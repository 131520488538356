<template>
  <div class="objection-handle">
    <!-- Title -->
    <div class="objection-handle-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="objection-handle-title">クチコミ通報対応<span v-if="sent" :style="{ marginLeft: '8px' }">完了</span></p>
    </div>

    <!-- Saved -->
    <div v-if="sent" class="objection-sent">
      <p class="objection-sent-title">通報の対応が完了しました</p>
      <EllipseButton
        :buttonText="'通報一覧にもどる'"
        :width="'212px'"
        :height="'36px'"
        @button-click="$_onClickBackButton"
      />
    </div>

    <!-- Edit -->
    <div v-else class="objection-items">
      <div class="objection-item-wrapper">
        <p class="objection-handle-sub-title">通報されたクチコミ</p>
        <p class="objection-handle-space-name">{{ spaceName }}</p>
        <p
          class="objection-handle-post-user-type"
          :class="$_getUserTypeClass(postUserType)"
        >
          {{ postUserType }}
        </p>
        <CommonTextarea
          :prefix="'objection-target-review'"
          :readonly="true"
          :remarks="reviewText"
        />
        <p class="objection-handle-post-at">{{ reviewCreateAt }}</p>
      </div>

      <div class="objection-item-wrapper objection-list">
        <div class="objection-header">
          <p class="objection-handle-sub-title">通報内容</p>
        </div>
        <div class="objection-body">
          <p class="objection-handle-contents-post-at">通報日時：{{ objectionCreateAt }}</p>
          <p class="objection-handle-type">通報の内容：{{ category }}</p>
          <p class="objection-handle-reason">通報理由</p>
          <CommonTextarea
            :prefix="'objection-reason'"
            :readonly="true"
            :remarks="reason"
          />
          <hr class="divided-line" />
        </div>

        <SquareButton
          v-if="otherObjections.length"
          :buttonText="showOtherObjections ? '閉じる' : `その他の通報を見る（${otherObjections.length}件）`"
          @button-click="showOtherObjections = !showOtherObjections"
        />
        <b-collapse v-model="showOtherObjections" :style="{ width: '100%' }" class="mt-2">
          <div
            v-for="objection, index in otherObjections"
            :key="`other-objection-${objection.objectionId}`"
            class="objection-body"
          >
            <p
              class="objection-handle-contents-post-at"
              :style="{ marginTop: index > 0 ? '4px' : '4px' }"
            >
              通報日時：{{ $_formatObjectionCreateAt(objection.createAt) }}
            </p>
            <p class="objection-handle-type">通報の内容：{{ objection.category }}</p>
            <p class="objection-handle-reason">通報理由</p>
            <CommonTextarea
              :prefix="'other-objection-reason'"
              :readonly="true"
              :shown="showOtherObjections"
              :remarks="objection.reason"
            />
            <p
              v-if="objection.handled"
              class="objection-handled"
            >
              対応済み
            </p>
            <hr class="divided-line" />
          </div>
        </b-collapse>
      </div>

      <div class="objection-item-wrapper no-border-bottom">
        <p class="objection-handle-sub-title pt-3 pb-2">クチコミ公開設定</p>
        <div class="radio-wrapper">
          <input type="radio" id="01-A" name="radio01" v-model="editPublication" value="公開">
          <label for="01-A" class="radio01">公開</label>
          <input type="radio" id="01-B" name="radio01" v-model="editPublication" value="非公開">
          <label for="01-B" class="radio01">非公開</label>
        </div>
        <p class="objection-remarks-title">備考{{ editRemarks.length }} / {{ maxReviewLength }}</p>
        <CommonTextarea
          v-model="editRemarks"
          :prefix="'objection-remarks'"
          :rows="'3'"
          :maxlength="String(maxReviewLength)"
          :resize="true"
          :userStyle="{
            border: 'solid 0.5px #707070',
            margin: '8px 0',
          }"
        />
      </div>

      <div class="action-button-wrapper">
        <EllipseButton
          :buttonText="'もどる'"
          :width="'312px'"
          :height="'36px'"
          @button-click="$_onCancelClick"
        />
        <EllipseButton
          :buttonText="'送信する'"
          :width="'312px'"
          :height="'36px'"
          @button-click="$_onSaveClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import SquareButton from '@/components/molecules/admin/AppAdminSquareButton'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { getObjectionByReviewId, putObjection } from '@/helper/firestore/objection'
import { getReview, putReview } from '@/helper/firestore/review'
import { getMaxLength } from '@/helper/common'
import { showConfirmPopup, showInfoPopupAlert, getChunkFromLocal, updateLocalChunk, sortByDate } from '@/helper/common'
import moment from 'moment'

const MAX_REVIEW_HANDLE_REMARKS_LENGTH = 300

export default {
  name: 'AppAdminObjectionHandle',
  components: {
    IconLeftArrow,
    EllipseButton,
    SquareButton,
    CommonTextarea
  },
  data() {
    return {
      sent: false,
      targetObjection: null,
      otherObjections: [],
      showOtherObjections: false,
      review: null,
      editRemarks: '',
      editPublication: '非公開',
    }
  },
  computed: {
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    spaceName() {
      return this.review ? this.review.spaceName : ''
    },
    postUserType() {
      return this.review ? this.review.postUserType : ''
    },  
    reviewText() {
      return this.review ? this.review.review : ''
    },
    reviewCreateAt() {
      return this.review
        ? moment(this.review.createAt.seconds * 1000).format('YYYY.MM.DD HH:mm')
        : ''
    },
    objectionCreateAt() {
      return this.review
        ? this.$_formatObjectionCreateAt(this.targetObjection.createAt)
        : ''
    },
    category() {
      return this.targetObjection ? this.targetObjection.category : ''
    },
    reason() {
      return this.targetObjection ? this.targetObjection.reason : ''
    },
    maxReviewLength() {
      return getMaxLength(
        MAX_REVIEW_HANDLE_REMARKS_LENGTH,
        this.editRemarks,
        false
      )
    },
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    try {
      const allObjections = await getObjectionByReviewId(this.$route.query.reviewId)

      this.targetObjection = allObjections
        .find(ob => ob.objectionId === this.$route.query.objectionId)
      this.otherObjections = allObjections
        .filter(ob => ob.objectionId !== this.$route.query.objectionId)
        .sort(this.$_sortByDate)

      this.review = await getReview(this.$route.query.reviewId)

      // 編集対象の変数に初期値設定
      this.editRemarks = this.targetObjection.remarks
      this.editPublication = this.review.publication ? '公開' : '非公開'
    } catch (error) {
      showInfoPopupAlert(`データ取得に失敗しました。<br />${error}`)
    } finally {
      await this.$nextTick()
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  methods: {
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },
    $_formatObjectionCreateAt(createAt) {
      return createAt
        ? moment(createAt.seconds * 1000).format('YYYY.MM.DD HH:mm')
        : ''
    },
    $_getUserTypeClass(postUserType) {
      switch(postUserType) {
        case '移動販売事業者':
          return 'mobile-vendor-user-type'
        case '利用客':
          return 'customer-user-type'
        case 'その他':
          return 'other-user-type'
        default:
          return ''
      }
    },
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push(
        {
          path: 'review_list',
          query: { tab: 0 }
        }
      )
    },
    $_onCancelClick: function () {
      this.$router.push(
        {
          path: 'review_list',
          query: { tab: 0 }
        }
      )
    },
    $_onSaveClick: async function () {
      try {
        const result = await showConfirmPopup(
          '通報対応を登録しますか？',
          '実行',
          `キャンセル`
        )

        this.$store.dispatch('loadingMask/showLoadingMask')

        if (result.isConfirmed) {
          const chunk = getChunkFromLocal(this.review.spaceId)
          const publication = this.editPublication === '公開'

          await putReview({
            reviewId: this.review.reviewId,
            spaceId: this.review.spaceId,
            chunk: getChunkFromLocal(this.review.spaceId),
            review: { publication: publication },
          })
          await putObjection(
            this.targetObjection.objectionId,
            {
              ...this.targetObjection,
              reviewPublication: publication,
              remarks: this.editRemarks,
              handled: true // 管理者が通報を処理したフラグ
            }
          )
          // ローカルのChunkを更新
          await updateLocalChunk(
            this.review.spaceId,
            { review: Math.max(0, chunk.review + (publication ? 1 : -1)) }
          )
          this.sent = true
        }
      } catch (error) {
        showInfoPopupAlert(`登録に失敗しました。<br />${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.objection-handle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px 32px;
}
.objection-handle-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.no-border-bottom {
  border-bottom: none !important;
}
.objection-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .objection-header {
    background-color: #EFEFEF;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
  }
  .objection-body {
    padding: 16px 32px;
    width: 100%;
  }
  .objection-handle-sub-title {
    color: #707070;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .objection-contents {
    color: #707070;
    font-size: 12px;
    width: 100%;
    resize: none;
    border: none;
    margin-top: 4px;
  }
  .objection-handle-space-name {
    color: #707070;
    font-size: 14px;
    font-weight: bold;
    margin: 12px 0;
  }
  .objection-handle-post-user-type {
    color: #707070;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    &:before {
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      width: 10px;
      margin-right: 4px;
    }
  }
  .mobile-vendor-user-type {
    &:before {
      background: #12B6D4;
    }
  }
  .customer-user-type {
    &:before {
      background: #FFDA0B;
    }
  }
  .other-user-type {
    &:before {
      background: #95BB00;
    }
  }
  .objection-handle-post-at {
    color: #707070;
    font-size: 10px;
    margin: 10px 0 12px 0;
  }
  .objection-handle-contents-post-at {
    color: #707070;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .objection-handle-type {
    color: #707070;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .objection-handle-reason {
    color: #707070;
    font-size: 12px;
    margin-bottom: 0;
  }
  .objection-handled {
    color: #707070;
    font-size: 12px;
    margin-bottom: 36px;
  }
  .objection-remarks-title {
    color: #707070;
    font-size: 12px;
    margin-bottom: 0;
  }
  .objection-remarks {
    color: #707070;
    font-size: 12px;
    width: 100%;
    resize: none;
    border: solid 0.5px #707070;
    margin: 8px 0;
  }
  .divided-line {
    margin: 0;
    width: 100%;
    color: #C6C6C6;
  }
  /* overwrite SquareButton style */
  ::v-deep(.app-admin-square-button) {
    width: 400px;
    text-align: center;
    margin: 12px auto 28px auto;
    .square-button {
      width: 90%;
      max-width: 800px;
    }
  }
}

.objection-list {
  border: solid 1px #C6C6C6;
}

.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
.objection-handle-title {
  color: #707070;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}
.objection-sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .objection-sent-title {
    color: #707070;
    font-size: 20px;
    font-weight: bold;
  }
}
.objection-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  border: solid 1px #707070;
  padding: 24px 32px;
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 64px;
  margin-top: 8px;
  width: 100%;
  .action-button {
    color: #707070;
    background-color: #FFFFFF;
    border: solid 1px #C6C6C6;
    border-radius: 16px;
    width: 312px;
    font-size: 12px;
    padding: 8px 32px;
    margin: 32px;
  }
}
/* ラジオボタン01 */
input[type=radio] {
  display: none;
}
.radio01 {
  color: #A5A5A5;
  font-size: 12px;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  margin-bottom: 20px;
  position: relative;
  width: auto;
}
.radio01::before {
  background: #fff;
  border: 1px solid #707070;
  border-radius: 50%;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  left: 0; 
  margin-top: -10px;
  position: absolute;
  top: 50%;
}
.radio01::after {
  background: #12B6D4;
  border-radius: 50%;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  left: 5px;
  margin-top: -4px;
  opacity: 0;
  position: absolute;
  top: 48%;
}
input[type=radio]:checked + .radio01::after {
  opacity: 1;
}
</style>
