<template>
  <div class="app-admin-my-page-edit-password-saved-container">
    <!-- Title -->
    <div class="app-admin-my-page-edit-password-saved-title-wrapper">
      <p class="app-admin-my-page-edit-password-saved-title">マイページ</p>
    </div>

    <div :style="{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'solid 1px lightgray',
      borderRadius: '8px',
      padding: '32px'
    }">
      <div class="header-title">{{ title }}</div>
      <div :style="{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }">
        <p :style="{ color: '#707070', fontWeight: 'bold'}">{{ mainText }}</p>
        <button
          class="action-button"
          @click="$router.push('/admin/my_page')"
        >
          マイページにもどる
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppAdminMyPageEditPasswordSaved',
  data() {
    return {
      title: 'パスワード変更',
      mainText: 'パスワードの変更が完了しました'
    }
  },
}
</script>

<style lang="scss" scoped>
.app-admin-my-page-edit-password-saved-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.app-admin-my-page-edit-password-saved-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.header-title {
  height: 40px;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  color: #707070;
  border-bottom: solid 1px #707070;
}
.app-admin-my-page-edit-password-saved-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
.action-button {
  color: #707070;
  background-color: #FFFFFF;
  border: solid 1px #C6C6C6;
  border-radius: 16px;
  width: 200px;
  font-size: 12px;
  padding: 8px 32px;
  margin: 0 32px;
}
</style>
