var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-list-container"},[_c('div',{staticClass:"news-create-title-wrapper"},[_c('button',{staticClass:"back-arrow-button",on:{"click":_vm.$_onClickBackButton}},[_c('IconLeftArrow')],1),_c('p',{staticClass:"news-create-title"},[_vm._v("お知らせ管理 新規作成")])]),(_vm.showEdit)?_c('div',{staticClass:"news-create-contents"},[_c('NewsEdit',_vm._b({attrs:{"publishDate":_vm.editNewsObject && _vm.editNewsObject.publishDate
        ? _vm.editNewsObject.publishDate.toString()
        : ''},on:{"edit-cancel":_vm.$_onClickBackButton,"edit-end":_vm.$_setEditNewsData}},'NewsEdit',_vm.editNewsObject,false))],1):(_vm.showConfirm)?_c('div',{staticClass:"news-create-contents"},[_c('NewsConfirm',_vm._b({on:{"edit-cancel":() => {
        _vm.showConfirm = false
        _vm.showEdit = true
        _vm.headerTitle = 'お知らせ管理 編集'
      }}},'NewsConfirm',_vm.editNewsObject,false))],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }