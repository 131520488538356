<template>
  <div class="space-data-detail">
    <!-- Saved -->
    <div v-if="showSaved" class="saved-page-wrapper">
      <div class="sub-title saved-page-title">
        スペースの作成が完了しました
      </div>
      <div class="action-button-wrapper">
        <EllipseButton
          :buttonText="'スペース情報にもどる'"
          :width="'212px'"
          :height="'36px'"
          @button-click="$_onClickGoBack"
        />
      </div>
    </div>

    <!-- Detail, Edit -->
    <div v-else :style="{ hegith: '100%' }">
      <!-- スペース情報 -->
      <div class="pb-5">
        <!-- ヘッダー -->
        <div class="topic-title">
          スペース情報
          <EllipseButton
            v-if="!showEdit && !showConfirm"
            :buttonText="'編集'"
            :width="'100px'"
            :height="'28px'"
            @button-click="$_onClickSpaceEdit"
          />    
        </div>
        <div
          v-for="form in forms"
          :key="form.id"
        >
          <!-- イメージ -->
          <div v-if="form.formType === 'image'">
            <!-- サムネイル -->
            <div v-if="addedBy === 'ユーザー'" class="space-detail-sub-title-container">
              <div class="sub-title">サムネイル</div>
              <div v-if="showEdit" class="item-label">
                <p class="optional-label">任意</p>
                <p class="limitation-label">未登録の場合<br />デフォルト画像<br />表示</p>
              </div>
              <div v-if="showEdit" class="input-wrapper">
                <div :style="{ display: 'flex', alignItems: 'center' }">
                  <UploadImageAdmin
                    v-bind:src="thumbnail"
                    v-bind:imageName="editThumbnailName"
                    v-bind:underButtonText="'PNG形式（1枚まで）'"
                    v-on:changed-image="$_onChangeImage"
                    v-on:remove-image="$_onRemoveImage"
                  />
                </div>
              </div>
              <div v-else-if="showConfirm" class="space-detail-text">
                <div class="space-detail-images-container" :style="{ justifyContent: 'flex-start', columnGap: '16px' }">
                  <viewer :images="[image]">
                    <img
                      class="space-image"
                      :src="editThumbnail.base64"
                      width="200px"
                      height="133px"
                    />
                  </viewer>
                  <div class="space-detail-text" :style="{ margin: '0' }">{{ editThumbnailName }}</div>
                </div>
              </div>
              <div v-else-if="showSaved" class="space-detail-text">
                <div class="space-detail-images-container" :style="{ columnGap: '16px' }">
                  <viewer :images="[image]">
                    <img
                      class="space-image"
                      :src="editThumbnail.base64"
                      width="200px"
                      height="133px"
                    />
                  </viewer>
                  <div class="space-detail-text" :style="{ margin: '0' }">{{ editThumbnailName }}</div>
                </div>
              </div>
              <div v-else class="space-detail-text">
                <div
                  class="space-detail-images-container"
                  :style="{ justifyContent: 'flex-start', columnGap: '16px' }"
                >
                  <viewer :images="[image]">
                    <img
                      class="space-image"
                      :src="image"
                      width="200px"
                      height="133px"
                    />
                  </viewer>
                  <div class="space-detail-text" :style="{ margin: '0' }">{{ thumbnailName }}</div>
                </div>
              </div>
            </div>
            <!-- サムネイル引用元URL -->
            <div v-if="addedBy === 'ユーザー' && hasSpaceImage" class="space-detail-sub-title-container">
              <div class="sub-title">サムネイル引用元URL</div>
              <div v-if="showEdit" class="item-label">
                <p class="required-label">必須</p>
                <p class="limitation-label">（サムネイル登録のみ）</p>
              </div>
              <div v-if="showEdit" class="input-wrapper">
                <input
                  type="text"
                  v-model="editCopyRightUrl"
                  :style="{
                    width: '100%',
                    border: 'none'
                  }"
                />
              </div>
              <div v-else-if="showConfirm" class="space-detail-text">{{ editCopyRightUrl }}</div>
              <div v-else-if="showSaved">{{ editCopyRightUrl }}</div>
              <div v-else class="space-detail-text">{{ copyRightUrl }}</div>
            </div>
          </div>
          <!-- 投稿者(編集不可) -->
          <div
            v-else-if="form.readOnly && (!showEdit && !showConfirm && !showSaved)"
            class="space-detail-sub-title-container"
          >
            <div class="sub-title">投稿者</div>
            <div class="space-detail-text">{{ form.value }}</div>
          </div>
          <!-- 通常項目 -->
          <DetailInput
            v-else-if="!form.readOnly"
            :showEdit="showEdit"
            :showConfirm="showConfirm"
            :showSaved="showSaved"
            v-bind="form"
            @value-changed="form.onValueChanged"
          />
        </div>

        <!-- 備考 -->
        <!-- 以下の懸念から実装分離を保留 -->
        <!-- AppAdminDetailInputの肥大化を避けるため -->
        <!-- textareaの表示はこの形式のみとは限らないため -->
        <div class="space-detail-sub-title-container">
          <div class="sub-title">備考</div>
          <div v-if="showEdit" class="item-label">
            <p class="optional-label">任意</p>
            <p class="limitation-label">400文字まで</p>
          </div>
          <div v-if="showEdit" class="textarea-wrapper">
            <CommonTextarea
              v-model="editRemarks"
              :prefix="'space-detail-edit'"
              :maxlength="String(maxRemerksLength)"
              :userStyle="{
                color: '#000',
                width: '100%',
                border: 'none'
              }"
            />
          </div>
          <CommonTextarea
            v-else-if="(showConfirm || showSaved)"
            v-model="editRemarks"
            :prefix="'space-detail-confirm'"
            :readonly="true"
            :userStyle="{
              flexGrow: '1',
              margin: '16px',
              fontSize: '14px',
              border: 'none',
            }"
          />
          <CommonTextarea
            v-else
            disabled
            :prefix="'space-detail'"
            :userStyle="{
              flexGrow: '1',
              margin: '16px',
              fontSize: '14px',
              border: 'none',
            }"
            :remarks="remarks"
          />
        </div>
        <!-- お気に入り数 -->
        <div v-if="placeId">
          <DetailInput
            :showEdit="showEdit"
            :showConfirm="showConfirm"
            :showSaved="showSaved"
            :formType="'number'"
            :value="likes"
            :title="'お気に入り数'"
            :required="true"
            :requiredRemarks="'半角数字'"
            :maxlength="'8'"
            @value-changed="(v) => editLikes = v"
          />
        </div>

        <b-container fluid>
          <b-row>
            <b-col cols="12" md="7" class="pl-0">
              <DetailInput
                v-for="form in addressForms"
                :key="form.id"
                :showEdit="showEdit"
                :showConfirm="showConfirm"
                :showSaved="showSaved"
                v-bind="form"
                @value-changed="form.onValueChanged"
              />
            </b-col>
            <b-col cols="12" md="5" class="pr-0 position-map-container">
              <MapContents
                v-bind="mapConfig"
                v-bind:mapZoom="18"
                v-on:map-loadend="$_onMapLoadEnd"
              />
              <div v-if="showEdit" style="display: flex;justify-content: space-around;align-items: center;column-gap: 8px;">
                <b-button
                  style="flex-grow: 1;font-size: 12px"
                  @click="$_onClickApplyAddress"
                >
                  住所の位置に移動
                </b-button>
                <b-button
                  style="flex-grow: 1;font-size: 12px" 
                  @click="$_onClickApplyPosition"
                >
                  ピンの位置を更新
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <!-- Action buttons -->
        <!-- 入力 → 確認 -->
        <div
          v-if="showEdit"
          class="action-button-wrapper"
        >
          <EllipseButton
            :buttonText="'キャンセル'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onEditCancelClick"
          />
          <EllipseButton
            :buttonText="'入力内容を確認する'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onClickSpaceSaveEdit"
          />
        </div>
        <!-- 確認 → 保存 -->
        <div
          v-else-if="showConfirm"
          class="action-button-wrapper"
        >
          <EllipseButton
            :buttonText="'キャンセル'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onSpaceConfirmCancelClick"
          />
          <EllipseButton
            :buttonText="'この内容で作成する'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onSaveSpaceClick"
          />
        </div>
        <!-- 削除 -->
        <div
          v-if="(placeId && showEdit)"
          class="delete-button-wrapper"
        >
          <span
            :style="{ cursor: 'pointer' }"
            @click="$_onClickDelete" 
          >
            スペースを削除する
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import DetailInput from '@/components/molecules/admin/AppAdminDetailInput.vue'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import MapContents from '@/components/molecules/user/AppUserMapContents'
import UploadImageAdmin from '@/components/organisms/admin/space/AppAdminUploadImage.vue'
import { getSpace, putSpace, postSpace, deleteSpace } from '@/helper/firestore/space'
import { getThumbnail } from '@/helper/place'
import { uploadThumbnailImages, deleteStorageImage } from '@/helper/admin'
import { showBottomInfoToast, showInfoPopupAlert, getMaxLength } from '@/helper/common'
import { mapGetters } from 'vuex'

const MAX_REMARKS_LENGTH = 400

export default {
  name: 'AppAdminSpaceDataDetail',
  components: {
    CommonTextarea,
    DetailInput,
    EllipseButton,
    MapContents,
    UploadImageAdmin
  },
  props: {
    placeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      geocooder: null,
      showConfirm: false,
      showEdit: !this.placeId,
      showSaved: false,
      space: {},
      forms: [],
      hasSpaceImage: false,
      thumbnail: null,
      editThumbnail: { base64: require('@/assets/image/place_default.png') },
      editThumbnailName: '',
      addressForms: [],
      editPublicStatus: '',
      editFacilityName: '',
      editThumbnailURL: '',
      editApplyUrl: '',
      editZip: '',
      editPrefecture: '',
      editArea: '',
      editAddress: '',
      editRequiredDocsOther: '',
      editRequiredDocsAfterContract: '',
      editSpaceName: '',
      editEmbeddedStreetView: '',
      editTwitterKeyword: '',
      editTwitterKeywordUrl: '',
      editLikes: 0,
      editIntroduction: '',
      editRemarks: '',
      map: null,
      google: null,
      marker: null,
      fixedMarker: null,
      eventListener: null,
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [
        'mapConfig',
        'mapCenter',
        'mapZoom',
        'mapBounds',
        'currentLocation',
        'basePoint'
      ]
    ),
    ...mapGetters('place', [ 'chunks' ]),
    spaceName: function () {
      // need Webpack^5 for Optional chaining
      // this.space?.spaceName
      return this.space && this.space.spaceName
        ? this.space.spaceName
        : ''
    },
    addedBy: function () {
      return this.space && this.space.addedBy
        ? this.space.addedBy === 'user'
          ? 'ユーザー'
          : '管理者'
        : '管理者'
    },
    thumbnailName: function () {
      return this.space && this.space.thumbnailName
        ? this.space.thumbnailName
        : ''
    },
    image: function () {
     return this.space && this.space.image
      ? this.space.image
      : require('@/assets/image/place_default.png')
    },
    embeddedStreetView: function () {
      return this.space && this.space.embeddedStreetView
        ? this.space.embeddedStreetView
        : ''
    },
    twitterKeywords: function () {
      return this.space && this.space.twitterKeywords
        ? this.space.twitterKeywords
        : ''
    },
    twitterKeywordUrl: function () {
      return this.space && this.space.twitterKeywordUrl
        ? this.space.twitterKeywordUrl
        : ''
    },
    likes: function () {
      return this.space && this.space.likes
        ? this.space.likes
        : 0
    },
    publicStatus: function () {
      return this.space && this.space.public
        ? this.space.public
        : ''
    },
    coordinates: function () {
      return this.space && this.space.coordinates
        ? this.space.coordinates
        : null
    },
    zip: function () {
      return this.space && this.space.zip
        ? this.space.zip
        : ''
    },
    prefecture: function () {
      return this.space && this.space.prefecture
        ? this.space.prefecture
        : ''
    },
    area: function () {
      return this.space && this.space.area
        ? this.space.area
        : ''
    },
    address: function () {
      return this.space && this.space.address
        ? this.space.address
        : ''
    },
    fullAddress: function () {
      return `
        ${this.editObject.prefecture}${this.editObject.area}${this.editObject.address}
      `
    },
    applyUrl: function () {
      return this.space && this.space.applyUrl
        ? this.space.applyUrl
        : ''
    },
    remarks: function () {
      return this.space && this.space.remarks
        ? this.space.remarks
        : ''
    },
    maxRemerksLength() {
      return getMaxLength(
        MAX_REMARKS_LENGTH,
        this.editRemarks,
        false
      )
    },
    editObject: function () {
      return {
        public: this.editPublicStatus,
        spaceName: this.editSpaceName,
        embeddedStreetView: this.editEmbeddedStreetView,
        twitterKeywords: this.editTwitterKeyword,
        twitterKeywordUrl: this.editTwitterKeywordUrl,
        zip: this.editZip,
        prefecture: this.editPrefecture,
        area: this.editArea,
        address: this.editAddress,
        applyUrl: this.editApplyUrl,
        likes: this.editLikes,
        coordinates: this.space.coordinates,
        remarks: this.editRemarks
      }
    }
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')
  },
  methods: {
    $_onMapLoadEnd: async function ({ map, google }) {
      this.map = map
      this.google = google

      try {
        // placeIdがあれば編集、なければ新規作成
        if (this.placeId) {
          await this.$_setSpaceDetail(this.placeId)

          this.thumbnail = this.image
          this.editThumbnail.base64 = this.image

          this.map.panTo(this.coordinates)

          if (this.fixedMarker) this.fixedMarker.setMap(null)
          this.fixedMarker = new this.google.maps.Marker({
            position: this.map.getCenter(),
            map: this.map,
            icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            zIndex: 2
          })
        } else {
          this.$_setForms()
        }

        // マーカー初期化
        if (this.marker) this.marker.setMap(null)
        this.google.maps.event.removeListener(this.eventListener)

        // マーカー作成
        this.marker = new this.google.maps.Marker({
          position: this.map.getCenter(),
          map: this.map,
          icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
          zIndex: 1
        })
        this.eventListener = this.google.maps.event.addListener(
          this.map,
          'center_changed',
          () => {
            this.marker.setPosition(this.map.getCenter())
          })
      } catch(error) {
        console.log(error)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    async $_onClickApplyAddress() {
      if (!this.map) return

      if (
        // !this.placeId &&
        this.editPrefecture &&
        this.editArea &&
        this.editAddress
      ) {
        try {
          // 新規作成かつ住所の入力が揃ったらジオコーディング → マップに適用
          const coords = await this.$_getCoords(this.fullAddress)
          coords && this.map.panTo(coords)
        } catch(error) {
          console.log(error)
        }
      }
    },
    $_onClickApplyPosition() {
      const center = this.map.getCenter()
      this.editObject.coordinates = {
        lat: center.lat(),
        lng: center.lng(),
      }

      if (this.fixedMarker) {
        this.fixedMarker.setPosition(center)
      } else {
        this.fixedMarker = new this.google.maps.Marker({
          position: this.map.getCenter(),
          map: this.map,
          icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
          zIndex: 2
        })
      }
    },
    $_setForms() {
      this.forms = [
        {
          id: 1,
          formType: 'radio',
          value: this.publicStatus,
          title: '公開設定',
          required: true,
          radioValue01: '公開',
          radioValue02: '非公開',
          onValueChanged: (v) => this.editPublicStatus = v,
        },
        {
          id: 2,
          formType: 'text',
          value: this.addedBy,
          title: '投稿者',
          readOnly: true,
          onValueChanged: () => {}
        },
        {
          id: 3,
          formType: 'text',
          value: this.spaceName,
          title: 'スペース名',
          required: true,
          requiredRemarks: '50字まで',
          maxlength: '50',
          onValueChanged: (v) => this.editSpaceName = v
        },
        {
          id: 4,
          formType: 'image',
          title: 'サムネイル',
          onValueChanged: (e) => {
            this.thumbnail = e.base64
            this.editThumbnail = e
            this.editThumbnailName = e.fileObject.name
          },
        },
        {
          id: 5,
          formType: 'text', 
          value: this.embeddedStreetView, 
          title: 'スペース詳細画面埋め込み', 
          required: false, 
          onValueChanged: (v) => this.editEmbeddedStreetView = v, 
        },
        {
          id: 6,
          formType: 'text', 
          value: this.twitterKeywords, 
          title: 'Twitter検索キーワード', 
          required: false, 
          onValueChanged: (v) => this.editTwitterKeyword = v, 
        },
        {
          id: 7,
          formType: 'text', 
          value: this.twitterKeywordUrl, 
          title: 'Twitter検索キーワードURL', 
          required: false, 
          onValueChanged: (v) => this.editTwitterKeywordUrl = v, 
        },
        {
          id: 12,
          formType: 'text', 
          value: this.applyUrl, 
          title: '募集ページ', 
          required: false, 
          onValueChanged: (v) => this.editApplyUrl = v, 
        }
      ]
      this.addressForms = [
        {
          id: 8,
          formType: 'text', 
          value: this.zip, 
          title: '郵便番号', 
          required: true, 
          requiredRemarks: 'ハイフンを含めて\n半角で入力', 
          maxlength: '8', 
          onValueChanged: (v) => this.editZip = v, 
        },
        {
          id: 9,
          formType: 'select', 
          value: this.prefecture, 
          title: '都道府県', 
          required: true, 
          onValueChanged: (v) => this.editPrefecture = v, 
        },
        {
          id: 10,
          formType: 'text', 
          value: this.area, 
          title: '市区郡', 
          required: true, 
          onValueChanged: (v) => this.editArea = v, 
        },
        {
          id: 11,
          formType: 'text', 
          value: this.address, 
          title: 'それ以降', 
          required: true, 
          onValueChanged: (v) => this.editAddress = v, 
        },
      ] 
    },
    $_onClickDelete: async function () {
      if (confirm('スペースを削除します。よろしいですか？')) {
        this.$store.dispatch('loadingMask/showLoadingMask')
        try {
          await this.$_deleteSpace()
          alert('削除しました')
        } catch (e) {
          // $_deleteSpace内でハンドリング済み
          console.log(e)
        } finally {
          this.$store.dispatch('loadingMask/hideLoadingMask')
        }
        this.$router.push('/admin/space_list')
      }
    },
    $_onEditCancelClick: function () {
      // 新規作成時はリストへ戻る
      if (!this.placeId) {
        this.$router.push('/admin/space_list')
      } else {
        this.showEdit = false
      }
    },
    $_onClickSpaceSaveEdit: function () {
      if (!this.$_validation()) return
      this.showEdit = false
      this.showConfirm = true
    },
    $_validation: function () {
      if (!this.editPublicStatus) {
        showBottomInfoToast('error', '「公開設定」を選択して下さい。', 4000)
        return false
      }
      if (!this.editSpaceName) {
        showBottomInfoToast('error', '「スペース名」を入力して下さい。', 4000)
        return false
      }
      if (!this.editZip) {
        showBottomInfoToast('error', '「郵便番号」を入力して下さい。', 4000)
        return false
      }
      if (!/^[0-9]{3}-[0-9]{4}$/.test(this.editZip)) {
        showBottomInfoToast('error', '「郵便番号」は半角英数字でハイフンを含めて入力して下さい。', 4000, 550)
        return false
      }
      if (!this.editPrefecture) {
        showBottomInfoToast('error', '「都道府県」を選択して下さい。', 4000)
        return false
      }
      if (!this.editArea) {
        showBottomInfoToast('error', '「市区郡」を選択して下さい。', 4000)
        return false
      }
      if (!this.editAddress) {
        showBottomInfoToast('error', '「それ以降」を選択して下さい。', 4000)
        return false
      }
      if (
        !this.editLikes &&
        (typeof this.editLikes !== 'number' || !isFinite(this.editLikes))
      ) {
        showBottomInfoToast('error', '「お気に入り数」を指定（数字）して下さい。', 4000, 400)
        return false
      }

      return true
    },
    $_onSpaceConfirmCancelClick: function () {
      this.showEdit = true
      this.showConfirm = false
    },
    $_onSaveSpaceClick: async function () {
      try {
        this.$store.dispatch('loadingMask/showLoadingMask')

        this.placeId
          ? await this.$_updateSpace()
          : await this.$_addSpace()

        this.showSaved = true
      } catch (e) {
        showInfoPopupAlert(`更新に失敗しました。<br />${e}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    async $_updateSpace() {
      try {
        // サムネイル保存、ダウンロードURL取得
        let uploadResult = ''
        if (this.editThumbnail && this.editThumbnail.fileObject) {
          uploadResult = await uploadThumbnailImages(
           'space',
            this.placeId,
            'thumbnail_1.png',
            this.editThumbnail.fileObject,
          )
        } else if (!this.editThumbnail || !this.editThumbnail.base64) {
          deleteStorageImage(
            'space',
            this.placeId,
            'thumbnail_1.png',
          )
        }

        // 対象chunk取得
        const targetChunk = this.chunks.find(c => c.placeId === this.placeId)
        if (!targetChunk) {
          throw new Error('ローカルデータが取得できませんでした。再度ログインしてください。')
        }

        await putSpace(
          {
            placeId: this.placeId,
            ...this.editObject,
            thumbnail: uploadResult,
            thumbnailName: this.editThumbnailName || '',
            addedBy: this.space.addedBy || 'admin', // 無ければ管理者とする
            createAt: this.space.createAt, // firestoreへの書き込み時に値がundefinedだとエラーになるので、editObjectにはcreateAtは展開していない(新規作成時はhelper側で追加)
            type: 'space',
          },
          targetChunk.docId,
        )

        // reload
        await this.$_setSpaceDetail(this.placeId)
        // reload local chunk
        this.$store.dispatch('place/updateSpecificChunk', targetChunk.docId)
      } catch (e) {
        throw new Error(e)
      }
    },
    async $_addSpace() {
      try {
        // 指定の座標がなければ、ここでジオコーディング
        const coords = this.editObject.coordinates || await this.$_getCoords(this.fullAddress)
        // 無ければ管理者とする
        const addedBy = this.space.addedBy || 'admin'
        const spaceObject = {
          ...this.editObject,
          coordinates: coords,
          addedBy: addedBy,
          type: 'space',
          likes: 0,
          review: 0
        }
        const result = await postSpace(spaceObject)

        if (
          result.space.status === 'success' &&
          result.chunk.status === 'success'
        ) {
          const spaceId = result.space.docId
          const chunkId = result.chunk.docId

          // 新規追加はplaceIdがないので、一度登録して取得してから画像登録
          if (this.editThumbnail && this.editThumbnail.fileObject) {
            const uploadResult = await uploadThumbnailImages(
              'space',
              spaceId,
              'thumbnail_1.png',
              this.editThumbnail.fileObject,
            )

            // 画像のダウンロードURLのみ更新
            await putSpace(
              {
                ...spaceObject,
                placeId: spaceId,
                thumbnail: uploadResult,
                thumbnailName: this.editThumbnailName || '',
              },
              chunkId,
            )
          } else {
            deleteStorageImage(
              'space',
              spaceId,
              'thumbnail_1.png',
            )
          }
          // reload space detail
          await this.$_setSpaceDetail(result.space.docId)
          // reload local chunk
          this.$store.dispatch('place/updateSpecificChunk', result.chunk.docId)
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    async $_deleteSpace() {
      try {
        const targetChunk = this.chunks.find(c => c.placeId === this.placeId)
        if (!targetChunk) {
          throw new Error('ローカルデータが取得できませんでした。再度ログインしてください。')
        }

        // delete space from spaces and chunks
        await deleteSpace(this.placeId, targetChunk.docId)

        // reload space detail
        await this.$_setSpaceDetail(targetChunk.docId)
        // reload local chunk
        this.$store.dispatch('place/updateSpecificChunk', targetChunk.docId)
      } catch (e) {
        showInfoPopupAlert(`削除に失敗しました。<br />${e}`)
        throw new Error(e)
      }
    },
    $_getCoords: async function (address) {
      if(!this.geocooder) {
        this.geocooder = new this.google.maps.Geocoder()
      }

      return new Promise((resolve, reject) => {
        try {
          this.geocooder.geocode(
            {
              address: address,
            },
            (result, status) => {
              if (status === 'OK') {
                resolve({
                  lng: result[0].geometry.location.lng(),
                  lat: result[0].geometry.location.lat(),
                })
              } else {
                console.log('error: ', status)
                resolve()
              }
            }
          )
        } catch (err) {
          console.log(err)
          reject(err)
          // resolve()
        }
      })
    },
    $_onClickSpaceEdit: function () {
      this.showEdit = true

      this.editApplyUrl = this.applyUrl
      this.editPublicStatus = this.publicStatus
      this.editTeepeesId = this.teepeesId
      this.editSpaceName = this.spaceName
      this.editZip = this.zip
      this.editPrefecture = this.prefecture
      this.editArea = this.area
      this.editAddress = this.address
      this.editEmbeddedStreetView = this.embeddedStreetView
      this.editTwitterKeyword = this.twitterKeywords
      this.editTwitterKeywordUrl = this.twitterKeywordUrl
      this.editLikes = this.likes
      this.editRemarks = this.remarks
      this.editThumbnailName = this.thumbnailName
    },
    /**
     * スペース詳細情報を取得する
     */
    $_setSpaceDetail: async function (placeId) {
      try {
        const spaceObject = await getSpace(placeId)
        // サムネイル画像を取得してthis.space.imageに埋め込む
        // 先にthis.spaceにspaceObjectを入れてしまうとタイミング的に
        // imageが取得できない
        spaceObject.image = await getThumbnail('space', placeId)
        this.space = spaceObject
        this.$_setForms()
      } catch (error) {
        showInfoPopupAlert(`スペース情報の取得に失敗しました。<br />${error}`)
      }
    },
    $_onClickGoBack: async function () {
      this.$store.dispatch('loadingMask/showLoadingMask')

      // reload
      if (this.placeId) await this.$_setSpaceDetail(this.placeId)

      this.showEdit = false
      this.showConfirm = false
      this.showSaved = false

      this.$store.dispatch('loadingMask/hideLoadingMask')
    },
    $_onChangeImage: function (e) {
      this.thumbnail = e.base64
      this.editThumbnail = e
      this.editThumbnailName = e.fileObject.name
    },
    $_onRemoveImage: function (e) {
      this.thumbnail = require('@/assets/image/place_default.png')
      this.editThumbnail = ''
      this.editThumbnailName = ''
      this.editCopyRightUrl = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.space-data-detail {
  height: 100%;
  padding: 1rem 2rem;
  .saved-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 77vh;
  }
  .saved-page-title {
    color: #707070;
    font-size: 20px;
    font-weight: bold;
  }
  .delete-button-wrapper {
    text-align: center;
    text-decoration: underline;
    line-height: 3;
  }
  .position-map-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 16px;
  }
  .space-detail-sub-title-container {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 16px;
    border: solid 1px #D6D6D6;
    border-radius: 4px;
    .bold {
      font-weight: bold;
    }
    .item-label {
      width: 96px;
      font-size: 8px;
      flex-shrink: 0;
      margin: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: solid 1px #D6D6D6;
      text-align: center;
      .optional-label {
        color: #707070;
        font-weight: bold;
        margin-bottom: 0;
      }
      .required-label {
        color: red;
        font-weight: bold;
        margin-bottom: 0;
      }
      .limitation-label {
        color: #707070;
        margin-bottom: 0;
      }
    }
    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 16px;
    }
    .sub-title {
      width: 13%;
      min-width: 260px;
      background-color: #D6D6D6;
      border-right: solid 1px #D6D6D6;
      color: #707070;
      font-size: 14px;
      font-weight: bold;
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .news-text {
      flex-grow: 1;
      label {
        margin-bottom: 0;
      }
    }
    .space-detail-text {
      width: 87%;
      flex-grow: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: auto 1rem;
    }
    .space-detail-images-container {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin: 8px 0;
        cursor: pointer;
      }
    }
  }
}
.topic-title {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  /* overwrite */
  ::v-deep(.btn-outline-secondary) {
    font-size: 12px;
  }
}
.space-detail-sub-title-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
  border: solid 1px #D6D6D6;
  border-radius: 4px;
  .item-label {
    width: 96px;
    font-size: 8px;
    flex-shrink: 0;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: solid 1px #D6D6D6;
    text-align: center;
    .optional-label {
      color: #707070;
      font-weight: bold;
      margin-bottom: 0;
    }
    .limitation-label {
      color: #707070;
      margin-bottom: 0;
    }
  }
  .textarea-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px;
    color: #707070;
    font-size: 14px;
  }
  .sub-title {
    width: 13%;
    min-width: 260px;
    background-color: #D6D6D6;
    border-right: solid 1px #D6D6D6;
    color: #707070;
    font-size: 14px;
    font-weight: bold;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .space-detail-textarea {
    flex-grow: 1;
    margin: 16px;
    color: #707070;
    font-size: 14px;
    border: none;
  }
  :disabled {
    background-color: white;
  }
}
.edit_button {
  border-radius: 24px;
  width: 100px;
  padding: 4px;
  font-size: 12px; 
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 64px;
  /* overwrite */
  ::v-deep(.btn-outline-secondary) {
    margin: 24px 0;
  }
}
</style>
