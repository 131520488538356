<template>
  <div class="app-admin-table">
    <b-table
      class="admin-table"
      sticky-header
      hover 
      responsive
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      @row-clicked="$_onRowClick"
    >
      <template #cell(buttonCell)="row">
        <slot name="buttonCell" :row="row" />
      </template>
    </b-table>

    <b-pagination
      class="custom-pagenation"
      align="center"
      aria-controls="my-table"
      v-model="currentPage"
      :total-rows="rowCount"
      :per-page="perPage"
    />
  </div>
</template>

<script>
export default {
  name: 'AppAdminTable',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 10
    },
  },
  data() {
    return{
      currentPage: 1,
    }
  },
  methods: {
    /**
     * 行クリック時のイベント処理
     */
    $_onRowClick(item, index, event) {
      this.$emit('row-clicked', item)
    }
  },
  computed: {
    /**
     * テーブルに表示されている行数を返す
     * @type {Number}
     */
     rowCount: function () {
      return this.items.length
    },
  }
}
</script>

<style lang="scss" scoped>
.app-admin-table {
  height: 100%;
  .admin-table {
    height: 100%;
  }
  /* overwrite */
  .b-table-sticky-header {
    height: calc(100% - 54px - 1rem);
    max-height: none;
  }  
} 
</style>
