var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-detail-container"},[_c('div',{staticClass:"news-detail-title-wrapper"},[_c('button',{staticClass:"back-arrow-button",on:{"click":_vm.$_onClickBackButton}},[_c('IconLeftArrow')],1),_c('p',{staticClass:"news-detail-title"},[_vm._v(_vm._s(_vm.headerTitle))])]),(_vm.showEdit)?_c('div',{style:({ height: '100%' })},[_c('NewsEdit',_vm._b({attrs:{"deleteAction":true},on:{"edit-cancel":() => {
        _vm.showEdit = false
        _vm.headerTitle = 'お知らせ詳細'
      },"edit-end":_vm.$_setEditNewsData,"delete-news":_vm.$_deleteNewsData}},'NewsEdit',_vm.newsObject,false))],1):(_vm.showConfirm)?_c('div',{style:({ height: '100%' })},[_c('NewsConfirm',_vm._b({on:{"edit-cancel":() => {
        _vm.showConfirm = false
        _vm.showEdit = true
        _vm.headerTitle = 'お知らせ管理 編集'
      }}},'NewsConfirm',_vm.editNewsObject,false))],1):_c('div',{staticClass:"news"},[_c('EllipseButton',{staticClass:"edit-button",attrs:{"buttonText":'編集',"width":'100px'},on:{"button-click":_vm.$_onClickEdit}}),_c('div',{staticClass:"news-sub-title-container"},[_c('div',{staticClass:"sub-title"},[_vm._v("カテゴリ")]),_c('div',{staticClass:"news-text"},[_vm._v(_vm._s(_vm.category))])]),_c('div',{staticClass:"news-sub-title-container"},[_c('div',{staticClass:"sub-title"},[_vm._v("公開日時")]),_c('div',{staticClass:"news-text"},[_vm._v(_vm._s(_vm.publishDate))])]),_c('div',{staticClass:"news-sub-title-container"},[_c('div',{staticClass:"sub-title"},[_vm._v("タイトル")]),_c('div',{staticClass:"news-text"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"news-sub-title-container multi-line-row"},[_c('div',{staticClass:"sub-title"},[_vm._v("本文")]),_c('div',{staticClass:"news-text"},[_c('CommonTextarea',{attrs:{"prefix":'news-detail',"readonly":true,"userStyle":{ fontSize: '14px' }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }