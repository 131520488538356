<template>
  <div class="event-detail-container">
    <!-- Title -->
    <div class="event-detail-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="event-detail-title">イベント詳細</p>
    </div>

    <!-- イベント詳細 -->
    <div class="event-detail">
      <EventDataDetail :placeId="$route.query.placeId" />
    </div>
  </div>
</template>

<script>
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import EventDataDetail from '@/components/organisms/admin/event/AppAdminEventDataDetail'

export default {
  name: 'AppAdminEventDetail',
  components: {
    EventDataDetail,
    IconLeftArrow,
  },
  methods: {
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/event_list')
    },
  },
}
</script>

<style lang="scss" scoped>
.event-detail-container {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  .event-detail-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    .back-arrow-button {
      border: 1px solid lightgray;
      border-radius: 4px;
      background-color: white;
      height: 32px;
      width: 32px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
      }
    }
    .event-detail-title {
      color: #707070;
      font-size: 24px;
      font-weight: bold;
      margin: 0px !important;
      line-height: 32px;
    }
  }
  .event-detail {
    border: solid 1px lightgray;
    box-shadow: 0px 5px 7px lightgrey;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    height: 88%;
    overflow: auto;
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 8px 16px;
  min-width: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
</style>