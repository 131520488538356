<template>
  <div class="app-admin-list">
    <PageFrame>
      <template v-slot:content>
        <div :is="contentComponent"></div>
      </template>
    </PageFrame>
    <LoadingMask />
  </div>
</template>

<script>
import PageFrame from '@/components/organisms/admin/common/AppAdminPageFrame'
import AppAdminSpaceList from '@/components/organisms/admin/space/AppAdminSpaceList'
import AppAdminSpaceCreate from '@/components/organisms/admin/space/AppAdminSpaceCreate'
import AppAdminSpaceDetail from '@/components/organisms/admin/space/AppAdminSpaceDetail'
import AppAdminSpaceUploadCsv from '@/components/organisms/admin/space/AppAdminSpaceUploadCsv'
import AppAdminSpaceDownloadCsv from '@/components/organisms/admin/space/AppAdminSpaceDownloadCsv'
import AppAdminEventList from '@/components/organisms/admin/event/AppAdminEventList'
import AppAdminEventCreate from '@/components/organisms/admin/event/AppAdminEventCreate'
import AppAdminEventDetail from '@/components/organisms/admin/event/AppAdminEventDetail'
import AppAdminEventUploadCsv from '@/components/organisms/admin/event/AppAdminEventUploadCsv'
import AppAdminEventDownloadCsv from '@/components/organisms/admin/event/AppAdminEventDownloadCsv'
import AppAdminNewsList from '@/components/organisms/admin/news/AppAdminNewsList'
import AppAdminNewsCreate from '@/components/organisms/admin/news/AppAdminNewsCreate'
import AppAdminNewsDetail from '@/components/organisms/admin/news/AppAdminNewsDetail'
import AppAdminNewsSaved from '@/components/organisms/admin/news/AppAdminNewsSaved'
import AppAdminMyPage from '@/components/organisms/admin/my-page/AppAdminMyPage'
import AppAdminMyPageEditEmailSaved from '@/components/organisms/admin/my-page/AppAdminMyPageEditEmailSaved'
import AppAdminMyPageEditNameSaved from '@/components/organisms/admin/my-page/AppAdminMyPageEditNameSaved'
import AppAdminMyPageEditPasswordSaved from '@/components/organisms/admin/my-page/AppAdminMyPageEditPasswordSaved'
import AppAdminReviewList from '@/components/organisms/admin//review/AppAdminReviewList'
import AppAdminReviewHandle from '@/components/organisms/admin/review/AppAdminReviewHandle'
import AppAdminObjectionHandle from '@/components/organisms/admin/review/AppAdminObjectionHandle'
import LoadingMask from '@/components/molecules/common/AppFullscreenLoadingMask'

export default {
  name: 'AppAdminPage',
  components: {
    PageFrame,
    AppAdminSpaceList,
    AppAdminSpaceCreate,
    AppAdminSpaceDetail,
    AppAdminSpaceUploadCsv,
    AppAdminSpaceDownloadCsv,
    AppAdminEventList,
    AppAdminEventCreate,
    AppAdminEventDetail,
    AppAdminEventUploadCsv,
    AppAdminEventDownloadCsv,
    AppAdminNewsList,
    AppAdminNewsCreate,
    AppAdminNewsDetail,
    AppAdminNewsSaved,
    AppAdminMyPage,
    AppAdminMyPageEditEmailSaved,
    AppAdminMyPageEditNameSaved,
    AppAdminMyPageEditPasswordSaved,
    AppAdminReviewList,
    AppAdminReviewHandle,
    AppAdminObjectionHandle,
    LoadingMask
  },
  props: {
    'contentComponent': {
      type: String,
      default: 'SpaceList'
    },
  },
}
</script>

<style lang="scss"></style>