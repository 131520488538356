<template>
  <div class="space-download-csv">
    <!-- Title -->
    <div class="space-detail-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="space-detail-title">スペース一覧 CSV一括ダウンロード</p>
    </div>

    <!-- Contents -->
    <div class="space-download-csv-container">
      <div v-if="complete" class="contents-wrapper">
        <div class="sub-title">
          ダウンロードが完了しました
        </div>
        <div class="plane-action-button-wrapper">
          <button
            class="action-button"
            @click="$_onClickGoBack"
          >
            スペース情報にもどる
          </button>
        </div>
      </div>

      <div v-else  class="contents-wrapper">
        <div class="sub-title">
          CSVを一括ダウンロードをする
        </div>
        <div class="action-button-wrapper">
          <button
            class="action-button"
            @click="$_onDownload"
          >
            CSVをダウンロードする
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { getAllSpaces } from '@/helper/firestore/space'
import { sortByDate } from '@/helper/common'
import { parse } from 'json2csv'

export default {
  name: 'AppAdminSpaceDownloadCsv',
  components: { IconLeftArrow },
  data() {
    return {
      complete: false,
      count: 0,
      total: 0,
      subText: ''
    }
  },
  watch: {
    count() {
      this.$store.dispatch('loadingMask/setCount', this.count)
    },
    total() {
      this.$store.dispatch('loadingMask/setTotal', this.total)
    },
    subText() {
      this.$store.dispatch('loadingMask/setSubText', this.subText)
    }
  },
  methods: {
    /*
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/space_list')
    },
    async $_onDownload() {
      this.subText = 'ダウンロード中...'
      this.$store.dispatch('loadingMask/showLoadingMask')
      try {
        const data = await getAllSpaces()
        data.sort(this.$_sortByDate)
        this.$_writeToCSV(data)
      } catch (error) {
        alert(error) 
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
        this.complete = true
      }
    },
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },
    $_json2csv(jsonArray) {
      // jsonArray.sort((a, b) => a.id - b.id)
      const data = jsonArray.map(d => {
        return {
          'ID': d.docId,
          '公開設定': d.public,
          'スペース名': d.spaceName,
          'スペース詳細画面埋め込み': d.embeddedStreetView,
          'twitter検索キーワード': d.twitterKeywords,
          'twitter検索キーワードURL': d.twitterKeywordUrl,
          '郵便番号': d.zip,
          '都道府県': d.prefecture,
          '市区郡': d.area,
          'それ以降': d.address,
          '募集ページ': d.applyUrl,
          '備考': d.remarks,
          'お気に入り数': d.likes,
        }
      })
      const fields = [
        'ID',
        '公開設定',
        'スペース名',
        'スペース詳細画面埋め込み',
        'twitter検索キーワード',
        'twitter検索キーワードURL',
        '郵便番号',
        '都道府県',
        '市区郡',
        'それ以降',
        '募集ページ',
        '備考',
        'お気に入り数',
      ]
      const csv = parse(data, { fields })
      return csv
    },
    $_writeToCSV(jsonArray){
      let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      let csvString = this.$_json2csv(jsonArray);
      let blob = new Blob([bom, csvString],{type:"text/plan"});
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'space.csv';
      link.click();
    },
    $_onClickGoBack: async function () {
      this.$router.push('/admin/space_list')
    },
  },
}
</script>

<style lang="scss" scoped>
.space-download-csv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.space-detail-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 1rem;
}
.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
.space-detail-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px !important;
  line-height: 32px;
}
.space-download-csv-container {
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
  .contents-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .sub-title {
      color: #707070;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  .action-button {
    color: #FFFFFF;
    background-color: #12B6D4;
    border: none;
    font-size: 12px;
    padding: 16px 32px;
    margin: 32px;
    font-weight: bold;
  }
}
.plane-action-button-wrapper {
  display: flex;
  justify-content: center;
  .action-button {
    color: #707070;
    background-color: #FFFFFF;
    border: solid 1px #C6C6C6;
    border-radius: 16px;
    font-size: 12px;
    padding: 10px 32px;
    margin: 32px;
    font-weight: bold;
  }
}
</style>