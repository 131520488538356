<template>
  <div class="news-list-container">
    <!-- Title -->
    <p class="event-management-list-title">お知らせ管理</p>
    <div class="controle-header">
      <!-- Search Bar -->
      <div class="search_bar">
        <input
          v-model="keyword"
          type="text"
          placeholder="タイトル、本文から検索"
        />
      </div>

      <!-- Create -->
      <div class="create-button-wrapper">
        <SquareButton
          :buttonText="'新規作成'"
          @button-click="$_onClickCreate"
        />
      </div>
    </div>

    <!-- Table -->
    <div class="news-table-container">
      <AdminTable
        :fields="fields"
        :items="filteredItems"
        @row-clicked="$_rowClickCallback"
      >
      </AdminTable>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import AdminTable from '@/components/organisms/admin/common/AppAdminTable'
import SquareButton from '@/components/molecules/admin/AppAdminSquareButton'
import { getAllNews } from '@/helper/firestore/news'

export default {
  name: 'NewsList',
  components: {
    AdminTable,
    SquareButton
  },
  data() {
    return {
      data: [],
      news: [],
      tableData: [],
      dateObject: '',
      keyword: '',
      selected: 'past',
      fields: [
        {
          class: 'normal-column',
          thClass: 'news-category-column-header',
          tdClass: 'news-category-column-cell',
          key: 'category',
          label: 'カテゴリ',
          sortable: true,
        },
        {
          class: 'normal-column',
          thClass: 'news-publish-date-column-header',
          tdClass: 'news-publish-date-column-cell',
          key: 'publishDate',
          label: '公開日時',
          sortable: true,
        },
        {
          class: 'normal-column',
          thClass: 'news-title-column-header',
          tdClass: 'news-title-column-cell',
          key: 'title',
          label: 'タイトル',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'news-create-at-column-header',
          tdClass: 'news-create-at-column-cell',
          key: 'createAt',
          label: '登録日時',
          sortable: true,
        },
      ],

    }
  },
  computed: {
    items() {
      // createAt がない場合があるので、 その場合は updateAt を使う
      return this.data.map(n => ({
        id: n.docId,
        category: n.category,
        publishDate: n.publishDate,
        title: n.title,
        text: n.text,
        createAt:  moment(
            n.createAt ? n.createAt.seconds * 1000 : n.updateAt.seconds * 1000
          ).format('YYYY/MM/DD HH:mm')
      })).sort((a, b) => {
        const dateA = new Date(a.publishDate)
        const dateB = new Date(b.publishDate)
        return dateB.getTime() - dateA.getTime()
      })
    },
    filteredItems: function () {
      return this.keyword
        ? this.items.filter(i => (
          i.title.includes(this.keyword) ||
          i.text.includes(this.keyword)
        ))
        : this.items
    },
    rows() {
      return this.items.length
    },
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    this.data = await getAllNews()
    this.$store.dispatch('news/setAdminNews', this.data)

    this.$nextTick(function(){
      this.$store.dispatch('loadingMask/hideLoadingMask')
    });
  },
  methods: {
    /**
     * 新規作成ボタン処理
     */
    $_onClickCreate() {
      this.$router.push('/admin/news_create')
    },
    /**
     * お知らせ詳細ページへ遷移
     * URL経由でNew IDを渡す
     * @param {Object} row 選択された行データ
     */
    $_rowClickCallback(data) {
      this.$router.push(
        {
           path: '/admin/news_detail',
           query: { id: data.id }
         }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.news-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.event-management-list-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.news-create-button {
  border: solid 1px #B1ACAC;
  height: 42px;
  padding: 0 3rem;
  border-radius: 2px;
}
.controle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_bar {
    color: #a0a0a0;
    margin: 16px 0;
    input {
      width: 35vw;
      min-width: 322px;
      max-width: 550px;
      height: 42px;
      padding-left: 20px;
      border: solid 1px #B1ACAC;
      border-radius: 2px;
      &::placeholder {
        font-size: 14px;
        color: #A0A0A0;
        /* TODO サーチアイコンをplaceholder先頭に追加する
        &::before {
          content:" ";
          background: url('/assets/image/icon_placeholder_search.svg') no-repeat;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin: 0 10px 3px 0;
        }
        */
      }
    }
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
.news-table-container {
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
}
::v-deep(#news-list-table) {
  border-bottom: solid 1px #dee2e6;
  th {
    background-color: #f9f9f9;
    font-weight: 14px;
    color: #707070;
  }
}
::v-deep(.news-category-column-header) {
  width: 16%
}
::v-deep(.news-category-column-cell) {
  width: 16%
}
::v-deep(.news-publish-date-column-header) {
  width: 26%
}
::v-deep(.news-publish-date-column-cell) {
  width: 26%
}
::v-deep(.news-title-column-header) {
  text-align: left !important;
}
::v-deep(.news-title-column-cell) {
  text-align: left !important;
}
::v-deep(.news-target-column-header) {
  width: 18%
}
::v-deep(.news-target-column-cell) {
  width: 18%
}
::v-deep(.news-create-at-column-header) {
  width: 200px;
}
::v-deep(.news-create-at-column-cell) {
  width: 200px;
}
/* overwrite */
.b-table-sticky-header {
  max-height: 100%;
  border-radius: 8px;
}
/* overwrite */
::v-deep(.button-column) {
  width: 10%;
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
/* overwrite */
::v-deep(.normal-column) {
  font-size: 14px;
  text-align: center;
  vertical-align: middle !important;
}

::v-deep(.custom-pagenation) {
  margin-right: 16px;
  /* overwrite */
  .page-item {
    margin: 0;
  }
  /* overwrite */
  .page-link {
    color: #707070
  }
  .active {
    .page-link {
      font-weight: bold;
      color: #ffffff;
      background-color: #12B6D4;
      border: #12B6D4;
      height: 100%;
    }
  }
}
.button-column {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
  width: 15%;
}
.confirm-button {
  font-size: 12px;
  color: #707070;
  background-color: #EBE8E8;
  border: #C6C6C6;
  border-radius: 8px;
  padding: 8px 0;
  width: 52px;
  &:hover {
    background-color: #C6C6C6;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
