<template>
  <div class="space-management-list-container">
    <!-- Title -->
    <div class="space-detail-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="space-detail-title">イベント一覧 CSV一括ダウンロード</p>
    </div>

    <!-- Table -->
    <div class="space-management-table-container">
      <div v-if="complete" :style="{ height: '100%' }">
        <div
          :style="{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }"
        >
          <div class="sub-title" :style="{ color: '#707070', fontSize: '20px', fontWeight: 'bold' }">
            ダウンロードが完了しました
          </div>
          <div class="plane-action-button-wrapper">
            <button
              class="action-button"
              @click="$_onClickGoBack"
            >
              イベント情報にもどる
            </button>
          </div>
        </div>
      </div>
      <div v-else :style="{ height: '100%' }">
        <div
          :style="{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }"
        >
          <div class="sub-title" :style="{ color: '#707070', fontSize: '20px', fontWeight: 'bold' }">
            CSVを一括ダウンロードをする
          </div>
          <div class="action-button-wrapper">
            <button
              class="action-button"
              @click="$_onDownload"
            >
              CSVをダウンロードする
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { getAllEvents } from '@/helper/firestore/event'
import { sortByDate } from '@/helper/common'
import { expiredDisplayDate } from '@/helper/place'
import { parse } from 'json2csv'

export default {
  name: 'AppAdminEventDownloadCsv',
  components: { IconLeftArrow },
  data() {
    return {
      complete: false,
      count: 0,
      total: 0,
      subText: ''
    }
  },
  watch: {
    count() {
      this.$store.dispatch('loadingMask/setCount', this.count)
    },
    total() {
      this.$store.dispatch('loadingMask/setTotal', this.total)
    },
    subText() {
      this.$store.dispatch('loadingMask/setSubText', this.subText)
    }
  },
  methods: {
    /*
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/event_list')
    },
    async $_onDownload() {
      this.subText = 'ダウンロード中...'
      this.$store.dispatch('loadingMask/showLoadingMask')
      try {
        const data = await getAllEvents()
        // 日付順ソート,掲載終了日でフィルタ
        this.$_writeToCSV(
          data
            .sort(this.$_sortByDate)
            .filter(d => !expiredDisplayDate(d.endOfPublicationDate))
        )
      } catch (error) {
        alert(error) 
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
        this.complete = true
      }
    },
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },
    $_json2csv(json) {
      const data = json.map(d => {
        return {
          'ID': d.docId,
          '公開設定': d.public,
          'イベント名': d.eventName,
          'サムネイル名': d.eventImageName,
          'サムネイル引用元URL': d.copyRightUrl,
          'スペース名': d.spaceName,
          '郵便番号': d.zip,
          '都道府県': d.prefecture,
          '市区郡': d.area,
          'それ以降': d.address,
          '開催日': d.heldDates,
          '募集期間(開始)': d.termBeginDate,
          '募集期間(終了)': d.termEndDate,
          '掲載終了日': d.endOfPublicationDate,
          'イベント情報掲載ページ': d.eventInfoUrl,
          'お気に入り数': d.likes
        }
      })
      const fields = [
        'ID',
        '公開設定',
        'イベント名',
        'サムネイル名',
        'サムネイル引用元URL',
        'スペース名',
        '郵便番号',
        '都道府県',
        '市区郡',
        'それ以降',
        '開催日',
        '募集期間(開始)',
        '募集期間(終了)',
        '掲載終了日',
        'イベント情報掲載ページ',
        'お気に入り数'
      ]
      const csv = parse(data, { fields })
      return csv
    },
    $_writeToCSV(json){
      let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      let csvString = this.$_json2csv(json);
      let blob = new Blob([bom, csvString],{type:"text/plan"});
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'event.csv';
      link.click();
    },
    $_onClickGoBack: async function () {
      this.$router.push('/admin/event_list')
    },
  },
}
</script>

<style lang="scss" scoped>
.space-management-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.space-detail-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 1rem;
}
.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
.space-management-list-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.space-detail-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px !important;
  line-height: 32px;
}

.left-arrow-button {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.right-arrow-button {
  background-color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.controle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_bar {
    color: #a0a0a0;
    margin: 16px 0;
    input {
      width: 35vw;
      min-width: 322px;
      max-width: 550px;
      height: 42px;
      padding-left: 20px;
      border: solid 1px #B1ACAC;
      border-radius: 2px;
      &::placeholder {
        font-size: 14px;
        color: #A0A0A0;
        /* TODO サーチアイコンをplaceholder先頭に追加する
        &::before {
          content:" ";
          background: url('/assets/image/icon_placeholder_search.svg') no-repeat;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin: 0 10px 3px 0;
        }
        */
      }
    }
  }
}

/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}

.news-create-button {
  border: solid 1px #B1ACAC;
  height: 42px;
  padding: 0 1rem;
  margin-left: 8px;
  border-radius: 2px;
  width: 145px;
}

.space-management-table-container {
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
}

::v-deep(#space-management-list-table) {
  border-bottom: solid 1px #dee2e6;
  th {
    background-color: #f9f9f9;
    font-weight: 14px;
    color: #707070;
  }
}

/* overwrite */
.b-table-sticky-header {
  max-height: 100%;
  border-radius: 8px;
}
/* overwrite */
::v-deep(.button-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
/* overwrite */
::v-deep(.normal-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}

::v-deep(.space-id-column-header) {
  display: none !important;
}
::v-deep(.space-id-column-cell) {
  display: none !important;
}
::v-deep(.space-public-column-header) {
  width: 16%;
}
::v-deep(.space-public-column-cell) {
  width: 16%;
}
::v-deep(.space-space-name-column-header) {
  text-align: left;
}
::v-deep(.space-space-name-column-cell) {
  text-align: left;
}
::v-deep(.detail-button-column-header) {
  width: 100px;
}
::v-deep(.detail-button-column-cell) {
  width: 100px;
}

::v-deep(.custom-pagenation) {
  margin-right: 16px;
  /* overwrite */
  .page-item {
    margin: 0;
  }
  /* overwrite */
  .page-link {
    color: #707070
  }
  .active {
    .page-link {
      font-weight: bold;
      color: #ffffff;
      background-color: #12B6D4;
      border: #12B6D4;
      height: 100%;
    }
  }
}

.button-column {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
  width: 10%;
}

.confirm-button {
  font-size: 12px;
  color: #707070;
  background-color: #EBE8E8;
  border: #C6C6C6;
  border-radius: 8px;
  padding: 8px 16px;
  &:hover {
    background-color: #C6C6C6;
    color: #ffffff;
    font-weight: bold;
  }
}

.target-month {
  align-items: center; 
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  .action-button {
    color: #FFFFFF;
    background-color: #12B6D4;
    border: none;
    font-size: 12px;
    padding: 16px 32px;
    margin: 32px;
    font-weight: bold;
  }
}
.plane-action-button-wrapper {
  display: flex;
  justify-content: center;
  .action-button {
    color: #707070;
    background-color: #FFFFFF;
    border: solid 1px #C6C6C6;
    border-radius: 16px;
    font-size: 12px;
    padding: 10px 32px;
    margin: 32px;
    font-weight: bold;
  }
}
</style>
