import { render, staticRenderFns } from "./AppAdminNewsEdit.vue?vue&type=template&id=d6ea777a&scoped=true&"
import script from "./AppAdminNewsEdit.vue?vue&type=script&lang=js&"
export * from "./AppAdminNewsEdit.vue?vue&type=script&lang=js&"
import style0 from "./AppAdminNewsEdit.vue?vue&type=style&index=0&id=d6ea777a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6ea777a",
  null
  
)

export default component.exports