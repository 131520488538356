<template>
  <div class="upload-image-container">
    <!-- Preview -->
    <div class="preview-container">
      <div class="image-preview">
        <img
          v-if="src"
          :src="src"
          width="200"
          height="133"
          alt
        />
        <default-image
          v-else
          width="200"
          height="133"
        />
        <close-button
          v-if="src"
          @click="() => $_removeImage()"
          class="close-image-button"
        />
        <div
          v-if="imageName"
          :style="{ textAlign: 'center', marginTop: '8px' }"
        >
          {{ imageName }}
        </div>
      </div>
    </div>

    <!-- Upload UI -->
    <label
      v-if="type === 'button'"
      for="select_file"
      class="select-image"
    >
      <add-image-header class="add-image-header" />
      写真を選択
      <input
        type="file"
        class="file_input"
        accept="image/*"
        style="display:none;"
        id="select_file"
        mulitple
        @change="$_onImageChange"
      />
    </label>

    <label
      v-else
      :for="`add_file_${docType}`"
      class="add-image"
      :style="{ flexDirection: 'column' }"
    >
      <div>
        <add-image-header class="add-image-header"/>
        写真を追加
      </div>
      <div>
        <span :style="{ color: 'gray' }">{{ underButtonText }}</span>
      </div>
      <input
        type="file"
        class="file_input"
        accept="image/*"
        style="display:none;"
        :id="`add_file_${docType}`"
        mulitple
        @change="$_onImageChange"
      />
    </label>
  </div>
</template>

<script>
import DefaultImage from '@/assets/image/icon_default_doc_image.svg?component'
import CloseButton from '@/assets/image/icon_image_close_button.svg?component'
import AddImageHeader from '@/assets/image/icon_add_image_header.svg?component'

export default {
  name: 'AppAdminUploadImage',
  components: {
   'default-image': DefaultImage,
   'close-button': CloseButton,
   'add-image-header': AddImageHeader
  },
  props: {
    docType: {
      type: String,
      default: 'default'
    },
    underButtonText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    imageName: {
      type: String,
      default: ''
    },
  },
  methods: {
    $_onImageChange(event) {
      //画像データ取得
      const fileList = event.target.files;
      Array.from(fileList).forEach(file => this.$_previewImage(file))
    },
    // アップロードした画像を表示
    $_previewImage(file) {
      let reader = new FileReader()
      reader.onload = (e) => {
        const base64 = e.target.result
        this.$emit('changed-image', {
          fileObject: file,
          base64: base64,
        })
      }
      reader.readAsDataURL(file)
    },
    $_removeImage() {
      // to be reactive uploadImages
      this.$emit('remove-image')
    }
  },
}
</script>

<style lang="scss" scoped>
.add-image {
  color: #12b6d4;
  font-size: 14px;
  display: flex;
  margin-bottom: 0;
  cursor: pointer;
}
.select-image {
  color: #707070;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #12b6d4;
  padding: 8px;
  font-size: 14px;
}
.add-image-header {
  margin-right: 8px;
}
.preview-container {
  display: flex;
  gap: 2px;
  width: 200px;
  flex-wrap: wrap;
}
.image-preview {
  position: relative;
}
.close-image-button {
  position: absolute;
  top: -14px;
  right: -14px;
}
.upload-image-container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 24px 0;
}
</style>