<template>
  <div class="app-admin-ellipse-button">
    <b-button
      variant="outline-secondary"
      :style="{ width: width, height: height}"
      @click="$emit('button-click')"
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AppAdminEllipseButton',
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.app-admin-ellipse-button {
  display: inline;
  .ellipse-button {
    color: #707070;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 8px 32px;
  }
}
/* overwrite */
::v-deep(.btn-outline-secondary) {
  border-radius: 30px;
}
</style>