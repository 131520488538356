<template>
  <div class="app-admin-space-create">
    <!-- Title -->
    <div class="space-create-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="space-create-title">新規スペース作成</p>
    </div>

    <!-- 新規スペース作成 -->
    <div class="space-create">
      <SpaceDataDetail />
    </div>
  </div>
</template>

<script>
import SpaceDataDetail from '@/components/organisms/admin/space/AppAdminSpaceDataDetail'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { getSpaceImages } from '@/helper/place'
import { getSpecifiedSpace } from '@/helper/functions'
import { getSpace } from '@/helper/firestore/space'

export default {
  name: 'AppAdminSpaceCreate',
  components: {
    SpaceDataDetail,
    IconLeftArrow,
  },
  props: {},
  data() {
    return {
      space: null,
    }
  },
  computed: {
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    images: function () {
      return this.space && this.space.images ? this.space.images : []
    },
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    await this.$_setSpaceDetail()

    this.$nextTick(function(){
      this.$store.dispatch('loadingMask/hideLoadingMask')
    });
  },
  methods: {
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/space_list')
    },
    /**
     * スペース詳細情報を取得する
     */
    $_setSpaceDetail: async function () {
      if (this.$route.query.placeId) {
        // spaceデータ取得 → placeIdをキーにspaceオーナーのユーザー情報を引く
        // const spaceObject = await getSpecifiedSpace(this.$route.query.placeId)

        // space画像を取得してthis.space.imagesに埋め込む
        // 先にthis.spaceにspaceObjectを入れてしまうとタイミング的に
        // imagesが取得できない
        // -> 20230215現在スペースのサムネイルはない
        // spaceObject.images = await getSpaceImages({
        //   placeId: this.$route.query.placeId,
        //   ...spaceObject,
        // })
        // this.space = spaceObject
        this.space = await getSpace(this.$route.query.placeId)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.app-admin-space-create {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  .space-create-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    padding: 1rem;
    .back-arrow-button {
      border: 1px solid lightgray;
      border-radius: 4px;
      background-color: white;
      height: 32px;
      width: 32px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
      }
    }
    .space-create-title {
      color: #707070;
      font-size: 24px;
      font-weight: bold;
      margin: 0px !important;
      line-height: 32px;
    }
  }
  .space-create {
    border: solid 1px lightgray;
    box-shadow: 0px 5px 7px lightgrey;
    display: flex;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 8px 16px;
  min-width: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
</style>