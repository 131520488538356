<template>
  <div class="news-saved-container">
    <!-- Title -->
    <div class="news-saved-title-wrapper">
      <p class="news-saved-title">お知らせ管理 投稿完了</p>
    </div>

    <div class="news-saved-body-wrapper">
      <p class="content">お知らせの投稿が完了しました。</p>
      <button
        class="action-button"
        @click="$router.push('/admin/news')"
      >
        お知らせ管理にもどる
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsSaved',
}
</script>

<style lang="scss" scoped>
.news-saved-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  .news-saved-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    .news-saved-title {
      color: #707070;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .news-saved-body-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px lightgray;
    border-radius: 8px;
    .content {
      color: #707070;
      font-weight: bold;
    }
  }
}

/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
input[type=checkbox]:checked + .checkbox01::after {
    opacity: 1;
}
.action-button {
  color: #707070;
  background-color: #FFFFFF;
  border: solid 1px #C6C6C6;
  border-radius: 16px;
  width: 200px;
  font-size: 12px;
  padding: 8px 32px;
  margin: 0 32px;
}
</style>
