<template>
  <div class="app-admin-detail-input">
    <!-- Form title -->
    <div class="sub-title">{{ title }}</div>

    <!-- Value limitation -->
    <div v-if="showEdit" class="item-label">
      <p v-if="required" class="required-label">必須</p>
      <p v-else class="optional-label">任意</p>
      <!-- v-text使用はタグ間のインデントや改行も出力されてしまうのを避けるため -->
      <div
        v-if="requiredRemarks"
        class="limitation-label"
        v-text="requiredRemarks"
      />
    </div>

    <div v-if="showEdit" class="input-wrapper">
      <!-- Input form -->
      <input
        v-if="isInputForm"
        class="default-input-form"
        v-model="editValue"
        :type="formType"
        :maxlength="maxlength"
      />

      <!-- Radio button form -->
      <div
        v-else-if="isRadioButtonForm"
        class="radio-button-input-form"
      >
        <input
          id="01-A"
          name="radio01"
          v-model="editValue"
          :type="formType"
          :value="radioValue01"
        >
        <label for="01-A" class="radio01">{{ radioValue01 }}</label>
        <input
          id="01-B"
          name="radio01"
          v-model="editValue"
          :type="formType"
          :value="radioValue02"
        >
        <label for="01-B" class="radio01">{{ radioValue02 }}</label>
      </div>

      <!-- Select -->
      <b-form-select
        v-else-if="isSelectForm"
        class="select-input-form"
        v-model="editValue"
        :options="prefectureOptions"
      />
    </div>
    <div v-else-if="showConfirm" class="detail-text">{{ editValue }}</div>
    <div v-else-if="showSaved">{{ editValue }}</div>
    <div v-else class="detail-text">{{ value }}</div>
  </div>
</template>

<script>
import prefectures from '@/js/prefectures'

export default {
  name: 'AppAdminDetailInput',
  components: {},
  props: {
    formType: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: null
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    showSaved: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredRemarks: {
      type: String,
      default: ''
    },
    maxlength: {
      type: String,
      default: ''
    },
    radioValue01: {
      type: String,
      default: ''
    },
    radioValue02: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editValue: null
    }
  },
  computed: {
    isInputForm() {
      return ['text', 'number'].includes(this.formType)
    },
    isRadioButtonForm() {
      return this.formType === 'radio'
    },
    isSelectForm() {
      return this.formType === 'select'
    },
    prefectureOptions: function () {
      return [
        { value: '', text: '選択', disabled: true },
        ...prefectures
      ]
    },
  },
  mounted() {
   this.editValue = this.value
  },
  watch: {
    showEdit() {
      if (!this.showEdit && !this.showConfirm && !this.showSaved) {
        // end of editing -> reset editValue
        this.editValue = this.value
      }
    },
    value() {
      this.editValue = this.value
    },
    editValue() {
      this.$emit('value-changed', this.editValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-admin-detail-input {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
  border: solid 1px #D6D6D6;
  border-radius: 4px;
  .bold {
    font-weight: bold;
  }
  .item-label {
    width: 96px;
    font-size: 8px;
    flex-shrink: 0;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: solid 1px #D6D6D6;
    text-align: center;
    .optional-label {
      color: #707070;
      font-weight: bold;
      margin-bottom: 0;
    }
    .required-label {
      color: red;
      font-weight: bold;
      margin-bottom: 0;
    }
    .limitation-label {
      color: #707070;
      margin-bottom: 0;
      white-space: pre-wrap;
    }
  }
  .input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 16px;
    .default-input-form {
      width: 100%;
      border: none;
    }
    .radio-button-input-form {
      width: 360px;
      /* overwrite */
      input[type=radio] {
          display: none;
      }
      .radio01 {
          user-select: none;
          box-sizing: border-box;
          cursor: pointer;
          display: inline-block;
          padding: 0 30px;
          position: relative;
          width: auto;
          margin-bottom: 0;
      }
      .radio01::before {
          background: #fff;
          border: 1px solid #231815;
          border-radius: 50%;
          content: '';
          display: block;
          height: 16px;
          left: 5px;
          margin-top: -8px;
          position: absolute;
          top: 50%;
          width: 16px;
      }
      .radio01::after {
          background: #12B6D4;
          border-radius: 50%;
          content: '';
          display: block;
          height: 10px;
          left: 8px;
          margin-top: -4px;
          opacity: 0;
          position: absolute;
          top: 48%;
          width: 10px;
      }
      input[type=radio]:checked + .radio01::after {
          opacity: 1;
      }
    }
    .select-input-form {
      width: auto;
    }
  }
  .sub-title {
    width: 13%;
    min-width: 260px;
    background-color: #D6D6D6;
    border-right: solid 1px #D6D6D6;
    color: #707070;
    font-size: 14px;
    font-weight: bold;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail-text {
    width: 87%;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto 1rem;
  }
}
</style>