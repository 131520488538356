<template>
  <div class="news">
    <div :style="{ height: '28px' }"></div>

    <div class="news-sub-title-container">
      <div class="sub-title">カテゴリ</div>
      <div class="news-text">{{ category }}</div>
    </div>

    <div class="news-sub-title-container">
      <div class="sub-title">公開日時</div>
      <div class="news-text">{{ formattedDate }}</div>
    </div>

    <div class="news-sub-title-container">
      <div class="sub-title">タイトル</div>
      <div class="news-text">{{ title }}</div>
    </div>

    <div class="news-sub-title-container multi-line-row">
      <div class="sub-title">本文</div>
      <div class="news-text">
        <CommonTextarea
          v-model="lineFeedText"
          :readonly="true"
          :prefix="'news-confirm'"
        />
      </div>
    </div>

    <!-- Action buttons -->
    <div class="action-button-wrapper">
      <EllipseButton
        :buttonText="'編集にもどる'"
        :width="'312px'"
        :height="'36px'"
        @button-click="$_onCancelClick"
      />
      <EllipseButton
        :buttonText="'この内容で投稿する'"
        :width="'312px'"
        :height="'36px'"
        @button-click="$_onClickSaveEdit"
      />   
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import { putNews, postNews } from '@/helper/firestore/news'
import {  showBottomInfoToast, showInfoPopupAlert } from '@/helper/common'
import moment from 'moment'

export default {
  name: 'AppAdminNewsConfirm',
  components: {
    CommonTextarea,
    EllipseButton
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    publishDate: {
      type: Date,
      default: new Date()
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    isTargetAdmin: {
      type: Boolean,
      default: false
    },
    isTargetTmpUser:  {
      type: Boolean,
      default: false
    },
    isTargetUser:  {
      type: Boolean,
      default: false
    },
    isTargetSpace: {
      type: Boolean,
      default: false
    },
    createAt: {
      type: Object,
      default: null
    }
  },
  computed: {
    lineFeedText: function () {
      return this.text.replace(/\\n/g, '\n')
    },
    formattedDate: function () {
     moment.locale('ja')
     return moment(this.publishDate).format('YYYY年MM月DD日(ddd) HH:mm')
    },
    formattedDateForSave: function () {
     moment.locale('ja')
     return moment(this.publishDate).format('YYYY/MM/DD HH:mm')
    },
    newsObject: function () {
      return {
        id: this.id,
        publishDate: this.formattedDateForSave,
        title: this.title,
        text: this.text.replace(/\n/g, '\\n'),
        category: this.category,
        isTargetAdmin: this.isTargetAdmin,
        isTargetTmpUser: this.isTargetTmpUser,
        isTargetUser: this.isTargetUser,
        isTargetSpace: this.isTargetSpace,
        createAt: this.createAt
      }
    }
  },
  methods: {
    /**
     * 戻るボタン押下処理
     */
    $_onCancelClick: function () {
      this.$emit('edit-cancel')
    },
    $_onClickSaveEdit: async function () {
      if (!this.$_validateInput()) return

      try {
        this.$store.dispatch('loadingMask/showLoadingMask')

        if (this.newsObject.id) {
          // お知らせ更新
          const news = await putNews(this.newsObject)
        } else {
          // お知らせ登録
          const news = await postNews(this.newsObject)
        }
        this.$router.push('/admin/news_saved')
      } catch (error) {
        showInfoPopupAlert(`更新に失敗しました。<br />${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_validateInput: function () {
      if (!this.formattedDateForSave) {
        showBottomInfoToast('error', '公開日時を入力して下さい。', 3000)
        return false
      } else if (!this.title) {
        showBottomInfoToast('error', 'タイトルを入力して下さい。', 3000)
        return false
      } else if (!this.text) {
        showBottomInfoToast('error', '本文を入力して下さい。', 3000)
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
/* overwrite */
.form-control {
  background-color: #fff;
}
.news-edit-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}

.news-edit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}

.news-edit-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.news-container {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
}

.news-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}
.news {
  border: solid 1px lightgray;
  box-shadow: 0px 5px 7px lightgrey;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 16px;
  height: 100%;
  padding: 16px 32px;
  color: #707070;
  font-size: 14px;
}

.news-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 16px 0 !important
}

.news-sub-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .sub-title {
    min-width: 220px;
  }
  .news-text {
    flex-grow: 1;
    p {
      margin-bottom: 0.25rem;
    }
  }
}
.multi-line-row {
  align-items: flex-start;
}
.edit-button {
  color: #707070;
  background-color: #EBE8E8;
  border: solid 1px #C6C6C6;
  border-radius: 16px;
  width: 170px;
  font-size: 12px;
  padding: 8px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}

/* ラジオボタン01 */
input[type=radio] {
    display: none;
}
.radio01 {
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
}
.radio01::before {
    background: #fff;
    border: 1px solid #231815;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
}
.radio01::after {
    background: #12B6D4;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 8px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 48%;
    width: 10px;
}
input[type=radio]:checked + .radio01::after {
    opacity: 1;
}
/* チェックボックス01 */
input[type=checkbox] {
    display: none;
}
.checkbox01 {
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
}
.checkbox01::before {
    background: #fff;
    border: 1px solid #231815;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
    border-radius: 8px;
}
.checkbox01::after {
    background: #12B6D4;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 8px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 46%;
    width: 10px;
}
input[type=checkbox]:checked + .checkbox01::after {
    opacity: 1;
}

.action-button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 64px;
  margin-top: 16px;
}
</style>
