<template>
  <div class="space-upload-csv">
    <!-- Title -->
    <div class="space-detail-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="space-detail-title">スペース一覧 CSV一括アップロード</p>
    </div>

    <!-- Contents -->
    <div class="space-upload-csv-container">
      <div v-if="complete" class="contents-wrapper">
        <div class="sub-title">
          アップロードが完了しました
        </div>

        <!-- Failed list -->
        <div v-if="failedData.length" class="upload-failed-wrapper">
          以下 {{ failedData.length }} 件のデータは登録処理に失敗しました。
          <div class="upload-failed-list">
            <div
              v-for="d, index in failedData"
              class="upload-failed-list-item"
              :key="`failed-data-${index}`"
            >
              <p class="upload-failed-item-name">{{ d[2] }}</p>
              <b-button
                class="action-button"
                @click="$_onClickRetry(d)"
              >
                再登録
              </b-button>
            </div>
          </div>
        </div>

        <div class="plane-action-button-wrapper">
          <button
            class="action-button"
            @click="$_onClickGoBack"
          >
            スペース情報にもどる
          </button>
        </div>
      </div>

      <div v-else class="contents-wrapper">
        <div class="sub-title">
          CSVを一括アップロードをする
        </div>
        <div class="action-button-wrapper">
          <label
            class="btn btn-info action-button"
            for="csv_upload_button2"
          >
            CSVをアップロードする
          </label>
          <b-form-file
            id="csv_upload_button2"
            style="display: none"
            v-on:change="$_onUpload($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { parseToCsv } from '@/helper/admin'
import { postSpace, putSpace } from '@/helper/firestore/space'
import { projectFunctions, httpsCallable } from '@/firebase/config'
import { mapGetters } from 'vuex'

// geocooding API has limitation of 10 requests per second. (1000 requests par day for free)
// 1000 / 5 (-5 to make sure)
// const geocoordingInterval = 1000 / 5
const geocoordingInterval = 1000

/**
 * 200件くらいまでなら一回のアップロードで行けるが、それ以上はOVER_QUERYが発生する可能性がある
 */
export default {
  name: 'AppAdminSpaceUploadCsv',
  components: { IconLeftArrow },
  data() {
    return {
      geocooder: null,
      failedData: [],
      functions: projectFunctions,
      getGoogleDriveFileList: null,
      googleDriveFiles: [],
      complete: false,
      count: 0,
      total: 0,
      subText: ''
    }
  },
  computed: {
    ...mapGetters('place', [['chunks']])
  },
  watch: {
    count() {
      this.$store.dispatch('loadingMask/setCount', this.count)
    },
    total() {
      this.$store.dispatch('loadingMask/setTotal', this.total)
    },
    subText() {
      this.$store.dispatch('loadingMask/setSubText', this.subText)
    }
  },
  async mounted() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    try {
      this.getGoogleDriveFileList = httpsCallable(
        this.functions,
        'getGoogleDriveFileList'
      )
      this.googleDriveFiles = await this.getGoogleDriveFileList()
      
      // loading google map
      if (!window.google || !this.geocooder) {
        const apiKey = process.env.VUE_APP_GOOGLE_MAP_KEY
        var s = document.createElement('script')
        s.type = 'text/javascript'
        s.src = 'https://maps.google.com/maps/api/js?key=' + apiKey
        var x = document.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
        // !important! we cannot access google.maps until it's finished loading
        s.addEventListener('load', (e) => {
          this.geocooder = new window.google.maps.Geocoder()
          this.$store.dispatch('loadingMask/hideLoadingMask')
        })
      } else if (!this.geocooder) {
        this.geocooder = new window.google.maps.Geocoder()
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    } catch (error) {
      alert('Google Driveの情報取得に失敗しました')
    }
  },
  methods: {
    /*
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/space_list')
    },
    // sb(a, b) {
    //   console.log(a[2], b[2])
    //   return list.indexOf(a[2]) - list.indexOf(b[2])
    // },
    async $_onUpload(event) {
      if (!this.chunks && !this.chunks.length) {
        alert('ローカルデータが取得できませんでした。再度ログインを願いします。')
        return
      }

      try {
        const data = await parseToCsv(event, 'space')
        // ヘッダーは除いて渡す
        const body = data.slice(1, data.length)
// const metaBody = data.slice(1, data.length)

// const body = metaBody.sort(this.sb).slice(0, 10)
// const body = metaBody.sort(this.sb).slice(0, 200)
// const body = metaBody.sort(this.sb).slice(200, 400)
// const body = metaBody.sort(this.sb).slice(400, 600)
// const body = metaBody.sort(this.sb).slice(600, 800)
// const body = metaBody.sort(this.sb).slice(800, 1000)
// const body = metaBody.sort(this.sb).slice(1000)
// console.log(body.map(a => a[2]))
        if (window.confirm(`${body.length} 件 更新しますか？`)) {
          // geocoding と firestore への書き込みごとに進捗を表示するので
          // 2回分を total へ設定
          this.total = body.length * 2
          this.subText = 'アップロード中...'
          this.$store.dispatch('loadingMask/showLoadingMask')
          await this.$_storeCsvData(body)
          this.complete = true
        }
      } catch (error) {
        if (error) alert(error) 
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    async $_onClickRetry(data) {
      // 総ステップ数
      this.total = 2
      // カウント初期化
      this.count = 0

      // 失敗結果配列から対象を削除
      this.failedData = this.failedData.filter(d => d.placeId !== data.placeId)

      // geocooding
      let jsonData = this.$_makeStoreJson(data)
      // if full address is set
      if (jsonData.fullAddress) {
        // waiting due to geocooding api limitation
        await new Promise((resolve) =>
          setTimeout(resolve, geocoordingInterval)
        )

        try {
          // inject coordinates
          jsonData.coordinates = await this.$_getCoordinates(
            jsonData.fullAddress
          )
        } catch (error) {
          console.log(error)
          this.failedData.push(data)
        }

        if (!jsonData.coordinates) {
          // failed to geocoding
          this.failedData.push(data)
        }
      } else {
        // there is no enough information to geocoording
        this.failedData.push(data)
      }

      // increace progress bar
      this.count++

      // waiting due to Firestore limitation(500/s)
      await new Promise((resolve) =>
        setTimeout(resolve, 20)
      )

      const result = data.placeId
        ? await putSpace(jsonData, jsonData.chunkId)
        : await postSpace(jsonData)

      if (this.failedData.length) {
        // geocoording失敗したデータ
        console.log('failed to geocoording *******************')
        console.log(this.failedData)
      }
    },
    $_onClickGoBack: async function () {
      this.$router.push('/admin/space_list')
    },
    $_makeStoreJson(data) {
      // check existion of full address info
      let fullAddress = ''
      if (data[8] && data[9]) {
        fullAddress = `${data[7]}${data[8]}${data[9]}`
      }

      const obj = {
        placeId: data[0],
        public: data[1],
        spaceName: data[2],
        embeddedStreetView: data[3],
        // twitterEmbeddedUrl: data[4],
        twitterKeywords: data[4],
        twitterKeywordUrl: data[5],
        zip: data[6],
        prefecture: data[7],
        area: data[8],
        address: data[9],
        applyUrl: data[10],
        remarks: data[11],
        likes: data[12] || 0,
        review: 0,
        // thumbnailName: data[13],
        // thumbnailUrl: this.$_getThumbnailUrl(data[13]),
        fullAddress: fullAddress,
        type: 'space'
      }

      // CSVにはChunkIDは含まれていないので、ここで取得
      // 取得できなまたはplaceIdがなければ新規と判定
      if (data[0]) {
        const targetChunk = this.chunks.find(c => c.placeId === data[0])
        obj.chunkId = targetChunk ? targetChunk.docId : ''
      }

      return obj
    },
    // $_getThumbnailUrl(fileName) {
    //   const ext = '.png'
    //   const file = this.googleDriveFiles.data.files.find(d => d.name === fileName + ext)
    //   return file
    //     // ? `http://drive.google.com/uc?export=view&id=${file.id}`
    //     // ? `https://drive.google.com/uc?id=${file.id}${ext}`
    //     ? `https://drive.google.com/uc?export=view&id=${file.id}`
    //     // ? file.webContentLink
    //     // ? file.webViewLink
    //     // ? `https://drive.google.com/file/d/${file.id}/view?usp=sharing`
    //     : ''
    // },
    // $_createBase64FromUrl(url) {
    //   const img = new Image()
    //   img.onload = () => {
    //     var canvas = document.createElement("canvas");
    //     canvas.width = img.width;
    //     canvas.height = img.height;
    //     var ctx = canvas.getContext("2d");
    //     ctx.drawImage(img, 0, 0);
    //     var dataURL = canvas.toDataURL("image/png");
    //     return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    //   }
    //   img.crossOrigin = "anonymous"; 
    //   img.src = url
    // },
    // $_dataURLtoFile(dataurl, filename) {
    //   var arr = dataurl.split(','),
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]), 
    //     n = bstr.length, 
    //     u8arr = new Uint8Array(n);
        
    //   while(n--){
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
      
    //   return new File([u8arr], filename, {type:mime});
    // },
    // $_makeArrayChunk([...array], size = 1) {
    //   return array.reduce(
    //     (acc, value, index) =>
    //       index % size ? acc : [...acc, array.slice(index, index + size)],
    //     []
    //   )
    // },
    async $_getCoordinates(address) {
      return new Promise((resolve, reject) => {
        try {
          this.geocooder.geocode(
            {
              address: address,
            },
            (result, status) => {
              if (status === 'OK') {
                resolve({
                  lng: result[0].geometry.location.lng(),
                  lat: result[0].geometry.location.lat(),
                })
              } else {
                console.log('error: ', status)
                resolve()
              }
            }
          )
        } catch (err) {
          console.log(err)
          resolve()
        }
      })
    },
    async $_storeCsvData(data) {
      // geocooding
      const jsonArray = []
      let jsonData = {}
      for (const d of data) {
        jsonData = this.$_makeStoreJson(d)
        // if full address is set
        if (jsonData.fullAddress) {
          // waiting due to geocooding api limitation
          await new Promise((resolve) =>
            setTimeout(resolve, geocoordingInterval)
          )

          try {
            // inject coordinates
            jsonData.coordinates = await this.$_getCoordinates(
              jsonData.fullAddress
            )
          } catch (error) {
            console.log(error)
            this.failedData.push(d)
          }

          if (!jsonData.coordinates) {
            // failed to geocoding
            this.failedData.push(d)
          } else {
            // success
            jsonArray.push(jsonData)
          }
        } else {
          // there is no enough information to geocoording
          this.failedData.push(d)
        }
        // increace progress bar
        this.count++
      }

      // FIXME: batch処理用に500件ずつにまとめる
      let c = 0
      for (const data of jsonArray) {
        // waiting due to Firestore limitation(500/s)
        await new Promise((resolve) => setTimeout(resolve, 20))

        c++

        try {
          const result = data.placeId
            ? await putSpace(data, data.chunkId)
            : await postSpace(data)
          console.log(c, result)
        } catch (error) {
          alert(`ID:${data.placeId} の登録に失敗しました。`)
          console.log(data.placeId, error)
        }
      }

      if (this.failedData.length) {
        // geocoording失敗したデータ
        console.log('failed to geocoording *******************')
        console.log(this.failedData)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.space-upload-csv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.space-detail-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 1rem;
}
.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
.space-detail-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px !important;
  line-height: 32px;
}
.space-upload-csv-container {
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
  .contents-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .sub-title {
      color: #707070;
      font-size: 20px;
      font-weight: bold;
    }
    .upload-failed-wrapper {
      color: #707070;
      font-size: 14px;
      margin: 16px 0;
      max-height: 300px;
      overflow: auto;
      .upload-failed-list {
        overflow-y: auto;
        height: 200px;
        margin-top: 8px;
        padding-right: 8px;
      }
      .upload-failed-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .upload-failed-item-name {
          margin-top: 8px;
        }
      }
    }
  }
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  .action-button {
    color: #FFFFFF;
    background-color: #12B6D4;
    border: none;
    font-size: 12px;
    padding: 16px 32px;
    margin: 32px;
    font-weight: bold;
  }
}
.plane-action-button-wrapper {
  display: flex;
  justify-content: center;
  .action-button {
    color: #707070;
    background-color: #FFFFFF;
    border: solid 1px #C6C6C6;
    border-radius: 16px;
    font-size: 12px;
    padding: 10px 32px;
    margin: 32px;
    font-weight: bold;
  }
}
.action-button {
  color: #707070;
  background-color: #FFFFFF;
  border: solid 1px #C6C6C6;
  border-radius: 8px;
  font-size: 12px;
  padding: 8px 24px;
  font-weight: bold;
}
</style>
