<template>
  <div class="review-management-list-container">
    <!-- Title -->
    <p class="review-management-list-title">クチコミ管理</p>

    <div class="controle-header">
      <!-- Search Bar -->
      <div class="search_bar">
        <input
          v-model="keyword"
          type="text"
          placeholder="スペース名から検索"
        />
      </div>
    </div>

    <!-- Tables -->
    <b-tabs
      pills
      card
      class="review-management-tab-container"
      v-model="tabIndex"
    >
      <b-tab title="通報" active>
        <div class="review-management-table-container">
          <AdminTable
            :fields="objectionFields"
            :items="filteredObjectionItems"
            @row-clicked="$_onObjectionRowClick"
          >
          </AdminTable>
        </div>
      </b-tab>

      <b-tab title="未対応">
        <div class="review-management-table-container">
          <AdminTable
            :fields="reviewFields"
            :items="filteredUncheckedReviewItems"
            @row-clicked="$_onReviewRowClick"
          >
          </AdminTable>
        </div>
      </b-tab>

      <b-tab title="対応済み">
        <div class="review-management-table-container">
          <AdminTable
            :fields="handledReviewFields"
            :items="filteredCheckedReviewItems"
            @row-clicked="$_onReviewRowClick"
          >
          </AdminTable>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AdminTable from '@/components/organisms/admin/common/AppAdminTable'
import { sortByNum } from '@/helper/common'
import { getAllObjections } from '@/helper/firestore/objection'
import { getAllReviews } from '@/helper/firestore/review'

import moment from 'moment'

export default {
  name: 'AppAdminReviewList',
  components: {
    AdminTable
  },
  data() {
    return {
      tabIndex: 0,
      reviewItems: [],
      objectionItems: [],
      currentPage: 1,
      keyword: "",
      reviewFields: [
        {
          key: 'reviewId',
          thClass: 'id-column-header',
          tdClass: 'id-column-cell'
        },
        {
          class: "normal-column",
          thClass: 'date-column-header',
          tdClass: 'date-column-cell',
          key: 'createAt',
          label: '投稿日時',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'review-post-user-type-column-header',
          tdClass: 'review-post-user-type-column-cell',
          key: 'postUserType',
          label: '属性',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'review-space-name-column-header',
          tdClass: 'review-space-name-column-cell',
          key: 'spaceName',
          label: 'スペース名',
          sortable: true,
        },
      ],
      handledReviewFields: [],
      objectionFields: [
        {
          key: 'objectionId',
          thClass: 'id-column-header',
          tdClass: 'id-column-cell'
        },
        {
          key: 'reviewId',
          thClass: 'id-column-header',
          tdClass: 'id-column-cell'
        },
        {
          class: "normal-column",
          thClass: 'date-column-header',
          tdClass: 'date-column-cell',
          key: 'createAt',
          label: '通報日時',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'objection-space-name-column-header',
          tdClass: 'objection-space-name-column-cell',
          key: 'spaceName',
          label: 'スペース名',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    /**
     * ローディングマスクの状態
     * @type {Boolean}
     */
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    /**
     * キーワードがあればフィルタアリングする
     * キーワードがなければ有効なeventデータを返す
     * @type {Array.<Object>}
     */
     filteredCheckedReviewItems: function () {
      // キーワードフィルタ
      if (this.keyword) {
        return [...this.reviewItems]
          .filter(i => (
            i.spaceName.includes(this.keyword) &&
            i.checked
          ))
          .sort(this.$_sortByDate)
      } else {
        return [...this.reviewItems]
          .filter(i => i.checked)
          .sort(this.$_sortByDate)
      }
    },
    /**
     * キーワードがあればフィルタアリングする
     * キーワードがなければ有効なeventデータを返す
     * @type {Array.<Object>}
     */
     filteredUncheckedReviewItems: function () {
      // キーワードフィルタ
      if (this.keyword) {
        return [...this.reviewItems]
          .filter(i => (
            i.spaceName.includes(this.keyword) &&
            !i.checked
          ))
          .sort((a, b) => this.$_sortByDate(a, b, 'asc'))
      } else {
        return [...this.reviewItems]
          .filter(i => !i.checked)
          .sort((a, b) => this.$_sortByDate(a, b, 'asc'))
      }
    },
    /**
     * キーワードがあればフィルタアリングする
     * キーワードがなければ有効なeventデータを返す
     * @type {Array.<Object>}
     */
     filteredObjectionItems: function () {
      // キーワードフィルタ
      if (this.keyword) {
        return [...this.objectionItems]
          .filter(i => (
            i.spaceName.includes(this.keyword)
          ))
          .sort((a, b) => this.$_sortByDate(a, b, 'asc'))
      } else {
        return [...this.objectionItems]
          .sort((a, b) => this.$_sortByDate(a, b, 'asc'))
      }
    },
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    // 対応済みリストのみ公開/非公開を表示
    this.handledReviewFields = [ ...this.reviewFields ]
    this.handledReviewFields.splice(
      this.handledReviewFields.findIndex(f => f.key === 'spaceName'),
      0,
      {
        class: "normal-column",
        thClass: 'review-publication-column-header',
        tdClass: 'review-publication-column-cell',
        key: 'publication',
        label: '公開/非公開',
        sortable: true,
      }
    )

    // eventデータを取得し、テーブル表示用に変換
    await this.$_setReviewItems()
    await this.$_setObjectionItems()

    this.$nextTick(function(){
      if (this.$route.query.tab) {
        this.tabIndex = Number(this.$route.query.tab)
      }
      this.$store.dispatch('loadingMask/hideLoadingMask')
    })
  },
  methods: {
    async $_setReviewItems() {
      const reviews = await getAllReviews()
      this.reviewItems = reviews
        .map(this.$_convertToReviewTableItem)
    },
    async $_setObjectionItems() {
      const objections = await getAllObjections()
      this.objectionItems = objections
        .filter(ob => !ob.handled) // handled(処理済み)はリストに表示しない
        .map(this.$_convertToObjectionTableItem)
      },
    $_convertToReviewTableItem(review) {
      return {
        reviewId: review.docId,
        spaceId: review.spaceId,
        checked: review.checked,
        createAt: moment(review.createAt.seconds * 1000).format('YYYY/MM/DD HH:mm'),
        createAtBySecond:review.createAt.seconds,
        postUserType: review.postUserType,
        publication: review.publication ? '公開' : '非公開',
        spaceName: review.spaceName,
      }
    },
    $_convertToObjectionTableItem(objection) {
      return {
        objectionId: objection.docId,
        reviewId: objection.reviewId,
        createAt: moment(objection.createAt.seconds * 1000).format('YYYY/MM/DD HH:mm'),
        createAtBySecond:objection.createAt.seconds,
        spaceName: objection.spaceName,
      }
    },
    /**
     * 通報対応ページへ遷移
     * URL経由でreviewId, objectionIdを渡す
     * @param {Object} data 選択された行データ
     */
    $_onObjectionRowClick(data) {
      this.$router.push(
        {
           path: 'objection_handle',
           query: {
             objectionId: data.objectionId,
             reviewId: data.reviewId,
           }
         }
      )
    },
    /**
     * クチコミ対応ページへ遷移
     * URL経由でreviewIdを渡す
     * @param {Object} data 選択された行データ
     */
    $_onReviewRowClick(data) {
      this.$router.push(
        {
           path: 'review_handle',
           query: { reviewId: data.reviewId }
         }
      )
    },
    $_sortByDate(a, b, order='desc') {
      // 秒単位でソートする
      if (!a.createAtBySecond || !b.createAtBySecond) return
      return sortByNum(
        a.createAtBySecond,
        b.createAtBySecond,
        order
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.review-management-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.review-management-list-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.controle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_bar {
    color: #a0a0a0;
    margin: 16px 0;
    input {
      width: 35vw;
      min-width: 322px;
      max-width: 550px;
      height: 42px;
      padding-left: 20px;
      border: solid 1px #B1ACAC;
      border-radius: 2px;
      &::placeholder {
        font-size: 14px;
        color: #A0A0A0;
      }
    }
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
.review-management-tab-container {
  border: none;
  flex-grow: 1;
  /* overwrite */
  ::v-deep(.tab-content) {
    height: calc(100% - 36px)
  }
  /* overwrite */
  ::v-deep(.card-header) {
    font-size: 12px;
    background-color: white;
    border: none;
    padding: 0 12px;
    .nav-item {
      margin: 0;
      width: 120px;
      text-align: center;
      .nav-link {
        color: #707070;
        border-radius: 0;
        border-top: solid 1px #B1ACAC;
        border-bottom: solid 1px #B1ACAC;
        border-left: solid 1px #B1ACAC;
        &.active {
          color: white;
          background-color: #12B6D4; 
        }
      }
    }
    .card-header-pills {
      li:last-child {
        .nav-link {
          border-right: solid 1px #B1ACAC;
        }
      }
    }
  }
  /* overwrite */
  ::v-deep(.card-body) {
    height: calc(100% - 36px - 4px);
    padding: 1.25rem 3px 0 3px;
  }
}
.review-management-table-container {
  height: calc(100% - 62px);
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
}
::v-deep(#review-management-list-table) {
  border-bottom: solid 1px #dee2e6;
  th {
    background-color: #f9f9f9;
    font-weight: 14px;
    color: #707070;
  }
}
/* overwrite */
.b-table-sticky-header {
  max-height: 100%;
  border-radius: 8px;
}
/* overwrite */
::v-deep(.button-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
/* overwrite */
::v-deep(.normal-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
::v-deep(.id-column-header) {
  display: none !important;
}
::v-deep(.id-column-cell) {
  display: none !important;
}
::v-deep(.date-column-header) {
  width: 15%;
}
::v-deep(.date-column-cell) {
  width: 15%;
}
::v-deep(.objection-space-name-column-header) {
  width: 58%;
  text-align: left;
}
::v-deep(.objection-space-name-column-cell) {
  width: 58%;
  text-align: left;
}
::v-deep(.review-space-name-column-header) {
  width: 48%;
  text-align: left;
}
::v-deep(.review-space-name-column-cell) {
  width: 48%;
  text-align: left;
}
::v-deep(.review-publication-column-header) {
  width: 15%;
}
::v-deep(.review-publication-column-cell) {
  width: 15%;
}
::v-deep(.review-post-user-type-column-header) {
  width: 14%;
}
::v-deep(.review-post-user-type-column-cell) {
  width: 14%;
}
::v-deep(.review-space-name-column-header) {
  width: 44%;
  text-align: left;
}
::v-deep(.review-space-name-column-cell) {
  width: 44%;
  text-align: left;
}
::v-deep(.detail-button-column-header) {
  width: 12%;
}
::v-deep(.detail-button-column-cell) {
  width: 12%;
}
::v-deep(.custom-pagenation) {
  margin-right: 16px;
  /* overwrite */
  .page-item {
    margin: 0;
  }
  /* overwrite */
  .page-link {
    color: #707070
  }
  .active {
    .page-link {
      font-weight: bold;
      color: #ffffff;
      background-color: #12B6D4;
      border: #12B6D4;
      height: 100%;
    }
  }
}
.button-column {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
  width: 10%;
}
.confirm-button {
  font-size: 12px;
  color: #707070;
  background-color: #EBE8E8;
  border: #C6C6C6;
  border-radius: 8px;
  padding: 8px 16px;
  &:hover {
    background-color: #C6C6C6;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
