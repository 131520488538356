<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop
    shadow
    no-header
    body-class="custom-sidebar"
    width="250px"
  >
    <b-list-group flush>
      <b-list-group-item class="custom-sidebar-logo">
        <!-- Safari でSVGのグラデーションが効かないためpngで対応 -->
        <img class="posh-logo" :src="require('@/assets/image/logo/logo.png')" />
      </b-list-group-item>

      <b-list-group-item
        class="custom-sidebar-item"
        active-class="active-menu-item"
        v-for="item in sideMenu"
        :class="{ active_item_background_color: $_isSelected(item.activeTargets) }"
        :key="item.key"
        :to="item.to"
        exact
      >
        {{ item.text }}
      </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import { SIDE_MENU } from '@/constants/admin'

export default {
  name: 'AppAdminAside',
  data() {
    return {
      sideMenu: SIDE_MENU,
      dirty: false,
      activePage: 'dashboard' // default top page
    }
  },
  watch: {
    '$route': function (to, from) {
      this.activePage = to.path.split('/').pop()
    }
  },
  mounted() {
    this.activePage = this.$router.history.current.path.split('/').pop()
  },
  methods: {
    $_isSelected: function (activeTargets) {
      return activeTargets.includes(this.activePage)
    },
  },
}
</script>

<style lang="scss" scoped>
/* overwrite */
::v-deep(.custom-sidebar) {
  background-color: #EAEAEA;
}
.list-group-item {
  outline: none;
  background-color: #EAEAEA;
  border: none;
}
.custom-sidebar-logo {
  height: 56px;
}

.active_item_background_color {
  background-color: #f8f9fa;
}
.navbar-height {
  border-bottom: solid 1px lightgray;
  height: 56px;
}

/* サイドバートグル時はロゴ非表示 */
@media (max-width: 992px) {
  .posh-logo {
    visibility: hidden;
  }
}
</style>
