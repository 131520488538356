<template>
  <div>
    <!-- カレンダーヘッダ -->
    <div id="cal-header">
      <div class="pager-wrapper" v-on:click="setLastMonth">
        <icon-left-arrow class="header-arrow" />
      </div>
      <span class="selected-month">{{ year }}年&emsp;{{ month }}月</span>
      <div class="pager-wrapper" v-on:click="setNextMonth">
        <icon-right-arrow class="header-arrow" />
      </div>
    </div>

    <!-- カレンダーメイン -->
    <table id="cal-main">
      <!-- 曜日を表示させる（テーブルヘッダ） -->
      <thead>
        <th
          v-for="(dayname,index) in weekdays"
          :key="index"
        >
          {{ dayname }}
        </th>
      </thead>

      <!-- 日付を表示させる（テーブルボディ） -->
      <tbody>
        <tr v-for="(weekData,index) in calData" :key="index">
          <td class="cal-day" v-for="(dayNum,index) in weekData" :key="index" 
            v-on:click="dateClick(dayNum)"
            :class="{'cal-today': isToday(dayNum), active: isActiveDay(dayNum)}"
          >
            <span :class="{ grayout: $_isDisabledDate(year, month, dayNum) }">
              {{dayNum}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import IconRightArrow from '@/assets/image/icon_right_arrow.svg?component'

export default {
  name: 'AppCommonCalendar',
  components: {
    'icon-left-arrow': IconLeftArrow,
    'icon-right-arrow': IconRightArrow,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    spaceSchedules: {
      type: Array,
      default: () => [],
    },
    disablePastDateSelect: {
      type: Boolean,
      default: false
    },
    reservedDates: {
      type: Array,
      default: () => [],
    },
    initialMonth: {
      type: Date,
      default: () => {
        return new Date
      }
    },
    // 初期表示の月に与える（例: offset = 1 なら来月が初期表示になる）
    offset: {
      type: Number,
      default: 0
    }
  },
  data(){
    return{
      weekdays: ['日', '月', '火', '水', '木', '金', '土'],
      year: 2022,
      month: 1,
      activeDates: this.reservedDates,
      disabledDates: this.spaceSchedules,
      today: '',
    }
  },
  watch: {
    reservedDates () {
      this.activeDates = this.reservedDates
    },
    spaceSchedules () {
      this.disabledDates = Array.from(
        new Set([...this.disabledDates, ...this.spaceSchedules])
      )
    }
  },
  mounted(){
    // 予約確認画面では初期表示を一番早い日付の月に設定する
    // 予約選択画面では初期表示を予約可能な一ヶ月先（本日から）に設定する
    const y = this.initialMonth.getFullYear()
    const m = ('0' + (this.initialMonth.getMonth() + 1 + this.offset))
    const d = ('0' + this.initialMonth.getDate())

    // // 選択できない日付に今日から1ヶ月先の分を追加
    // this.initialMonth.setMonth(this.initialMonth.getMonth() + 1)
    // let checkDate = new Date()
    // checkDate.setDate(checkDate.getDate() - 1) // 最初に今日の日付をチェックするため、前日からスタート
    // while (checkDate < this.initialMonth) {
    //   checkDate.setDate(checkDate.getDate() + 1)
    //   this.disabledDates.push(checkDate.toLocaleDateString())
    // }

    // yearとmonthを設定
    this.year = y
    this.month = Number(m)
    // 今日の日付を設定
    this.today = y + '-' + m + '-' + d
  },
  methods: {
    /*
     * 対象の選択済み日付判定
     * 
     */
    isActiveDay: function (dayNum) {
      const targetDate = this.$_formatDate(this.year, this.month, dayNum)
      // return this.activeDates.includes(targetDate)
      return this.reservedDates.includes(targetDate)
    },
    /**
     * カレンダー日付クリック時の処理
     */
    dateClick: function (dayNum) {
      if (this.readOnly) return

      if (dayNum !== '') {
        const clickedDate = this.$_formatDate(this.year, this.month, dayNum)
        if (this.$_isDisabledDate(this.year, this.month, dayNum)) {
          return
        } else if (this.activeDates.includes(clickedDate)) {
          this.activeDates = this.activeDates.filter(d => d !== clickedDate)
        } else {
          this.activeDates.push(clickedDate)
        }
        this.$emit('change-date-selection', this.activeDates)
      }
    },
    /**
     * 今日かどうかの判定
     * 年、月は現在選択しているページ
     * 日は引数
     */
    isToday: function (day) {
      const date = this.year + '-' + ('0' + this.month).slice(-2) + "-" + day
      return this.today === date
    },
    /**
     * 先月のカレンダーを取得
     */
    setLastMonth: function () {
      const today = new Date()
      const nextMonth = new Date(this.year, this.month - 1)
      // 過去の月は禁止
      if (nextMonth < today) return

      if (this.month === 1) {
        this.year--
        this.month = 12
      } else {
        this.month--
      }
      this.day = -1
    },
    /**
     * 翌月のカレンダーを取得
     */
    setNextMonth: function () {
      if (this.month === 12) {
        this.year++
        this.month = 1
      } else {
        this.month++
      }
      this.day = -1
    },
    $_isDisabledDate: function(year, month, day) {
      if (this.disablePastDateSelect) {
        const today = new Date()
        const target = new Date(year, month - 1, day)
        return (
          !day ||
          target < today ||
          this.disabledDates.includes(this.$_formatDate(year, month, day))
        )
      } else {
        return this.disabledDates.includes(this.$_formatDate(year, month, day))
      }
    },
    $_formatDate: function(year, month, day) {
      return `${year}/${month}/${day}`
    }
  },
  computed: {
    calData: function () {
      const calData = []
      // 初日の曜日を取得
      const firstWeekDay = new Date(this.year, this.month - 1, 1).getDay()
      // 月の日数
      const lastDay = new Date(this.year, this.month, 0).getDate()
      // 日数カウント用
      let dayNum = 1
      // 週ごとのデータを作成して、calDateにpush
      while (dayNum <= lastDay) {
        const weekData = []
            
        // 日曜～土曜の日付データを配列で作成
        for (var i = 0; i <= 6; i++) {
          if (calData.length === 0 && i < firstWeekDay) {
            // 初週の1日以前の曜日は空文字
            weekData[i] = ''
          } else if (lastDay < dayNum) {
            // 最終日以降の曜日は空文字
            weekData[i] = ''
          } else {
            // 通常の日付入力
            weekData[i] = dayNum
            dayNum++
          }
        }
        calData.push(weekData)
      }
      return calData
    }
  }
}
</script>
<style scoped>
/*---------------------------------------
ヘッダのcss
---------------------------------------*/
#cal-header {
  font-size: 24px;
  padding: 0;
  text-align: center;
  margin-bottom: 16px; 
  background-color: #fff;
  display:flex;
  justify-content: center;
  align-items: center;
}
.pager-wrapper {
  width: 20%;
}
#cal-header span{
  padding: 0 20px;
  display: inline-block;
}
.selected-month {
  color: #707070;;
  margin: 0 16px;
  font-size: 18px;
  width: 160px;
}
.header-arrow {
  color: #12B6D4;
}
/*---------------------------------------
カレンダーのcss
---------------------------------------*/
#cal-main {
  font-size:14px;
  line-height:20px;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
#cal-main th {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  color: #999;
}
#cal-main td {
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.cal-today {
  background-color: #eeeeee;
}
.cal-day.active {
  background-color: #12B6D4;
  color: #fff;
  font-weight: bold;
}
.grayout {
  color: rgb(185, 185, 185)
}
</style>
