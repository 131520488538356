<template>
  <div class="app-admin-page-frame">
    <header v-show="showHeader">
      <appAdminHeader />
    </header>

    <aside v-show="showAside">
      <appAdminAside />
    </aside>

    <div :class="{ 'admin-wrapper': showHeader && showAside }">
      <slot name="content" />
    </div>
  </div>
</template>
  
<script>
import appAdminHeader from '@/components/organisms/admin/common/AppAdminHeader'
import appAdminAside from '@/components/organisms/admin/common/AppAdminAside'
  
export default {
  name: 'AppAdminPageFrame',
  components: {
    appAdminHeader,
    appAdminAside,
  },
  props: {},
  data(){
    return{
      showHeader: false,
      showAside: false,
    }
  },
  watch: {
    $route: async function(to, from) {
      this.showHeader = this.$router.history.current.meta.showHeader
      this.showAside = this.$router.history.current.meta.showAside
    },
  },
  mounted(){
    this.showHeader = this.$router.history.current.meta.showHeader
    this.showAside = this.$router.history.current.meta.showAside
  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss" scoped>
.app-admin-page-frame {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
/* 横幅992px以下 */
.admin-wrapper {
  height: calc(100vh - 56px);
  margin-top: 56px;
}
/* 横幅992px以上 */
@media (min-width: 992px) {
  /* メニューボタン非表示 */
  #menubtn,
  .b-sidebar-backdrop {
    display: none;
  }

  /* Sidebar常時表示 */
  .b-sidebar {
    display: block !important;
  }

  /* Sidebarの幅を確保 */
  .admin-wrapper {
    margin-left: 250px;
    width: calc(100% - 250px);
    height: calc(100vh - 56px);
    margin-top: 56px;
  }
}

</style>
  