<template>
  <div class="event-management-list-container">
    <!-- Title -->
    <p class="event-management-list-title">イベント一覧</p>

    <div class="controle-header">
      <!-- Search Bar -->
      <div class="search_bar">
        <input
          v-model="keyword"
          type="text"
          placeholder="イベント名、スペース名から検索"
        />
      </div>

      <!-- Action -->
      <div class="action-button-wrapper">
        <SquareButton
          :buttonText="'新規イベント作成'"
          @button-click="$_onClickCreate"
        />
        <SquareButton
          :buttonText="'CSVダウンロード'"
          @button-click="$_onClickDownloadCsv"
        />
        <SquareButton
          :buttonText="'CSVアップロード'"
          @button-click="$_onClickUploadCsv"
        />
      </div>
    </div>

    <!-- Table -->
    <div class="event-management-table-container">
      <AdminTable
        :fields="fields"
        :items="filteredItems"
        @row-clicked="$_rowClickCallback"
      >
      </AdminTable>
    </div>
  </div>
</template>

<script>
import AdminTable from '@/components/organisms/admin/common/AppAdminTable'
import SquareButton from '@/components/molecules/admin/AppAdminSquareButton'
import moment from 'moment'
import { sortByDate } from '@/helper/common'
// import common from '@/helper/firestore/common'
import { getEvent, getAllEvents } from '@/helper/firestore/event'
import { putChunk } from '@/helper/firestore/chunk'
// import { extractChunkData, getAvailableChunkDocId } from '@/helper/firestore/chunk'

export default {
  name: 'AppAdminEventList',
  components: {
    AdminTable,
    SquareButton
  },
  data() {
    return {
      keyword: "",
      fields: [
        {
          key: 'placeId',
          thClass: 'event-id-column-header',
          tdClass: 'event-id-column-cell'
        },
        {
          class: "normal-column",
          thClass: 'event-public-column-header',
          tdClass: 'event-public-column-cell',
          key: 'public',
          label: '公開設定',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'event-added-by-column-header',
          tdClass: 'event-added-by-column-cell',
          key: 'addedBy',
          label: '投稿者',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'event-event-name-column-header',
          tdClass: 'event-event-name-column-cell',
          key: 'eventName',
          label: 'イベント名',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'event-space-name-column-header',
          tdClass: 'event-space-name-column-cell',
          key: 'spaceName',
          label: 'スペース名',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'event-first-held-date-column-header',
          tdClass: 'event-first-held-date-column-cell',
          key: 'firstHeldDates',
          label: '開催初日',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'event-end-of-publication-date-column-header',
          tdClass: 'event-end-of-publication-date-column-cell',
          key: 'endOfPublicationDate',
          label: '掲載終了日',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'event-create-at-column-header',
          tdClass: 'event-create-at-column-cell',
          key: 'createAt',
          label: '登録日時',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    /**
     * ローディングマスクの状態
     * @type {Boolean}
     */
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    /**
     * Chunkデータ
     * @type {Array.<Object>}
     */
    places: function () {
      return this.$store.getters['place/validEventsForAdmin']
    },
    /**
     * 有効テーブル表示データ
     * データは作成日時でソートされる
     * @type {Array.<Object>}
     */
    tableData: function () {
      return this.places
        .filter(this.$_validation)
        .sort(this.$_sortByDate)
        .map((this.$_convertToTableData))
    },
    /**
     * キーワードがあればフィルタアリングする
     * キーワードがなければ有効なeventデータを返す
     * @type {Array.<Object>}
     */
    filteredItems: function () {
      // キーワードフィルタ
      if (this.keyword) {
        return this.tableData.filter(i => (
          i.eventName.includes(this.keyword) ||
          i.spaceName.includes(this.keyword)
        ))
      } else {
        return this.tableData
      }
    },
    /**
     * テーブルに表示されている行数を返す
     * @type {Number}
     */
    rowCount: function () {
      return this.filteredItems.length
    },
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    if (!this.places || !this.places.length) {
      await this.$store.dispatch('place/setChunks')
    }

    // await this.$_checkChunk()

    this.$nextTick(function(){
      this.$store.dispatch('loadingMask/hideLoadingMask')
    });
  },
  methods: {
    async $_checkChunk() {
      const chunkEvents = this.places
        .filter(p => (
          p.type === 'event'
        ))
      const noDate = chunkEvents.filter(a => !a.createAt)

      for (let i = 0; i < noDate.length; i++) {
        const { placeId, docId } = noDate[i]
        const event = await getEvent(placeId)
         const value = {
          ...noDate[i],
          createAt: new Date(event.createAt.toMillis())
         }
        await putChunk(docId, placeId, value)
        await new Promise(resolve => setTimeout(resolve, 100))
      }

// const allEvents = await getAllEvents()
// const target = allEvents.find(e => e.eventName === 'ふるさとグルメてらす〜東北地酒フェス2023〜')
// console.log(target)
//       console.log(new Date(target.createAt.toMillis()))
// //      for (let i = 0; i < target.length; i++) {
//       // for (let i = 0; i < 1; i++) {
//          const { placeId, docId } = ch
// console.log(placeId, docId)
// //         const space = await getSpace(placeId)
// //console.log(space)
//          const value = {
//           ...ch,
//           createAt: new Date(target.createAt.toMillis())
//          }
// console.log(value)
//         await putChunk(docId, placeId, value)
//         await new Promise(resolve => setTimeout(resolve, 100))
// //      }





// const cNmaes = spaces.map(s => s.spaceName)
// const allEvents = await getAllEvents()
// console.log(allEvents)

  // const a = allEvents.filter(ae => (
  //   chunkEvents.filter(ce => {
  //     console.log(ae.createAt, ce.createAt)
  //     console.log(ae.createAt.toMillis, ce.createAt.toMillis)
  //     console.log(ae.createAt.toMillis(), ce.createAt.toMillis())
  //     if (
  //       // ae.eventName === ce.eventName && ae.createAt!== ce.createAt
  //       (ae.eventName === ce.eventName && ae.spaceName === ce.spaceName) && ae.createAt.toMillis() !== ce.createAt.toMillis()
  //     ) {
  //       console.log(ae.eventName, ce.eventName, ae.spaceName, ce.spaceName, ae.createAt.toMillis(), ce.createAt.toMillis())
  //       console.log((ae.eventName === ce.eventName && ae.spaceName === ce.spaceName) && ae.createAt.toMillis() !== ce.createAt.toMillis())
  //     }
  //     return (
  //       // ae.eventName === ce.eventName && ae.createAt!== ce.createAt
  //       (ae.eventName === ce.eventName && ae.spaceName === ce.spaceName) && ae.createAt.toMillis() !== ce.createAt.toMillis()
  //     )
  //   }).length > 0
  // )).sort((a, b) => a.area > b.area ? 1 : -1)
  // const b = chunkEvents.filter(ae => (
  //   allEvents.filter((ce, index) => {
  //     // if (index === 0) console.log(index, ce.createAt.toMillis())
  //     if (
  //       // ae.eventName === ce.eventName && ae.createAt!== ce.createAt
  //       (ae.eventName === ce.eventName && ae.spaceName === ce.spaceName) && ae.createAt.toMillis() !== ce.createAt.toMillis()
  //     ) {
  //       console.log(ae.eventName, ce.eventName, ae.spaceName, ce.spaceName, ae.createAt.toMillis(), ce.createAt.toMillis())
  //       console.log((ae.eventName === ce.eventName && ae.spaceName === ce.spaceName) && ae.createAt.toMillis() !== ce.createAt.toMillis())
  //     }
  //     return (
  //       // ae.eventName === ce.eventName && ae.createAt!== ce.createAt
  //       (ae.eventName === ce.eventName && ae.spaceName === ce.spaceName) && ae.createAt.toMillis() !== ce.createAt.toMillis()
  //     )
  //   }).length > 0
  // )).sort((a, b) => a.area > b.area ? 1 : -1)

  // console.log(
  //   a,
  //   b,
  //   // a[0].eventName === b[0].eventName && a[0].spaceName === b[0].spaceName,
  //   // a[0].endOfPublicationDate === b[0].endOfPublicationDate,
  //   // a[1].eventName === b[1].eventName && a[1].spaceName === b[1].spaceName,
  //   // a[1].endOfPublicationDate === b[1].endOfPublicationDate
  // )

// const names = allSpaces.map(s => s.spaceName)
// console.log(names)
// console.log(names.filter(ss => !cNmaes.includes(ss)))

// const target = allSpaces.filter(s => t.includes(s.spaceName))
// console.log(target)

       // for (let i = 0; i < 1; i++) {
//        for (let i = 0; i < target.length; i++) {
// console.log(target[i])
//           // Event形式の値をChunk形式変換
//           const chunkValue = extractChunkData(target[i])
// console.log(chunkValue)

//           // 書き込み可能なChunkドキュメントIDを取得（配列が返ってくる）
//           const chunkId = await getAvailableChunkDocId()
// console.log(chunkId)
//           const chunkResult = await common.postChunk(
//             chunkValue,
//             chunkId,
//             target[i].docId
//           )
// console.log(chunkResult)
//        }

// const target = spaces.filter(s => t.includes(s.spaceName))
// console.log(target)
//       for (let i = 0; i < target.length; i++) {
//       // for (let i = 0; i < 1; i++) {
//          const { placeId, docId } = target[i]
// console.log(placeId, docId)
//          const space = await getSpace(placeId)
// console.log(space)
//          const value = {
//           ...target[i],
//           createAt: space.createAt
//          }
// console.log(value)
//         await putChunk(docId, placeId, value)
//         await new Promise(resolve => setTimeout(resolve, 100))
//       }
    },
    /**
     * 
     * @param {*} event 
     */
    $_getFirstHeldDate: function (event) {
      const dateArray= event.heldDates && event.heldDates.length
        ? event.heldDates.replace(/”/g, '').replace(/"/g, '').split(",")
        : ''
      moment.locale('ja')
      return dateArray.length
        ? moment(dateArray[0]).format('YYYY年MM月DD日 (ddd)')
        : ''
    },

    /**
     * 
     * @param {*} event 
     */
    $_getEndOfPublicationDate: function (event) {
      moment.locale('ja')
      return event.endOfPublicationDate
        ? moment(
            event.endOfPublicationDate.replace(/”/g, '').replace(/"/g, '')
          ).format('YYYY年MM月DD日 (ddd)')
        : ''
    },

    /**
     * 
     */
    $_onClickCreate() {
      this.$router.push('/admin/event_create')
    },

    /**
     * 
     */
     $_onClickDownloadCsv() {
      this.$router.push('/admin/event_download_csv')
    },

    /**
     * 
     */
     $_onClickUploadCsv() {
      this.$router.push('/admin/event_upload_csv')
    },

    /**
     * event詳細ページへ遷移
     * URL経由でplaceIdを渡す
     * @param {Object} row 選択された行データ
     */
    $_rowClickCallback(item) {
      this.$router.push(
        {
          path: 'event_detail',
          query: { placeId: item.placeId }
        }
      )
    },

    /**
     * 
     * @param {*} event 
     */
    $_validation(event) {
      return ( 
        event.type === 'event' &&
        event.placeId &&
        // event.publish &&
        event.eventName &&
        event.spaceName &&
        event.endOfPublicationDate &&
        event.heldDates
      )
    },

    /**
     * 
     * @param {*} a 
     * @param {*} b 
     */
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },

    /**
     * fetchしたeventデータをテーブル表示用に変換
     * @param {Object} event 対象ショップデータ
     */
    $_convertToTableData(event) {
      // timestamp の toDateやnanoseconds が undefined になる場合がる（原因不明）
      // よって秒数が正しくない値となるため、分までの表示とする（ソートは正しくできている）
      return {
        placeId: event.placeId,
        public: event.publish,
        addedBy: event.addedBy === 'user' ? 'ユーザー' : '管理者',
        eventName: event.eventName,
        spaceName: event.spaceName,
        firstHeldDates: this.$_getFirstHeldDate(event),
        endOfPublicationDate: this. $_getEndOfPublicationDate(event),
        createAt:  moment(event.createAt.seconds * 1000)
          .format('YYYY/MM/DD HH:mm')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.event-management-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.event-management-list-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.controle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_bar {
    color: #a0a0a0;
    margin: 16px 0;
    input {
      width: 35vw;
      min-width: 322px;
      max-width: 550px;
      height: 42px;
      padding-left: 20px;
      border: solid 1px #B1ACAC;
      border-radius: 2px;
      &::placeholder {
        font-size: 14px;
        color: #A0A0A0;
        /* TODO サーチアイコンをplaceholder先頭に追加する
        &::before {
          content:" ";
          background: url('/assets/image/icon_placeholder_search.svg') no-repeat;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin: 0 10px 3px 0;
        }
        */
      }
    }
  }
  .action-button-wrapper {
    display: flex;
    column-gap: 8px;
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
.news-create-button {
  border: solid 1px #B1ACAC;
  height: 42px;
  padding: 0 1rem;
  margin-left: 8px;
  border-radius: 2px;
  width: 145px;
}
.event-management-table-container {
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
}
::v-deep(#event-management-list-table) {
  border-bottom: solid 1px #dee2e6;
  th {
    background-color: #f9f9f9;
    font-weight: 14px;
    color: #707070;
  }
}
/* overwrite */
.b-table-sticky-header {
  max-height: 100%;
  border-radius: 8px;
}
/* overwrite */
::v-deep(.button-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
/* overwrite */
::v-deep(.normal-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
::v-deep(.event-id-column-header) {
  display: none !important;
}
::v-deep(.event-id-column-cell) {
  display: none !important;
}
::v-deep(.event-public-column-header) {
  width: 10%;
}
::v-deep(.event-public-column-cell) {
  width: 10%;
}
::v-deep(.event-added-by-column-header) {
  width: 10%;
}
::v-deep(.event-added-by-column-cell) {
  width: 10%;
}
::v-deep(.event-event-name-column-header) {
  width: 20%;
  text-align: left;
}
::v-deep(.event-event-name-column-cell) {
  width: 20%;
  text-align: left;
}
::v-deep(.event-space-name-column-header) {
  width: 20%;
  text-align: left;
}
::v-deep(.event-space-name-column-cell) {
  width: 20%;
  text-align: left;
}
::v-deep(.event-first-held-date-column-header) {
  width: 16%;
}
::v-deep(.event-first-held-date-column-cell) {
  width: 16%;
}
::v-deep(.event-end-of-publication-date-column-header) {
  width: 16%;
}
::v-deep(.event-end-of-publication-date-column-cell) {
  width: 16%;
}
::v-deep(.detail-button-column-header) {
  width: 100px;
}
::v-deep(.detail-button-column-cell) {
  width: 100px;
}
::v-deep(.event-create-at-column-header) {
  width: 200px;
}
::v-deep(.event-create-at-column-cell) {
  width: 200px;
}
::v-deep(.custom-pagenation) {
  margin-right: 16px;
  /* overwrite */
  .page-item {
    margin: 0;
  }
  /* overwrite */
  .page-link {
    color: #707070
  }
  .active {
    .page-link {
      font-weight: bold;
      color: #ffffff;
      background-color: #12B6D4;
      border: #12B6D4;
      height: 100%;
    }
  }
}
.button-column {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
  width: 10%;
}
.confirm-button {
  font-size: 12px;
  color: #707070;
  background-color: #EBE8E8;
  border: #C6C6C6;
  border-radius: 8px;
  padding: 8px 16px;
  &:hover {
    background-color: #C6C6C6;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
