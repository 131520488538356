<template>
  <div class="news-edit">
    <div :style="{ height: '28px' }"></div>

    <div class="news-sub-title-container">
      <div class="sub-title">カテゴリ</div>
      <div class="news-text news-category-radio-wrapper">
        <div v-for="ct in newsCategories" :key="ct.ID">
          <input
            type="radio"
            :id="ct.ID"
            name="radio01"
            v-model="editCategory"
            :value="ct.TEXT"
          >
          <label :for="ct.ID" class="radio01">
            {{ ct.TEXT }}
          </label>
        </div>
      </div>
    </div>

    <div class="news-sub-title-container">
      <div class="sub-title">公開日時</div>
      <div class="news-text">
        <b-form-select
          v-model="selectedYear"
          :options="yearOptions"
          :style="{ width: '80px' }"
        /><span :style="{ padding: '0 8px 0 4px' }" >年</span>
        <b-form-select
          v-model="selectedMonth"
          :options="monthOptions"
          :style="{ width: '80px' }"
        /><span :style="{ padding: '0 8px 0 4px' }" >月</span>
        <b-form-select
          v-model="selectedDate"
          :options="dateOptions"
          :style="{ width: '80px' }"
        /><span :style="{ padding: '0 8px 0 4px' }" >日</span>
        <b-form-select
          v-model="selectedHour"
          :options="hourOptions"
          :style="{ width: '80px', marginLeft: '16px' }"
        /><span :style="{ padding: '0 8px 0 4px' }" >時</span>
        <b-form-select
          v-model="selectedMinute"
          :options="minuteOptions"
          :style="{ width: '80px' }"
        /><span :style="{ padding: '0 8px 0 4px' }" >分</span>
      </div>
    </div>

    <div class="news-sub-title-container">
      <div class="sub-title">タイトル</div>
      <div class="news-text">
        <b-form-input
          placeholder="タイトルを入力"
          v-model="editTitle"
        />
      </div>
    </div>

    <div class="news-sub-title-container multi-line-row">
      <div class="sub-title">本文</div>
      <div class="news-text">
        <CommonTextarea
          v-model="editText"
          :prefix="'news-edit'"
          :placeholder="'お知らせ本文を入力'"
          :rows="'4'"
          :resize="true"
          :userStyle="textareaStyle"
        />
        <p :style="{ fontWeight: 'bold', margin: '8px 0'}">
          ※URLを入力する場合は改行をしてください。
        </p>
        <div :style="{ display: 'flex', gap: '24px' }">
          <p :style="{ paddingLeft: '8px' }">正しい例：<br />お知らせを追加しました。<br />https://poshspace.jp</p>
          <p :style="{ paddingLeft: '8px' }">誤った例：<br />お知らせを追加しました。https://poshspace.jp</p>
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="action-button-wrapper">
      <EllipseButton
        :buttonText="'もどる'"
        :width="'312px'"
        :height="'36px'"
        @button-click="$_onCancelClick"
      />
      <EllipseButton
        :buttonText="'登録する'"
        :width="'312px'"
        :height="'36px'"
        @button-click="$_onClickSaveEdit"
      />      
    </div>
    <div
      v-if="deleteAction"
      :style="{
        textAlign: 'center',
        textDecoration: 'underline',
        lineHeight: '3',
      }"
    >
       <span
        :style="{ cursor: 'pointer' }"
        @click="$_onClickDelete" 
       >
        投稿を削除する
      </span>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import { showBottomInfoToast } from '@/helper/common'
import { NEWS_CATEGORY } from '@/constants/common'
import moment from 'moment'

export default {
  name: 'AppAdminNewsEdit',
  components: {
    CommonTextarea,
    EllipseButton
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    publishDate: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    deleteAction: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      today: null,
      newsCategories: NEWS_CATEGORY,
      editCategory: this.category,
      editPublishDate: this.publishDate,
      selectedYear: null,
      yearOptions: [],
      selectedMonth: new Date().getMonth() + 1, // default => nownull,
      monthOptions: [],
      selectedDate: null,
      dateOptions: [],
      selectedHour: null,
      hourOptions: [],
      selectedMinute: null,
      minuteOptions: [],
      originalDateOptions: [],
      editTitle: this.title,
      editText: this.text,
      textareaStyle: {
        height: '124px',
        padding: '0.375rem 0.75rem',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '0.25rem',
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      }
    }
  },
  computed: {
    adminNews: function () {
      return this.$store.getters['news/adminNews']
       ? this.$store.getters['news/adminNews']
       : []
    },
    targetNews: function () {
      return this.adminNews.find(n => n.docId === this.$route.query.id)
    },
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    newsObject: function () {
      return {
        id: this.targetNews && this.targetNews.docId ? this.targetNews.docId : null,
        // publishDate: new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDate),
        publishDate: new Date(
          this.selectedYear,
          this.selectedMonth - 1,
          this.selectedDate,
          this.selectedHour,
          this.selectedMinute
        ),
        title: this.editTitle,
        text: this.editText.replace(/\\n/g, '\n'),
        category: this.editCategory,
      }
    }
  },
  watch: {
    selectedMonth: function () {
      this.dateOptions = this.originalDateOptions
        .filter(d => d <= this.$_getLastDay(this.selectedYear, this.selectedMonth))
    },
    targetNews: function () {
      if (this.targetNews.docId) {
        this.editPublishDate = this.targetNews.publishDate
        this.editTitle = this.targetNews.title
        this.editText = this.targetFaq.text.replace(/\\n/g, '\n')
        this.editCategory = this.targetNews.category
      }
    }
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    const today = new Date()
    this.yearOptions = [
      today.getFullYear(),
      today.getFullYear() + 1,
      today.getFullYear() + 2,
      today.getFullYear() + 3,
      today.getFullYear() + 4,
    ]
    this.monthOptions = [...Array(12)].map((_, i) => i + 1)
    this.dateOptions = [...Array(31)].map((_, i) => i + 1)
    this.hourOptions = [...Array(24)].map((_, i) => i)
    this.minuteOptions = [...Array(60)].map((_, i) => i) // 0-59
    this.originalDateOptions = [...this.dateOptions]

    const date = moment(this.publishDate)
    if (date) {
      // parsing publish date
      this.selectedYear = date.format('YYYY')
      this.selectedMonth = date.format('M')
      this.selectedDate = date.format('D')
      this.selectedHour = date.format('H')
      this.selectedMinute = date.format('m')
    } else {
      this.selectedYear = moment(today.seconds * 1000).format('YYYY')
      this.selectedMonth = moment(today.seconds * 1000).format('MM')
      this.selectedDate = moment(today.seconds * 1000).format('D')
      this.selectedHour = 0
      this.selectedMinute = 1
    }

    this.$nextTick(function(){
      this.$store.dispatch('loadingMask/hideLoadingMask')
    });
  },
  methods: {
    $_getLastDay: function (year, month) {
      // 月が不明なら月の最終日はわからないので31を返す
      if (!month) return 31
      // 年が不明なら今年として月のデータだけでDateオブジェクトを作る
      if (!year) {
        const currentYear = new Date().getFullYear()
        return new Date(currentYear, month, 0).getDate()
      }
      return new Date(year, month, 0).getDate()
    },
    $_onCancelClick: function () {
      // this.$router.push('/admin/news')
      this.$emit('edit-cancel')
    },
    $_onClickSaveEdit: async function () {
     if (!this.$_validateInput()) return
      this.$emit('edit-end', {
        ...this.newsObject,
        createAt: this.targetNews ? this.targetNews.createAt : null
      })
    },
    $_onClickDelete: function () {
      if (confirm('削除しますか？', '削除', 'もどる')) {
        this.$emit('delete-news', this.$route.query.id)
      }
    },
    $_validateInput: function () {
      if (!this.editCategory) {
        showBottomInfoToast('error', 'カテゴリを選択して下さい。', 3000)
        return false
      } else if (
        !this.selectedYear ||
        !this.selectedMonth ||
        !this.selectedDate
      ) {
        showBottomInfoToast('error', '公開日時を全て入力して下さい。', 3000)
        return false
      } else if (!this.editTitle) {
        showBottomInfoToast('error', 'タイトルを入力して下さい。', 3000)
        return false
      } else if (!this.editText) {
        showBottomInfoToast('error', '本文を入力して下さい。', 3000)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.news-edit {
  border: solid 1px lightgray;
  box-shadow: 0px 5px 7px lightgrey;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 12px;
  height: 100%;
  padding: 16px 32px;
  color: #707070;
  font-size: 14px;
}
.news-edit-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
.news-sub-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .news-category-radio-wrapper {
    display: flex;
  }
  .sub-title {
    min-width: 220px;
  }
  .news-text {
    flex-grow: 1;
    p {
      margin-bottom: 0.25rem;
    }
  }
}
.news-edit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}

.news-edit-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.news-container {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
}

.news-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.news {
  border: solid 1px lightgray;
  box-shadow: 0px 5px 7px lightgrey;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 16px 32px;
}

.news-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 16px 0 !important
}

.news-sub-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .sub-title {
    min-width: 220px;
  }
  .news-text {
    flex-grow: 1;
    label {
      margin-bottom: 0;
    }
  }
}
.multi-line-row {
  align-items: flex-start;
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}

/* ラジオボタン01 */
input[type=radio] {
    display: none;
}
.radio01 {
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
}
.radio01::before {
    background: #fff;
    border: 1px solid #231815;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
}
.radio01::after {
    background: #12B6D4;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 8px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 48%;
    width: 10px;
}
input[type=radio]:checked + .radio01::after {
    opacity: 1;
}
/* チェックボックス01 */
input[type=checkbox] {
    display: none;
}
.checkbox01 {
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
}
.checkbox01::before {
    background: #fff;
    border: 1px solid #231815;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
    border-radius: 8px;
}
.checkbox01::after {
    background: #12B6D4;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 8px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 46%;
    width: 10px;
}
input[type=checkbox]:checked + .checkbox01::after {
    opacity: 1;
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 64px;
  .action-button {
    font-size: 12px;
  }
}
</style>
