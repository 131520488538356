<template>
  <div class="space-management-list-container">
    <!-- Title -->
    <p class="space-management-list-title">スペース一覧</p>

    <div class="controle-header">
      <!-- Search Bar -->
      <div class="search_bar">
        <input
          v-model="keyword"
          type="text"
          placeholder="スペース名から検索"
        />
      </div>

      <!-- Action -->
      <div class="action-button-wrapper">
        <SquareButton
          :buttonText="'新規スペース作成'"
          @button-click="$_onClickCreate"
        />
        <SquareButton
          :buttonText="'CSVダウンロード'"
          @button-click="$_onClickDownloadCsv"
        />
        <SquareButton
          :buttonText="'CSVアップロード'"
          @button-click="$_onClickUploadCsv"
        />
      </div>
    </div>

    <!-- Table -->
    <div class="space-management-table-container">
      <AdminTable
        :fields="fields"
        :items="filteredItems"
        @row-clicked="$_rowClickCallback"
      >
      </AdminTable>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import common from '@/helper/firestore/common'
import AdminTable from '@/components/organisms/admin/common/AppAdminTable'
import SquareButton from '@/components/molecules/admin/AppAdminSquareButton'
import { getSpace, getAllSpaces } from '@/helper/firestore/space'
import { putChunk } from '@/helper/firestore/chunk'
import { extractChunkData, getAvailableChunkDocId } from '@/helper/firestore/chunk'
import { sortByDate } from '@/helper/common'

export default {
  name: 'AppAdminSpaceList',
  components: {
    AdminTable,
    SquareButton
  },
  data() {
    return {
      keyword: "",
      fields: [
        {
          key: 'placeId',
          thClass: 'space-id-column-header',
          tdClass: 'space-id-column-cell'
        },
        {
          class: "normal-column",
          thClass: 'space-public-column-header',
          tdClass: 'space-public-column-cell',
          key: 'public',
          label: '公開設定',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'space-added-by-column-header',
          tdClass: 'space-added-by-column-cell',
          key: 'addedBy',
          label: '投稿者',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'space-space-name-column-header',
          tdClass: 'space-space-name-column-cell',
          key: 'spaceName',
          label: 'スペース名',
          sortable: true,
        },
        {
          class: "normal-column",
          thClass: 'space-create-at-column-header',
          tdClass: 'space-create-at-column-cell',
          key: 'createAt',
          label: '登録日時',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    /**
     * ローディングマスクの状態
     * @type {Boolean}
     */
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    /**
     * Chunkデータ
     * @type {Array.<Object>}
     */
    places: function () {
      return this.$store.getters['place/chunks']
    },
    /**
     * 有効テーブル表示データ
     * データは作成日時でソートされる
     * @type {Array.<Object>}
     */
    tableData: function () {
      return this.places
        .filter(this.$_validation)
        .sort(this.$_sortByDate)
        .map((this.$_convertToTableData))
    },
    /**
     * キーワードがあればフィルタアリングする
     * キーワードがなければ有効なspaceデータを返す
     * @type {Array.<Object>}
     */
    filteredItems: function () {
      // キーワードフィルタ
      if (this.keyword) {
        return this.tableData.filter(i => (
          i.spaceName && i.spaceName.includes(this.keyword)
        ))
      } else {
        return this.tableData
      }
    },
    /**
     * テーブルに表示されている行数を返す
     * @type {Number}
     */
    rowCount: function () {
      return this.filteredItems.length
    }
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    if (!this.places || !this.places.length) {
      await this.$store.dispatch('place/setChunks')
    }

    // await this.$_checkChunk()

    this.$nextTick(function(){
      this.$store.dispatch('loadingMask/hideLoadingMask')
    })
  },
  methods: {
    async $_checkChunk() {
      const t = [
        "桃井原っぱ公園",
        "馬橋公園",
        "塚山公園",
        "下高井戸おおぞら公園",
        "蚕糸の森公園",
        "柏の宮公園",
        "井草森公園",
        "JAさわやか館",
        "東池袋中央公園",
      ]
      const spaces = this.places
        .filter(p => (
          p.type === 'space'
        ))
console.log(spaces)

// const cNmaes = spaces.map(s => s.spaceName)
// const allSpaces = await getAllSpaces()
// console.log(allSpaces)

// const names = allSpaces.map(s => s.spaceName)
// console.log(names)
// console.log(names.filter(ss => !cNmaes.includes(ss)))

// const target = allSpaces.filter(s => t.includes(s.spaceName))
// console.log(target)

       // for (let i = 0; i < 1; i++) {
//        for (let i = 0; i < target.length; i++) {
// console.log(target[i])
//           // Event形式の値をChunk形式変換
//           const chunkValue = extractChunkData(target[i])
// console.log(chunkValue)

//           // 書き込み可能なChunkドキュメントIDを取得（配列が返ってくる）
//           const chunkId = await getAvailableChunkDocId()
// console.log(chunkId)
//           const chunkResult = await common.postChunk(
//             chunkValue,
//             chunkId,
//             target[i].docId
//           )
// console.log(chunkResult)
//        }

const target = spaces.filter(s => t.includes(s.spaceName))
console.log(target)
      for (let i = 0; i < target.length; i++) {
      // for (let i = 0; i < 1; i++) {
         const { placeId, docId } = target[i]
console.log(placeId, docId)
         const space = await getSpace(placeId)
         const value = {
          ...target[i],
          createAt: space.createAt
         }

        await putChunk(docId, placeId, value)
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    },
    /**
     * 
     * @param {*} a 
     * @param {*} b 
     */
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },

    /**
     * 
     */
    $_onClickCreate() {
      this.$router.push('/admin/space_create')
    },

    /**
     * 
     */
    $_onClickDownloadCsv() {
      this.$router.push('/admin/space_download_csv')
    },

    /**
     * 
     */
    $_onClickUploadCsv() {
      this.$router.push('/admin/space_upload_csv')
    },

    /**
     * space詳細ページへ遷移
     * URL経由でplaceIdを渡す
     * @param {Object} data 選択された行データ
     */
    $_rowClickCallback(item) {
      this.$router.push(
        {
           path: 'space_detail',
           query: { placeId: item.placeId }
         }
      )
    },
    /**
     * 
     * @param {Object} space
     */
    $_validation(space) {
      return ( 
        space.type === 'space' &&
        space.placeId &&
        // space.publish &&
        space.spaceName
      )
    },

    /**
     * fetchしたspaceデータをテーブル表示用に変換
     * @param {Object} space 対象ショップデータ
     */
     $_convertToTableData(space) {
      // if (!space.createAt) {
      //   console.log(space.spaceName)
      //   console.log(space.createAt)
      //   console.log(space.updateAt)
      // }
      // timestamp の toDateやnanoseconds が undefined になる場合がる（原因不明）
      // よって秒数が正しくない値となるため、分までの表示とする（ソートは正しくできている）
      return {
        placeId: space.placeId,
        public: space.publish,
        addedBy: space.addedBy === 'user' ? 'ユーザー' : '管理者',
        spaceName: space.spaceName,
        createAt:  space.createAt && moment(space.createAt.seconds * 1000)
          .format('YYYY/MM/DD HH:mm')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.space-management-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.space-management-list-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.controle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_bar {
    color: #a0a0a0;
    margin: 16px 0;
    input {
      width: 35vw;
      min-width: 322px;
      max-width: 550px;
      height: 42px;
      padding-left: 20px;
      border: solid 1px #B1ACAC;
      border-radius: 2px;
      &::placeholder {
        font-size: 14px;
        color: #A0A0A0;
        /* TODO サーチアイコンをplaceholder先頭に追加する
        &::before {
          content:" ";
          background: url('/assets/image/icon_placeholder_search.svg') no-repeat;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin: 0 10px 3px 0;
        }
        */
      }
    }
  }
  .action-button-wrapper {
    display: flex;
    column-gap: 8px;
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
.news-create-button {
  border: solid 1px #B1ACAC;
  height: 42px;
  padding: 0 1rem;
  margin-left: 8px;
  border-radius: 2px;
  width: 145px;
}
.space-management-table-container {
  border: solid 1px lightgray;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0px 5px 7px lightgrey;
}
::v-deep(#space-management-list-table) {
  border-bottom: solid 1px #dee2e6;
  th {
    background-color: #f9f9f9;
    font-weight: 14px;
    color: #707070;
  }
}
/* overwrite */
.b-table-sticky-header {
  max-height: 100%;
  border-radius: 8px;
}
/* overwrite */
::v-deep(.button-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
/* overwrite */
::v-deep(.normal-column) {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
}
::v-deep(.space-id-column-header) {
  display: none !important;
}
::v-deep(.space-id-column-cell) {
  display: none !important;
}
::v-deep(.space-public-column-header) {
  width: 10%;
}
::v-deep(.space-public-column-cell) {
  width: 10%;
}
::v-deep(.space-added-by-column-header) {
  width: 10%;
}
::v-deep(.space-added-by-column-cell) {
  width: 10%;
}
::v-deep(.space-space-name-column-header) {
  text-align: left;
}
::v-deep(.space-space-name-column-cell) {
  text-align: left;
}
::v-deep(.detail-button-column-header) {
  width: 100px;
}
::v-deep(.detail-button-column-cell) {
  width: 100px;
}
::v-deep(.space-create-at-column-header) {
  width: 200px;
}
::v-deep(.space-create-at-column-cell) {
  width: 200px;
}
::v-deep(.custom-pagenation) {
  margin-right: 16px;
  /* overwrite */
  .page-item {
    margin: 0;
  }
  /* overwrite */
  .page-link {
    color: #707070
  }
  .active {
    .page-link {
      font-weight: bold;
      color: #ffffff;
      background-color: #12B6D4;
      border: #12B6D4;
      height: 100%;
    }
  }
}
.button-column {
  font-size: 12px;
  text-align: center;
  vertical-align: middle !important;
  width: 10%;
}
.confirm-button {
  font-size: 12px;
  color: #707070;
  background-color: #EBE8E8;
  border: #C6C6C6;
  border-radius: 8px;
  padding: 8px 16px;
  &:hover {
    background-color: #C6C6C6;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
