<template>
  <div class="my-page">
    <!-- Title -->
    <h4 class="my-page-title mb-2">マイページ</h4>

    <!-- My Page -->
    <div class="my-page-content">
      <div class="header-title">アカウント設定</div>

      <div v-if="editName" class="my-page-item">
        <div class="my-page-item-container">
          <div class="sub-title">管理者名</div>
          <input class="my-page-input" type="text" v-model="editNameValue" />
        </div>

        <div class="action-button-wrapper">
          <EllipseButton
            :buttonText="'もどる'"
            :width="'200px'"
            :height="'36px'"
            @button-click="editName = false"
          />
          <EllipseButton
            :buttonText="'変更する'"
            :width="'200px'"
            :height="'36px'"
            @button-click="$_onSaveEditName"
          />
        </div>
      </div>

      <div v-else-if="editEmail" class="my-page-item">
        <div class="my-page-item-container">
          <div class="sub-title">メールアドレス</div>
          <input class="my-page-input" type="text" v-model="editEmailValue" />
        </div>

        <div class="action-button-wrapper">
          <EllipseButton
            :buttonText="'もどる'"
            :width="'200px'"
            :height="'36px'"
            @button-click="editEmail = false"
          />
          <EllipseButton
            :buttonText="'変更する'"
            :width="'200px'"
            :height="'36px'"
            @button-click="$_onSaveEditEmail"
          />
        </div>
      </div>

      <div v-else-if="editPassword" class="my-page-item">
        <div class="my-page-item-container">
          <div class="sub-title">現在のパスワード</div>
          <input class="my-page-input" type="text" v-model="currentPassword" />
        </div>
        <div class="my-page-item-container">
          <div class="sub-title"></div>
          <p
            :style="{ textDecoration: 'underline', cursor: 'pointer' }"
            @click="$router.push('/admin/send_reset_password')"
          >パスワードをお忘れお方</p>
        </div>
        <div class="my-page-item-container">
          <div class="sub-title">新しいのパスワード</div>
          <input class="my-page-input" type="text" v-model="newPassword" />
        </div>
        <div class="my-page-item-container">
          <div class="sub-title">新しいパスワード確認</div>
          <input class="my-page-input" type="text" v-model="confirmPassword" />
        </div>

        <div class="action-button-wrapper">
          <EllipseButton
            :buttonText="'もどる'"
            :width="'200px'"
            :height="'36px'"
            @button-click="editPassword = false"
          />
          <EllipseButton
            :buttonText="'変更する'"
            :width="'200px'"
            :height="'36px'"
            @button-click="$_onSaveEditPassword"
          />
        </div>
      </div>

      <div v-else class="my-page-item">
        <div class="my-page-item-container">
          <div class="sub-title">管理者名</div>
          <div class="my-page-text">{{ editNameValue }}</div>
          <EllipseButton
            :buttonText="'変更する'"
            :width="'98px'"
            @button-click="editName = true"
          />
        </div>
        <div class="my-page-item-container">
          <div class="sub-title">メールアドレス</div>
          <div class="my-page-text">{{ editEmailValue }}</div>
          <EllipseButton
            :buttonText="'変更する'"
            :width="'98px'"
            @button-click="editEmail = true"
          />
        </div>
        <div class="my-page-item-container">
          <div class="sub-title">パスワード</div>
          <div class="my-page-text">{{ editPasswordValue }}</div>
          <EllipseButton
            :buttonText="'変更する'"
            :width="'98px'"
            @button-click="editPassword = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import {
  auth,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  signOut
} from '@/firebase/config'
import { updateUser, getAccount } from '@/helper/functions'
import { showConfirmPopup,  showInfoPopupAlert } from '@/helper/common'

export default {
  name: 'AppAdminMyPage',
  components: {
    EllipseButton
  },
  data() {
    return {
      editName: false,
      editEmail: false,
      editPassword: false,
      editNameValue: '',
      editEmailValue: '',
      editPasswordValue: '******',
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.user
    },
    account: function () {
      return this.$store.getters.account
    },
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')
    this.$nextTick(async () => {
      try {
        const account = await getAccount(auth.currentUser.uid)
        this.editNameValue = account.name
        this.editEmailValue = auth.currentUser.email
      } catch(error) {
        alert(error)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    })
  },
  methods: {
    $_onSaveEditName: async function () {
      try {
        const result = await showConfirmPopup(
          '管理者名を更新しますか？',
          '実行',
          `キャンセル`
        )

        this.$store.dispatch('loadingMask/showLoadingMask')

        if (result.isConfirmed) {
          // お知らせ更新
          const news = await updateUser(auth.currentUser.uid, { name: this.editNameValue })
          if (news.status === 'success') {
            // showInfoPopupAlert('更新完了')
            // update account data
            // this.$store.dispatch(
            //   'set_account',
            //   await getAccount(auth.currentUser.uid)
            // )
            showInfoPopupAlert(`管理者名を更新しました。`)
            this.editName = false
            this.$router.push('/admin/my_page_edit_name_saved')
          } else {
            showInfoPopupAlert(`更新に失敗しました。`)
          }
        }
      } catch (error) {
        showInfoPopupAlert(`管理者名更新に失敗しました。<br />${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_onSaveEditEmail: async function () {
      try {
        const result = await showConfirmPopup(
          'メールアドレスを更新しますか？',
          '実行',
          `キャンセル`
        )

        this.$store.dispatch('loadingMask/showLoadingMask')

        if (result.isConfirmed) {
          // アカウントのメールアドレス更新
          await updateEmail(auth.currentUser, this.editEmailValue)
          // Users更新
          const result = await updateUser(auth.currentUser.uid, { email: this.editEmailValue })
          if (result.status === 'success') {
            // showInfoPopupAlert('更新完了')
            // update account data
            // this.$store.dispatch(
            //   'set_account',
            //   await getAccount(auth.currentUser.uid)
            // )
            showInfoPopupAlert(`メールアドレスを更新しました。`)
            this.editEmail = false
            this.$router.push('/admin/my_page_edit_email_saved')
          } else {
            showInfoPopupAlert(`更新に失敗しました。`)
          }
        }
      } catch (error) {
        showInfoPopupAlert(`メールアドレス更新に失敗しました。<br />${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_onSaveEditPassword: async function () {
      if (this.newPassword !== this.confirmPassword) {
        alert('新しいパスワードが一致しません')
        return
      }
      try {
        const result = await showConfirmPopup(
          'パスワードを更新しますか？',
          '実行',
          `キャンセル`
        )
        if (!result) return

        if (result.isConfirmed) {
          this.$store.dispatch('loadingMask/showLoadingMask')

          const user = auth.currentUser
          const credential = await EmailAuthProvider.credential(
            user.email, 
            this.currentPassword
          )
          reauthenticateWithCredential(user, credential).then(() => {
            updatePassword(user, this.newPassword).then(() => {
              showInfoPopupAlert('パスワードを更新しました。再度ログインして下さい。')
              signOut(auth).then(() => {
                console.log('log outed')
                this.$store.dispatch('loadingMask/hideLoadingMask')
                this.editPassword = false
                this.$router.push('/admin/login')
              })
            }).catch((error) => {
              showInfoPopupAlert('パスワード更新に失敗しました。' + error)
              this.$store.dispatch('loadingMask/hideLoadingMask')
            })
          }).catch((error) => {
            showInfoPopupAlert('パスワード更新時の再ログインに失敗しました。' + error)
            this.$store.dispatch('loadingMask/hideLoadingMask')
          })
        }
      } catch (error) {
        showInfoPopupAlert('パスワード更新に失敗しました。' + error)
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.my-page {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
}
.my-page-content {
  border: solid 1px lightgray;
  box-shadow: 0px 5px 7px lightgrey;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 32px;
  height: 100%;
  padding: 32px;
  overflow: auto;
  .my-page-item {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
.header-title {
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: solid 1px #707070;
}
.my-page-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px 0px 16px 0 !important
}
.my-page-item-container {
  display: flex;
  align-items: center;
  height: 40px;
  .sub-title {
    width: 13%;
    min-width: 150px;
  }
  .my-page-text {
    width: 30%;
    min-width: 150px;
    overflow-x: hidden;
  }
  .my-page-input {
    border: solid 1px lightgray;
    height: 32px;
    width: 300px;
  }
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 64px;
  padding: 32px;
}
/* overwrite */
::v-deep(.btn-outline-secondary) {
  font-size: 12px;
}
</style>
