import { 
  storage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
  deleteObject
} from '@/firebase/config'
// import SPACE_DATA_MODEL from '@/model/space'
// import EVENT_DATA_MODEL from '@/model/event'
import csvSync from 'csv-parse/lib/sync'

export const getExt = fileName => {
  const position = fileName.lastIndexOf('.')
  return position < 0 ? '' : fileName.slice(position + 1)
}

export const deleteStorageImage = async (type, placeId, name) => {
  try {
    const storageRef = ref(storage, `${type}_images/${placeId}/${name}`)
    await deleteObject(storageRef)
  } catch (error) {
    throw new Error(error)
  }
}

export const uploadSpaceImages = async (placeId, name, file, docType='default') => {
    try {
      const ext = getExt(file.name)
      const metadata = { contentType: file.type }
      const storageRef = ref(storage, `space_images/${placeId}/${name}`)
      const uploadTask = await uploadBytesResumable(storageRef, file, metadata)
      const downloadUrl = await getDownloadURL(uploadTask.ref)
      return { key: docType, downloadUrl: downloadUrl }
    } catch (error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      let message
      switch (error.code) {
        case 'storage/unauthorized':
          message = 'アクセス権限がありません'
          break
        case 'storage/canceled':
          message = 'アップロードがキャンセルになりました'
          break
        case 'storage/unknown':
          message = '不明なエラーが発生しました'
          console.log("Unknown error occurred, inspect error.serverResponse")
          break
        default:
          message = error.message || ''
      }
      alert(`書類画像のアップロードに失敗しました。${message}`)
    }
  }

  export const uploadThumbnailImages = async (type, placeId, name, file) => {
    try {
      const metadata = { contentType: file.type }
      const storageRef = ref(storage, `${type}_images/${placeId}/${name}`)
      const uploadTask = await uploadBytesResumable(storageRef, file, metadata)
      return await getDownloadURL(uploadTask.ref)
    } catch (error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      let message
      switch (error.code) {
        case 'storage/unauthorized':
          message = 'アクセス権限がありません'
          break
        case 'storage/canceled':
          message = 'アップロードがキャンセルになりました'
          break
        case 'storage/unknown':
          message = '不明なエラーが発生しました'
          console.log("Unknown error occurred, inspect error.serverResponse")
          break
        default:
          message = error.message || ''
      }
      alert(`画像のアップロードに失敗しました。${message}`)
    }
  }
export const parseToCsv = async (event, type) => {
  // POST to Firebase
  // call Firebase Functions
  const file = event.target.files
    ? event.target.files[0]
    : event.dataTransfer.files[0]

  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.readAsText(file)
    reader.onload = async (event) => {
      // parseing csv
      const rowData = event.target.result
      const arrayData = await csvSync(rowData)
      if (
        type === 'event'
          ? !isValidEventCsvHeader(arrayData)
          : !isValidSpaceCsvHeader(arrayData)
      ) {
        reject()
      }

      resolve(arrayData)
      // この段階で、実行する件数と大体の実行時間（デバッグ時の体感でOK）を表示指定
      // parse しないと件数が分からないからこのタイミングで確認アラートを出す
      // if (window.confirm(`${arrayData.length} 件 更新しますか？`)) {
      //   this.$_storeCsvData(arrayData)
      // }
    }
    reader.onerror = (error) => {
      window.alert('failed to csv loading with File API.')
      reject(error)
    }
  })
}
const isValidEventCsvHeader = data => {
  const headerCount = 16
  if (!data || !data.length) {
    alert('CSVデータが読み込めません')
    return false
  }
  if (data[0].length !== headerCount) {
    alert(`CSVデータの列数は${headerCount}としてください`)
    return false
  }
  if (data[0][0] !== 'ID') {
    alert('1列目のヘッダー名は「ID」としてください')
    return false
  }
  if (data[0][1] !== '公開設定') {
    alert('2列目のヘッダー名は「公開設定」としてください')
    return false
  }
  if (data[0][2] !== 'イベント名') {
    alert('3列目のヘッダー名は「イベント名」としてください')
    return false
  }
  if (data[0][3] !== 'サムネイル名') {
    alert('4列目のヘッダー名は「サムネイル名」としてください')
    return false
  }
  if (data[0][4] !== 'サムネイル引用元URL') {
    alert('5列目のヘッダー名は「サムネイル引用元URL」としてください')
    return false
  }
  if (data[0][5] !== 'スペース名') {
    alert('6列目は「スペース名」としてください')
    return false
  }
  if (data[0][6] !== '郵便番号') {
    alert('7列目のヘッダー名は「郵便番号」としてください')
    return false
  }
  if (data[0][7] !== '都道府県') {
    alert('8列目のヘッダー名は「都道府県」としてください')
    return false
  }
  if (data[0][8] !== '市区郡') {
    alert('9列目のヘッダー名は「市区郡」としてください')
    return false
  }
  if (data[0][9] !== 'それ以降') {
    alert('10列目のヘッダー名は「それ以降」としてください')
    return false
  }
  if (data[0][10] !== '開催日') {
    alert('11列目のヘッダー名は「開催日」としてください')
    return false
  }
  if (data[0][11] !== '募集期間(開始)') {
    alert('12列目のヘッダー名は「募集期間(開始)」としてください')
    return false
  }
  if (data[0][12] !== '募集期間(終了)') {
    alert('13列目のヘッダー名は「募集期間(終了)」としてください')
    return false
  }
  if (data[0][13] !== '掲載終了日') {
    alert('14列目のヘッダー名は「掲載終了日」としてください')
    return false
  }
  if (data[0][14] !== 'イベント情報掲載ページ') {
    alert('15列目のヘッダー名は「イベント情報掲載ページ」としてください')
    return false
  }
  if (data[0][15] !== 'お気に入り数') {
    alert('16列目のヘッダー名は「お気に入り数」としてください')
    return false
  }
  return true
}
const isValidSpaceCsvHeader = data => {
  const headerCount = 13
  if (!data || !data.length) {
    alert('CSVデータが読み込めません')
    return false
  }
  if (data[0].length !== headerCount) {
    alert(`CSVデータの列数は${headerCount}としてください`)
    return false
  }
  if (data[0][0] !== 'ID') {
    alert('1列目のヘッダー名は「ID」としてください')
    return false
  }
  if (data[0][1] !== '公開設定') {
    alert('2列目のヘッダー名は「公開設定」としてください')
    return false
  }
  if (data[0][2] !== 'スペース名') {
    alert('3列目のヘッダー名は「スペース名」としてください')
    return false
  }
  if (data[0][3] !== 'スペース詳細画面埋め込み') {
    alert('4列目のヘッダー名は「スペース詳細画面埋め込み」としてください')
    return false
  }
  if (data[0][4] !== 'twitter検索キーワード') {
    alert('6列目のヘッダー名は「twitter検索キーワード」としてください')
    return false
  }
  if (data[0][5] !== 'twitter検索キーワードURL') {
    alert('7列目のヘッダー名は「twitter検索キーワードURL」としてください')
    return false
  }
  if (data[0][6] !== '郵便番号') {
    alert('8列目のヘッダー名は「郵便番号」としてください')
    return false
  }
  if (data[0][7] !== '都道府県') {
    alert('9列目のヘッダー名は「都道府県」としてください')
    return false
  }
  if (data[0][8] !== '市区郡') {
    alert('10列目のヘッダー名は「市区郡」としてください')
    return false
  }
  if (data[0][9] !== 'それ以降') {
    alert('11列目のヘッダー名は「それ以降」としてください')
    return false
  }
  if (data[0][10] !== '募集ページ') {
    alert('12列目のヘッダー名は「募集ページ」としてください')
    return false
  }
  if (data[0][11] !== '備考') {
    alert('14列目のヘッダー名は「備考」としてください')
    return false
  }
  if (data[0][12] !== 'お気に入り数') {
    alert('13列目のヘッダー名は「お気に入り数」としてください')
    return false
  }
  return true
}