var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"review-handle"},[_c('div',{staticClass:"review-handle-title-wrapper"},[_c('button',{staticClass:"back-arrow-button",on:{"click":_vm.$_onClickBackButton}},[_c('IconLeftArrow')],1),_c('p',{staticClass:"review-handle-title"},[_vm._v(" "+_vm._s(_vm.title)),_c('span',{style:({ marginLeft: '8px' })},[_vm._v(_vm._s(_vm.titleAction))])])]),(_vm.saved)?_c('div',{staticClass:"review-saved"},[_c('p',{staticClass:"review-saved-title"},[_vm._v(_vm._s(_vm.savedTitle))]),_c('EllipseButton',{attrs:{"buttonText":'クチコミ管理にもどる',"width":'212px',"height":'36px'},on:{"button-click":_vm.$_onClickBackButton}})],1):_c('div',{staticClass:"review-handle-contents-wrapper"},[(_vm.checked)?_c('div',{staticClass:"review-handle-sub-title-container"},[_c('div',{staticClass:"space-detail-sub-title-container"},[_c('div',{staticClass:"sub-title"},[_vm._v("公開設定")]),_c('div',{staticClass:"review-handle-text",style:({ padding: '16px' })},[_vm._v(_vm._s(_vm.publicationText))])])]):_vm._e(),_c('div',{staticClass:"review-handle-sub-title-container"},[_c('div',{staticClass:"space-detail-sub-title-container"},[_c('div',{staticClass:"sub-title"},[_vm._v("スペース名")]),_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          columnGap: '16px',
          margin: 'auto 1rem',
          maxWidth: '73%'
        })},[_c('div',{staticClass:"review-handle-text",style:({
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }),attrs:{"title":_vm.spaceName}},[_vm._v(" "+_vm._s(_vm.spaceName)+" ")]),(_vm.checked)?_c('EllipseButton',{staticClass:"review-link-button",attrs:{"buttonText":'クチコミ一覧へ',"width":'116px'},on:{"button-click":() => _vm.$_onSpaceReviewListClick(_vm.spaceId)}}):_vm._e()],1)])]),_vm._l((_vm.forms),function(form){return _c('div',{key:form.id,staticClass:"review-handle-sub-title-container"},[_c('DetailInput',_vm._b({},'DetailInput',form,false))],1)}),_c('div',{staticClass:"review-handle-sub-title-container multi-line-row"},[_c('div',{staticClass:"space-detail-sub-title-container"},[_c('div',{staticClass:"sub-title"},[_vm._v("本文")]),_c('CommonTextarea',{attrs:{"prefix":'review-handle',"readonly":true,"userStyle":{
            flexGrow: '1',
            margin: '16px',
            fontSize: '1rem',
            border: 'none',
          },"remarks":_vm.reviewText}})],1)]),(_vm.checked)?_c('div',{staticClass:"action-button-wrapper"},[_c('EllipseButton',{attrs:{"buttonText":'もどる',"width":'312px',"height":'36px'},on:{"button-click":_vm.$_onCancelClick}}),_c('EllipseButton',{attrs:{"buttonText":_vm.publicationButtonText,"width":'312px',"height":'36px'},on:{"button-click":() => _vm.$_onPublicationClick(!_vm.publication)}})],1):_c('div',{staticClass:"action-button-wrapper"},[_c('EllipseButton',{attrs:{"buttonText":'もどる',"width":'212px',"height":'36px'},on:{"button-click":_vm.$_onCancelClick}}),_c('EllipseButton',{attrs:{"buttonText":'公開',"width":'212px',"height":'36px'},on:{"button-click":() => _vm.$_onPublicationClick(true)}}),_c('EllipseButton',{attrs:{"buttonText":'非公開',"width":'212px',"height":'36px'},on:{"button-click":() => _vm.$_onPublicationClick(false)}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }