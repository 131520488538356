var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-admin-my-page-edit-email-saved-container"},[_vm._m(0),_c('div',{style:({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px lightgray',
    borderRadius: '8px',
    padding: '32px'
  })},[_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{style:({
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    })},[_c('p',{style:({ color: '#707070', fontWeight: 'bold'})},[_vm._v(_vm._s(_vm.mainText))]),_c('button',{staticClass:"action-button",on:{"click":function($event){return _vm.$router.push('/admin/my_page')}}},[_vm._v(" マイページにもどる ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-admin-my-page-edit-email-saved-title-wrapper"},[_c('p',{staticClass:"app-admin-my-page-edit-email-saved-title"},[_vm._v("マイページ")])])
}]

export { render, staticRenderFns }