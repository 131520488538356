<template>
  <b-navbar class="app-admin-header" type="dark" fixed="top">
    <b-button
      class="mr-3 border"
      id="menubtn"
      variant="light"
      v-b-toggle.sidebar-backdrop
    >
      <b-icon icon="list" aria-hidden="true"></b-icon>
    </b-button>

    <b-navbar-brand id="brand" class="mr-auto">
        <!-- Safari でSVGのグラデーションが効かないためpngで対応 -->
        <img :src="require('@/assets/image/logo/logo.png')" />
    </b-navbar-brand>

    <b-navbar-nav class="ml-auto">
      <EllipseButton
        :buttonText="'ログアウト'"
        :width="'106px'"
        :height="'38px'"
        @button-click="$_logOut"
      />
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'

export default {
  name: 'AppAdminHeader',
  components: {
    EllipseButton,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted: function () {},
  methods: {
    $_logOut() {
      this.$router.push('/admin/logout')
    }
  },
}
</script>

<style lang="scss" scoped>
.app-admin-header {
  border-bottom: solid 1px lightgray;
  height: 56px;
}
</style>
