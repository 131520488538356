<template>
  <div class="app-admin-objection-card">
    <p class="objection-card-item pb-1">
      <span :style="{ display: 'inline-block', width: '70px' }">通報日時</span>：&nbsp;&nbsp;{{ createAt }}
    </p>
    <p class="objection-card-item mb-0">
      <span :style="{ display: 'inline-block', width: '70px' }">通報の内容</span>：&nbsp;&nbsp;{{ category }}
    </p>
    <div :style="{ display: 'flex' }">
      <p class="objection-card-item" :style="{ flexShrink: '0' }">
        <span :style="{ display: 'inline-block', width: '70px' }">通報理由</span>：&nbsp;&nbsp;
      </p>
      <CommonTextarea
        :prefix="'objection-card-reason'"
        :readonly="true"
        :remarks="objectionReason"
        :userStyle="{ padding: '0 0 8px 0', marginTop: '0', backgroundColor: '#F7F7F7' }"
      />
    </div> 

    <div class="handle-wrapper">
      <p class="objection-card-item">
        <span :style="{ display: 'inline-block', width: '50px' }">対応日時</span>：&nbsp;&nbsp;{{ updateAt }}
      </p>

      <!-- 更新日時がなければ公開設定もなしとする -->
      <p class="objection-card-item">
        <span :style="{ display: 'inline-block', width: '50px' }">公開設定</span>：&nbsp;&nbsp;{{
          updateAt
            ? reviewPublication ? '公開' : '非公開'
            : ''
        }}
      </p>
      <div :style="{ display: 'flex' }">
        <p class="objection-card-item" :style="{ flexShrink: '0', marginBottom: '0' }">
          <span :style="{ display: 'inline-block', width: '50px' }">備考</span>：&nbsp;&nbsp;
        </p>
        <CommonTextarea
          :prefix="'objection-card-remarks'"
          :readonly="true"
          :remarks="remarks"
          :userStyle="{ padding: '0', marginTop: '0', backgroundColor: '#F7F7F7' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'

export default {
  name: 'AppAdminObjectionCard',
  components: {
    CommonTextarea
  },
  props: {
    objectionReason: {
      type: String,
      default: ''
    },
    createAt: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    updateAt: {
      type: String,
      default: ''
    },
    reviewPublication: {
      type: Boolean,
      default: false
    },
    remarks: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss" scoped>
.app-admin-objection-card {
  padding-bottom: 8px;
  .objection-card-item {
    color: #707070;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .handle-wrapper {
    border: solid 1px #C6C6C6;
    border-radius: 4px;
    padding: 16px;
  }
}
</style>