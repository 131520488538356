<template>
  <div class="news-list-container">
    <!-- Title -->
    <div class="news-create-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="news-create-title">お知らせ管理 新規作成</p>
    </div>

    <div v-if="showEdit" class="news-create-contents">
      <!--
         edit-end で返ってきたのpublishDateはDateオブジェクトであり、
         NewDetailでNewsEditのpublishDate propsに文字列を渡す関係で
         propsのタイプがStringになっているためv-bind:publishDateで
         上書きする
      -->
      <NewsEdit
        v-bind="editNewsObject"
        v-bind:publishDate="editNewsObject && editNewsObject.publishDate
          ? editNewsObject.publishDate.toString()
          : ''"
        v-on:edit-cancel="$_onClickBackButton"
        v-on:edit-end="$_setEditNewsData"
      />
    </div>

    <div v-else-if="showConfirm" class="news-create-contents">
      <NewsConfirm
        v-bind="editNewsObject"
        v-on:edit-cancel="() => {
          showConfirm = false
          showEdit = true
          headerTitle = 'お知らせ管理 編集'
        }"
      />
    </div>
  </div>
</template>

<script>
import NewsEdit from '@/components/organisms/admin/news/AppAdminNewsEdit'
import NewsConfirm from '@/components/organisms/admin/news/AppAdminNewsConfirm'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'

export default {
  name: 'NewsCreate',
  components: {
    IconLeftArrow,
    NewsEdit,
    NewsConfirm
  },
  data() {
    return {
      editNewsObject: null,
      showEdit: true,
      showConfirm: false,
    }
  },
  methods: {
    $_setEditNewsData: function (data) {
      this.editNewsObject = data
      this.showEdit = false
      this.showConfirm = true
      this.headerTitle = 'お知らせ管理 内容確認'
    },
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/news')
    },
  },
}
</script>

<style lang="scss" scoped>
.news-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  .news-create-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    .back-arrow-button {
      border: 1px solid lightgray;
      border-radius: 4px;
      background-color: white;
      height: 32px;
      width: 32px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
      }
    }
    .news-create-title {
      color: #707070;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .news-create-contents {
    flex-grow: 1;
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
</style>
