<template>
  <div class="app-admin-space-detail">
    <!-- Title -->
    <div class="space-detail-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="space-detail-title">スペース詳細</p>
    </div>

    <b-tabs
      pills
      card
      class="space-detail-tab-container"
      v-model="tabIndex"
    >
      <b-tab title="スペース情報" active>
        <!-- スペース詳細 -->
        <div class="space-detail">
          <SpaceDataDetail :placeId="$route.query.placeId" />
        </div>
      </b-tab>
      <b-tab title="クチコミ">
        <!-- クチコミ一覧 -->
        <div class="space-detail">
          <SpaceReviewList :placeId="$route.query.placeId" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SpaceDataDetail from '@/components/organisms/admin/space/AppAdminSpaceDataDetail'
import SpaceReviewList from '@/components/organisms/admin/space/AppAdminSpaceReviewList'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'

export default {
  name: 'AppAdminSpaceDetail',
  components: {
    SpaceDataDetail,
    SpaceReviewList,
    IconLeftArrow,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  mounted() {
    this.$nextTick(function() {
      if (this.$route.query.tab) {
        this.tabIndex = Number(this.$route.query.tab)
      }
    })
  },
  methods: {
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/space_list')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-admin-space-detail {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  .space-detail-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    .back-arrow-button {
      border: 1px solid lightgray;
      border-radius: 4px;
      background-color: white;
      height: 32px;
      width: 32px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
      }
    }
    .space-detail-title {
      color: #707070;
      font-size: 24px;
      font-weight: bold;
      margin: 0px !important;
      line-height: 32px;
    }
  }
  .space-detail-tab-container {
    border: none;
    flex-grow: 1;
    overflow-y: auto;
    /* overwrite */
    ::v-deep(.card-header) {
      font-size: 12px;
      background-color: white;
      border: none;
      padding: 0 12px;
      .nav-item {
        margin: 0;
        width: 120px;
        text-align: center;
        .nav-link {
          color: #707070;
          border-radius: 0;
          border-top: solid 1px lightgrey;
          border-left: solid 1px lightgrey;
          &.active {
            color: white;
            background-color: #12B6D4; 
          }
        }
      }
      .card-header-pills {
        li:last-child {
          .nav-link {
            border-right: solid 1px lightgrey;
          }
        }
      }
    }
    /* overwrite */
    ::v-deep(.card-body) {
      padding: 0 2px 1.25rem 2px 
    }
    .space-detail {
      border: solid 1px lightgray;
      display: flex;
      flex-direction: column;
      height: 84%;
      overflow: auto;
    }
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 8px 16px;
  min-width: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
</style>
