<template>
  <div class="review-handle">
    <!-- Title -->
    <div class="review-handle-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="review-handle-title">
        {{ title }}<span :style="{ marginLeft: '8px' }">{{ titleAction }}</span>
      </p>
    </div>

    <!-- Saved -->
    <div v-if="saved" class="review-saved">
      <p class="review-saved-title">{{ savedTitle }}</p>
      <EllipseButton
        :buttonText="'クチコミ管理にもどる'"
        :width="'212px'"
        :height="'36px'"
        @button-click="$_onClickBackButton"
      />
    </div>

    <div v-else class="review-handle-contents-wrapper">
      <div v-if="checked" class="review-handle-sub-title-container">
        <div class="space-detail-sub-title-container">
          <div class="sub-title">公開設定</div>
          <div class="review-handle-text" :style="{ padding: '16px' }">{{ publicationText }}</div>
        </div>
      </div>

      <div class="review-handle-sub-title-container">
        <div class="space-detail-sub-title-container">
          <div class="sub-title">スペース名</div>
          <div :style="{
            display: 'flex',
            alignItems: 'center',
            columnGap: '16px',
            margin: 'auto 1rem',
            maxWidth: '73%'
          }">
            <div
              class="review-handle-text"
              :style="{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }"
              :title="spaceName"
            >
              {{ spaceName }}
            </div>
            <EllipseButton
              v-if="checked"
              class="review-link-button"
              :buttonText="'クチコミ一覧へ'"
              :width="'116px'"
              @button-click="() => $_onSpaceReviewListClick(spaceId)"
            />
          </div>
        </div>
      </div>

      <div
        class="review-handle-sub-title-container"
        v-for="form in forms"
        :key="form.id"
      >
        <DetailInput v-bind="form" />
      </div>

      <div class="review-handle-sub-title-container multi-line-row">
        <div class="space-detail-sub-title-container">
          <div class="sub-title">本文</div>
          <CommonTextarea
            :prefix="'review-handle'"
            :readonly="true"
            :userStyle="{
              flexGrow: '1',
              margin: '16px',
              fontSize: '1rem',
              border: 'none',
            }"
            :remarks="reviewText"
          />
        </div>
      </div>

      <div v-if="checked" class="action-button-wrapper">
        <EllipseButton
          :buttonText="'もどる'"
          :width="'312px'"
          :height="'36px'"
          @button-click="$_onCancelClick"
        />
        <EllipseButton
          :buttonText="publicationButtonText"
          :width="'312px'"
          :height="'36px'"
          @button-click="() => $_onPublicationClick(!publication)"
        />
      </div>

      <div v-else class="action-button-wrapper">
        <EllipseButton
          :buttonText="'もどる'"
          :width="'212px'"
          :height="'36px'"
          @button-click="$_onCancelClick"
        />
        <EllipseButton
          :buttonText="'公開'"
          :width="'212px'"
          :height="'36px'"
          @button-click="() => $_onPublicationClick(true)"
        />
        <EllipseButton
          :buttonText="'非公開'"
          :width="'212px'"
          :height="'36px'"
          @button-click="() => $_onPublicationClick(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import DetailInput from '@/components/molecules/admin/AppAdminDetailInput.vue'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { getReview, putReview } from '@/helper/firestore/review'
import { showConfirmPopup, showInfoPopupAlert, getChunkFromLocal, updateLocalChunk } from '@/helper/common'
import moment from 'moment'

export default {
  name: 'AppAdminReviewHandle',
  components: {
    CommonTextarea,
    EllipseButton,
    DetailInput,
    IconLeftArrow,
  },
  data() {
    return {
      saved: false,
      review: null,
      editPublication: false,
      forms: []
    }
  },
  computed: {
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    checked() {
      return this.review && this.review.checked
    },
    spaceName() {
      return this.review ? this.review.spaceName : ''
    },
    spaceId() {
      return this.review ? this.review.spaceId : ''
    },
    publication() {
      return this.review && this.review.publication
    },
    publicationText() {
      return this.publication ? '公開' : '非公開'
    },
    publicationButtonText() {
      return this.publication ? '非公開にする' : '公開にする'
    },
    createAt() {
      return this.review
        ? moment(this.review.createAt.seconds * 1000).format('YYYY年MM月DD日(ddd) HH:mm')
        : ''
    },
    postUserType() {
      return this.review ? this.review.postUserType : ''
    },
    reviewText() {
      return this.review ? this.review.review : ''
    },
    title() {
      return 'クチコミ投稿'
    },
    titleAction() {
      return this.checked
        ? this.saved ? '公開設定変更完了' : '公開設定変更'
        : this.saved ? '確認完了' : '確認'
    },
    savedTitle() {
      return `クチコミ投稿の公開設定を${this.editPublication ? '公開' : '非公開'}に変更しました。`
    }
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    try {
      this.review = await getReview(this.$route.query.reviewId)
      this.editPublication = this.review.publication
      this.$_setForms()
    } catch (error) {
      showInfoPopupAlert(`データ取得に失敗しました。<br />${error}`)
    } finally {
      await this.$nextTick()
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  methods: {
    $_setForms() {
      this.forms = [
        {
          id: 1,
          formType: 'text',
          value: this.createAt,
          title: '投稿日時',
        },
        {
          id: 2,
          formType: 'text',
          value: this.postUserType,
          title: '属性',
        },
      ]
    },
    $_onSpaceReviewListClick(spaceId) {
      this.$router.push(
        {
          path: '/admin/space_detail',
          query: { placeId: spaceId, tab: 1 }
        }
      )
    },
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function() {
      this.$router.push(
        {
          path: 'review_list',
          query: { tab: this.checked ? 2 : 1 }
        }
      )
    },
    $_onCancelClick: function() {
      this.$router.push(
        {
          path: 'review_list',
          query: { tab: this.checked ? 2 : 1 }
        }
      )
    },
    $_onPublicationClick: async function(flag) {
      this.editPublication = flag

      try {
        const comfirmText = this.checked
          ? `クチコミを${this.editPublication ? '公開に' : '非公開に'}変更しますか？`
          : `クチコミを${this.editPublication ? '公開' : '非公開に'}しますか？`
        const result = await showConfirmPopup(
          comfirmText,
          '実行',
          `キャンセル`
        )

        this.$store.dispatch('loadingMask/showLoadingMask')

        if (result.isConfirmed) {
          const chunk = getChunkFromLocal(this.review.spaceId)
          await putReview({
            reviewId: this.review.docId,
            spaceId: this.review.spaceId,
            chunk: chunk,
            review: {
              checked: true,
              publication: this.editPublication
            }
          })

          // ローカルのChunkを更新
          await updateLocalChunk(
            this.review.spaceId,
            { review: Math.max(0, chunk.review + (this.editPublication ? 1 : -1)) }
          )

          this.saved = true
        }
      } catch (error) {
        showInfoPopupAlert(`クチコミの公開状態更新に失敗しました。<br />${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.review-handle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 16px;
  height: 100%;
  padding: 16px;
  .review-handle-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .back-arrow-button {
      border: 1px solid lightgray;
      border-radius: 4px;
      background-color: white;
      height: 32px;
      width: 32px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
      }
    }
    .review-handle-title {
      color: #707070;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .review-handle-contents-wrapper {
    color: #707070;
    border: solid 1px lightgray;
    box-shadow: 0px 5px 7px lightgrey;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    height: 92%;
    padding: 32px 48px;
    overflow-y: scroll;
  }
  .review-saved {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .review-saved-title {
      color: #707070;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.review-handle-sub-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .sub-title {
    min-width: 220px;
  }
  .review-handle-text {
    flex-grow: 1;
    p {
      margin-bottom: 0.25rem;
    }
  }
  #textarea-auto-height {
    border: none;
    resize: none;
    background-color: white;
    color: #707070;
    width: 100%;
    /* overwrite */
    .form-control {
      padding: 0.375rem 0;
    }
  }
  .review-link-button {
    /* overwrite */
    ::v-deep(.btn-outline-secondary) {
      color: #12B6D4;
      border-color: #12B6D4;
      font-size: 12px;
      padding: 4px 0;
      &hover {
        background-color: yellow
      }
    }
  }
}
.multi-line-row {
  align-items: flex-start;
}
.space-detail-sub-title-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
  border: solid 1px #D6D6D6;
  border-radius: 4px;
  width: 100%;
  .item-label {
    width: 96px;
    font-size: 8px;
    flex-shrink: 0;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: solid 1px #D6D6D6;
    text-align: center;
    .optional-label {
      color: #707070;
      font-weight: bold;
      margin-bottom: 0;
    }
    .limitation-label {
      color: #707070;
      margin-bottom: 0;
    }
  }
  .textarea-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px;
    color: #707070;
    font-size: 14px;
  }
  .sub-title {
    width: 13%;
    min-width: 260px;
    background-color: #D6D6D6;
    border-right: solid 1px #D6D6D6;
    color: #707070;
    font-size: 14px;
    font-weight: bold;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .space-detail-textarea {
    flex-grow: 1;
    margin: 16px;
    color: #707070;
    font-size: 14px;
    border: none;
  }
  :disabled {
    background-color: white;
  }
}
input[type=checkbox]:checked + .checkbox01::after {
  opacity: 1;
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-top: 32px;
  width: 100%;
}
</style>
