var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-management-list-container"},[_c('div',{staticClass:"space-detail-title-wrapper"},[_c('button',{staticClass:"back-arrow-button",on:{"click":_vm.$_onClickBackButton}},[_c('IconLeftArrow')],1),_c('p',{staticClass:"space-detail-title"},[_vm._v("イベント一覧 CSV一括ダウンロード")])]),_c('div',{staticClass:"space-management-table-container"},[(_vm.complete)?_c('div',{style:({ height: '100%' })},[_c('div',{style:({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        })},[_c('div',{staticClass:"sub-title",style:({ color: '#707070', fontSize: '20px', fontWeight: 'bold' })},[_vm._v(" ダウンロードが完了しました ")]),_c('div',{staticClass:"plane-action-button-wrapper"},[_c('button',{staticClass:"action-button",on:{"click":_vm.$_onClickGoBack}},[_vm._v(" イベント情報にもどる ")])])])]):_c('div',{style:({ height: '100%' })},[_c('div',{style:({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        })},[_c('div',{staticClass:"sub-title",style:({ color: '#707070', fontSize: '20px', fontWeight: 'bold' })},[_vm._v(" CSVを一括ダウンロードをする ")]),_c('div',{staticClass:"action-button-wrapper"},[_c('button',{staticClass:"action-button",on:{"click":_vm.$_onDownload}},[_vm._v(" CSVをダウンロードする ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }