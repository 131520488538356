<template>
  <div class="app-admin-square-button">
    <b-button
      class="square-button"
      variant="outline-dark"
      size="sm"
      @click="$emit('button-click')"
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AppAdminSquareButton',
  props: {
    buttonText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.app-admin-square-button {
  display: inline;
  .square-button {
    border: solid 1px #B1ACAC;
    height: 42px;
    width: 145px;
  }
}
</style>