<template>
  <div class="news-detail-container">
    <!-- Title -->
    <div class="news-detail-title-wrapper">
      <button
        class="back-arrow-button"
        v-on:click="$_onClickBackButton"
      >
        <IconLeftArrow />
      </button>
      <p class="news-detail-title">{{ headerTitle }}</p>
    </div>

    <!-- edit -->
    <div v-if="showEdit" :style="{ height: '100%' }">
      <NewsEdit
        v-bind="newsObject"
        v-bind:deleteAction="true"
        v-on:edit-cancel="() => {
          showEdit = false
          headerTitle = 'お知らせ詳細'
        }"
        v-on:edit-end="$_setEditNewsData"
        v-on:delete-news="$_deleteNewsData"
      />
    </div>

    <!-- confirm -->
    <div v-else-if="showConfirm" :style="{ height: '100%' }">
      <NewsConfirm
        v-bind="editNewsObject"
        v-on:edit-cancel="() => {
          showConfirm = false
          showEdit = true
          headerTitle = 'お知らせ管理 編集'
        }"
      />
    </div> 

    <!-- detail -->
    <div v-else class="news">
      <EllipseButton
        class="edit-button"
        :buttonText="'編集'"
        :width="'100px'"
        @button-click="$_onClickEdit"
      />

      <div class="news-sub-title-container">
        <div class="sub-title">カテゴリ</div>
        <div class="news-text">{{ category }}</div>
      </div>

      <!-- <div class="news-sub-title-container">
        <div class="sub-title">配信対象</div>
        <div class="news-text">{{ target }}</div>
      </div> -->

      <div class="news-sub-title-container">
        <div class="sub-title">公開日時</div>
        <div class="news-text">{{ publishDate }}</div>
      </div>

      <div class="news-sub-title-container">
        <div class="sub-title">タイトル</div>
        <div class="news-text">{{ title }}</div>
      </div>

      <div class="news-sub-title-container multi-line-row">
        <div class="sub-title">本文</div>
        <div class="news-text">
          <CommonTextarea
            v-model="text"
            :prefix="'news-detail'"
            :readonly="true"
            :userStyle="{ fontSize: '14px' }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import NewsEdit from '@/components/organisms/admin/news/AppAdminNewsEdit'
import NewsConfirm from '@/components/organisms/admin/news/AppAdminNewsConfirm'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import { deleteNews } from '@/helper/firestore/news'
import { showInfoPopupAlert } from '@/helper/common'

export default {
  name: 'NewsDetail',
  components: {
    IconLeftArrow,
    NewsEdit,
    NewsConfirm,
    EllipseButton,
    CommonTextarea
  },
  data() {
    return {
      showEdit: false,
      showConfirm: false,
      headerTitle:'お知らせ詳細',
      editNewsObject: null,
    }
  },
  computed: {
    adminNews: function () {
      return this.$store.getters['news/adminNews']
       ? this.$store.getters['news/adminNews']
       : []
    },
    targetNews: function () {
      return this.adminNews.find(n => n.docId === this.$route.query.id)
    },
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    category: function () {
      return this.targetNews && this.targetNews.category
    },
    target: function () {
      const rs = []
      if (this.targetNews.isTargetUser) {
        rs.push('本ユーザー')
      }
      if (this.targetNews.isTargetTmpUser) {
        rs.push('仮ユーザー')
      }
      if (this.targetNews.isTargetSpace) {
        rs.push('スペースオーナー')
      }
      return rs.join(', ')
    },
    publishDate: function () {
      return this.targetNews && this.targetNews.publishDate
    },
    title: function () {
      return this.targetNews && this.targetNews.title
    },
    text: function () {
      return this.targetNews && this.targetNews.text.replace(/\\n/g, '\n')
    },
    newsObject: function () {
      return {
        id: this.targetNews.docId,
        publishDate: this.publishDate,
        title: this.title,
        text: this.text,
        category: this.category,
        isTargetTmpUser: this.targetNews.isTargetTmpUser,
        isTargetUser: this.targetNews.isTargetUser,
        isTargetSpace: this.targetNews.isTargetSpace
      }
    }
  },
  methods: {
    $_setEditNewsData: function (data) {
      this.editNewsObject = data
      this.showEdit = false
      this.showConfirm = true
      this.headerTitle = 'お知らせ管理 内容確認'
    },
    $_deleteNewsData: async function (newsId) {
      const rs = await deleteNews(newsId)
      if (rs.status === 'success') {
        showInfoPopupAlert('削除しました。')
        this.$router.push('/admin/news')
      }
    },
    /**
     * 戻るボタン押下処理
     */
    $_onClickBackButton: function () {
      this.$router.push('/admin/news')
    },
    $_onClickEdit: function () {
      this.showEdit = true
      this.headerTitle = 'お知らせ管理 編集'
    },
  },
}
</script>

<style lang="scss" scoped>
/* overwrite */
.form-control {
  background-color: #fff;
}
.news-detail-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}
.news-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}
.news-detail-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}
.edit_button {
  margin-left: auto;
  border-radius: 24px;
  width: 100px;
  padding: 4px;
  font-size: 12px; 
}
.news {
  border: solid 1px lightgray;
  box-shadow: 0px 5px 7px lightgrey;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 16px;
  height: 100%;
  padding: 16px 32px;
  color: #707070;
  font-size: 14px;
  .edit-button {
    margin-left: auto
  }
}
.news-sub-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .sub-title {
    min-width: 220px;
  }
  .news-text {
    flex-grow: 1;
    p {
      margin-bottom: 0.25rem;
    }
  }
}
.multi-line-row {
  align-items: flex-start;
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 4px 32px;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
/* ラジオボタン01 */
input[type=radio] {
  display: none;
}
.radio01 {
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  width: auto;
}
.radio01::before {
  background: #fff;
  border: 1px solid #231815;
  border-radius: 50%;
  content: '';
  display: block;
  height: 16px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.radio01::after {
  background: #12B6D4;
  border-radius: 50%;
  content: '';
  display: block;
  height: 10px;
  left: 8px;
  margin-top: -4px;
  opacity: 0;
  position: absolute;
  top: 48%;
  width: 10px;
}
input[type=radio]:checked + .radio01::after {
  opacity: 1;
}
/* チェックボックス01 */
input[type=checkbox] {
  display: none;
}
.checkbox01 {
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  width: auto;
}
.checkbox01::before {
  background: #fff;
  border: 1px solid #231815;
  content: '';
  display: block;
  height: 16px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
  border-radius: 8px;
}
.checkbox01::after {
  background: #12B6D4;
  border-radius: 50%;
  content: '';
  display: block;
  height: 10px;
  left: 8px;
  margin-top: -4px;
  opacity: 0;
  position: absolute;
  top: 46%;
  width: 10px;
}
input[type=checkbox]:checked + .checkbox01::after {
  opacity: 1;
}
/* overwrite */
::v-deep(.btn-outline-secondary) {
  font-size: 12px;
}
</style>
