import { serverTimestamp } from '@/firebase/config'

export const NEWS_DATA_MODEL = {
  category: '',
  // createAt: serverTimestamp(),
  createAt: null,
  disabled: true,
  isTargetAdmin: false,
  isTargetSpace: false,
  isTargetTmpUser: false,
  isTargetUser: false,
  publishDate: null,
  text: '',
  title: ''
}