import common from './common'
import moment from 'moment'
import { NEWS_DATA_MODEL } from '@/model/news'
import { NEWS_DOCS_NAME } from '@/constants/firestore'
import { isEmptyObject } from '@/helper/util'

/**
 * すべてのお知らせデータ取得
 * @return {Array.<Object>} お知ら配列 
 */
export const getAllNews = async () => {
  try {
    return await common.get(NEWS_DOCS_NAME)
  } catch (e) {
    throw new Error(`getAllNews => ${e}`)
  }
}

/**
 * 有効かつ公開お知らせデータ取得
 * @return {Array.<Object>} お知ら配列 
 */
export const getOpenNews = async () => {
  try {
    // 有効ドキュメントを取得
    const result = await common.getEnabled(NEWS_DOCS_NAME)

    // 有効な公開予定日フィルタ
    return result.filter(r => {
      moment.locale('ja')
      const today = moment().toDate()
      const endDate = moment(
        r.publishDate.replace(/”/g, '').replace(/"/g, '')
      ).toDate()
      return today > endDate
    })
  } catch (e) {
    throw new Error(`getOpenNews => ${e}`)
  }
}

/**
 * お知らせデータ登録
 * @param {Object} value 
 * @return {Object} 成功時オブジェクト
 * { statue: 'success', docId: 登録したnewsドキュメントID }
 */
export const postNews = async value => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')

    return common.post(
      NEWS_DOCS_NAME,
      {
        ...NEWS_DATA_MODEL,
        disabled: false,
        ...value
      }
    )
  } catch (e) {
    throw new Error(`postNews => ${e}`)
  }
}

/**
 * お知らせデータ更新
 * @param {Object} value 
 * @return {Object} 成功時オブジェクト
 * { statue: 'success' }
 */
export const putNews = async value => {
  try {
    if (!value.id) throw new Error('News document ID is not provided.')  
    if (isEmptyObject(value)) throw new Error('Value is not provided.')

    return common.put(
      NEWS_DOCS_NAME,
      value.id,
      {
        ...NEWS_DATA_MODEL,
        disabled: false,
        ...value
      }
    )
  } catch (e) {
    throw new Error(`putNews => ${e}`)
  }
}

/**
 * 対象お知らせデータ削除
 * @param {String} newsId
 * @return {Object|Array.<Object>} 更新結果（バッチ処理の場合はそれぞれの処理結果配列）
 */
export const deleteNews = async (newsId) => {
  try {
    if (!newsId) throw new Error('News document ID is not provided.')  
    return await common.delete(NEWS_DOCS_NAME, newsId)
  } catch (e) {
    throw new Error(`deleteNews => ${e}`)
  } 
}