<template>
  <div class="space-data-detail" ref="review-list">
    <!-- Detail, Edit -->
    <div :style="{ hegith: '100%' }">
      <!-- スペース情報 -->
      <div class="pb-5">
        <!-- ヘッダー -->
        <div class="topic-title">
          クチコミ件数（公開のみ）： {{ openReviewItems.length }} 件
        </div>

        <b-tabs
          pills
          card
          class="review-list-tab-container"
          v-model="tabIndex"
        >
          <b-tab title="公開" active>
            <b-table
              id="open-review-list-table"
              class="review-list-table"
              :fields="reviewFields"
              :items="openReviewItems"
              :per-page="perPage"
              :current-page="openReviewsCurrentPage"
            >
              <!-- Review row -->
              <template #cell(reviewData)="row">
                <div class="objection-item-wrapper">
                  <p
                    class="objection-handle-post-user-type"
                    :class="$_getUserTypeClass(row.item.reviewData.postUserType)"
                  >
                    {{ row.item.reviewData.postUserType }}
                  </p>
                  <CommonTextarea
                    readonly
                    :prefix="`open-review-${row.item.reviewData.reviewId}`"
                    :remarks="row.item.reviewData.review" 
                    :shown="showOpenReviewList"
                  />
                  <div class="review-data-cell-footer">
                    <p class="objection-handle-post-at">
                      {{ $_formatDate(row.item.reviewData.createAt) }}
                    </p>
                    <p class="objection-link" @click="row.toggleDetails">
                      通報({{ row.item.reviewData.objections.length }})
                    </p>
                  </div>
                  <hr class="divided-line" />
                </div>
              </template>
              <!-- Objection row -->
              <template #row-details="row">
                <div class="row-detail-header">
                  <p class="header-text">通報({{ row.item.reviewData.objections.length }})</p>
                  <EllipseButton
                    :buttonText="'クチコミ投稿 公開設定変更'"
                    :width="'180px'"
                    :height="'28px'"
                    @button-click="() => $_onToReviewLinkClick(row.item.reviewData.reviewId)"
                  />    
                </div>
                <div class="objection-list-table-wrapper">
                  <b-table
                    class="objection-list-table"
                    :fields="objectionFields"
                    :items="row.item.reviewData.objections"
                    :current-page="openReviewsCurrentPage"
                  >
                    <template #cell(objectionData)="row">
                      <ObjectionCard
                        :createAt="$_formatDate(row.item.createAt)"
                        :category="row.item.category"
                        :updateAt="$_formatDate(row.item.updateAt)"
                        :reviewPublication="row.item.reviewPublication"
                        :remarks="row.item.remarks"
                        :objectionReason="row.item.reason"
                      />
                    </template>
                  </b-table>
                </div>
              </template>
            </b-table>

            <b-pagination
              class="custom-pagenation"
              align="center"
              aria-controls="open-review-list-table"
              v-model="openReviewsCurrentPage"
              :total-rows="openReviewsRowCount"
              :per-page="perPage"
            ></b-pagination>
          </b-tab>
          <b-tab title="非公開">
            <b-table
              id="closed-review-list-table"
              class="review-list-table"
              :fields="reviewFields"
              :items="closedReviewItems"
              :per-page="perPage"
              :current-page="closedReviewsCurrentPage"
            >
              <!-- Review row -->
              <template #cell(reviewData)="row">
                <div class="objection-item-wrapper">
                  <p
                    class="objection-handle-post-user-type"
                    :class="$_getUserTypeClass(row.item.reviewData.postUserType)"
                  >
                    {{ row.item.reviewData.postUserType }}
                  </p>
                  <CommonTextarea
                    readonly
                    :prefix="`closed-review-${row.item.reviewData.reviewId}`"
                    :remarks="row.item.reviewData.review"
                    :shown="showClosedReviewList"
                  />
                  <div class="review-data-cell-footer">
                    <p class="objection-handle-post-at">
                      {{ $_formatDate(row.item.reviewData.createAt) }}
                    </p>
                    <p class="objection-link" @click="row.toggleDetails">
                      通報({{ row.item.reviewData.objections.length }})
                    </p>
                  </div>
                  <hr class="divided-line" />
                </div>
              </template>
              <!-- Objection row -->
              <template #row-details="row">
                <div class="row-detail-header">
                  <p class="header-text">通報({{ row.item.reviewData.objections.length }})</p>
                  <EllipseButton
                    :buttonText="'クチコミ投稿 公開設定変更'"
                    :width="'180px'"
                    :height="'28px'"
                    @button-click="() => $_onToReviewLinkClick(row.item.reviewData.reviewId)"
                  />  
                </div>
                <div class="objection-list-table-wrapper">
                  <b-table
                    class="objection-list-table"
                    :fields="objectionFields"
                    :items="row.item.reviewData.objections"
                    :current-page="closedReviewsCurrentPage"
                  >
                    <template #cell(objectionData)="row">
                      <ObjectionCard
                        :createAt="$_formatDate(row.item.createAt)"
                        :category="row.item.category"
                        :updateAt="$_formatDate(row.item.updateAt)"
                        :reviewPublication="row.item.reviewPublication"
                        :remarks="row.item.remarks"
                        :objectionReason="row.item.reason"
                      />
                    </template>
                  </b-table>
                </div>
              </template>
            </b-table>

            <b-pagination
              class="custom-pagenation"
              align="center"
              aria-controls="closed-review-list-table"
              v-model="closedReviewsCurrentPage"
              :total-rows="closedReviewsRowCount"
              :per-page="perPage"
            ></b-pagination>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import ObjectionCard from '@/components/molecules/admin/AppAdminObjectionCard'
import { getSpaceReviews } from '@/helper/firestore/review'
import { sortByDate } from '@/helper/common'
import moment from 'moment'

export default {
  name: 'AppAdminSpaceReviewList',
  components: {
    CommonTextarea,
    EllipseButton,
    ObjectionCard
  },
  props: {
    placeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      reviewFields: [
        {
          class: "button-column",
          thClass: 'detail-button-column-header',
          tdClass: 'detail-button-column-cell',
          key: 'reviewData',
        },
      ],
      objectionFields: [

        {
          class: "button-column",
          thClass: 'detail-button-column-header',
          tdClass: 'detail-button-column-cell',
          key: 'objectionData',
        },
      ],
      openReviewItems: [],
      openReviewsCurrentPage: 1,
      closedReviewItems: [],
      closedReviewsCurrentPage: 1,
      perPage: 10,
      tabIndex: 0,
      reviews: [],
      showOpenReviewList: false,
      showClosedReviewList: false,
    }
  },
  computed: {
    openReviewsRowCount: function () {
      return this.openReviewItems.length
    },
    closedReviewsRowCount: function () {
      return this.closedReviewItems.length
    }
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    try {
      // placeIdがあれば編集、なければ新規作成
      if (this.placeId) {
        this.reviews = await getSpaceReviews(this.placeId)
        this.openReviewItems = this.reviews
          .filter(r => r.publication)
          .sort(this.$_sortByDate)
          .map(r => this.$_convertToReviewTable(r))
        this.closedReviewItems = this.reviews
          .filter(r => !r.publication)
          .sort(this.$_sortByDate)
          .map(r => this.$_convertToReviewTable(r))
      }
    } catch (error) {
      alert(error)
    } finally {
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }

    // 本コンポーネントのサイズを監視することで、表示状態（タブの切替）になった
    // タイミングを監視 ⇨ タブのインデックスで表示中のタブを判定（公開 or 非公開）
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const rect = entry.contentRect
        // サイズがある = 表示された
        if(rect.width > 0 && rect.height > 0) {
          this.showOpenReviewList = this.tabIndex === 0
          this.showClosedReviewList = this.tabIndex === 1
        }
      })
    })
    observer.observe(this.$refs['review-list'])

  },
  methods: {
    $_convertToReviewTable(review) {
      return {
        placeId: review.spaceId,
        reviewData: review,
      }
    },
    $_formatDate(timeStamp) {
      return timeStamp
        ? moment(timeStamp.seconds * 1000).format('YYYY.MM.DD HH:mm')
        : ''
    },
    $_getUserTypeClass(postUserType) {
      switch(postUserType) {
        case '移動販売事業者':
          return 'mobile-vendor-user-type'
        case '利用客':
          return 'customer-user-type'
        case 'その他':
          return 'other-user-type'
        default:
          return ''
      }
    },
    $_onToReviewLinkClick(reviewId) {
      this.$router.push(
        {
           path: 'review_handle',
           query: { reviewId: reviewId }
         }
      )
    },
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },
  },
}
</script>

<style lang="scss" scoped>
.space-data-detail {
  height: 100%;
  padding: 1rem 2rem;
  .topic-title {
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
  }
  .review-list-tab-container {
    border: none;
    flex-grow: 1;
    /* overwrite */
    ::v-deep(.card-header) {
      font-size: 12px;
      background-color: white;
      border: none;
      padding: 0 12px;
      .nav-item {
        margin: 0;
        width: 120px;
        text-align: center;
        .nav-link {
          color: #707070;
          border-radius: 0;
          border-top: solid 1px #B1ACAC;
          border-bottom: solid 1px #B1ACAC;
          border-left: solid 1px #B1ACAC;
          &.active {
            color: white;
            background-color: #12B6D4; 
          }
        }
      }
      .card-header-pills {
        li:last-child {
          .nav-link {
            border-right: solid 1px #B1ACAC;
          }
        }
      }
    }
    ::v-deep(.review-list-table) {
      thead {
        display: none;
      }
      th {
        background-color: #f9f9f9;
        font-weight: 14px;
        color: #707070;
      }
      td {
        border-top: none;
      }
      .objection-item-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .objection-handle-sub-title {
          color: #707070;
          font-size: 16px;
          font-weight: bold;
          margin: 24px 0 18px 0;
        }
        .objection-contents {
          color: #707070;
          font-size: 12px;
          width: 100%;
          resize: none;
          border: none;
          margin-top: 4px;
        }
        .objection-handle-space-name {
          color: #707070;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .objection-handle-post-user-type {
          color: #707070;
          font-size: 12px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          &:before {
            border-radius: 50%;
            content: '';
            display: block;
            height: 10px;
            width: 10px;
            margin-right: 4px;
          }
        }
        .mobile-vendor-user-type {
          &:before {
            background: #12B6D4;
          }
        }
        .customer-user-type {
          &:before {
            background: #FFDA0B;
          }
        }
        .other-user-type {
          &:before {
            background: #95BB00;
          }
        }
        .review-data-cell-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .objection-handle-post-at {
            color: #707070;
            font-size: 10px;
            margin: 10px 0;
          }
          .objection-link {
            color: #707070;
            text-decoration: underline;
            font-size: 10px;
            margin: 10px 0;
          }
        }
        .objection-handle-contents-post-at {
          color: #707070;
          font-size: 12px;
          margin-bottom: 4px;
        }
        .objection-handle-type {
          color: #707070;
          font-size: 12px;
          margin-bottom: 12px;
        }
        .objection-remarks-title {
          color: #707070;
          font-size: 12px;
          margin-bottom: 0;
        }
        .objection-remarks {
          color: #707070;
          font-size: 12px;
          width: 100%;
          resize: none;
          border: solid 0.5px #707070;
          margin: 8px 0;
        }
        .divided-line {
          margin: 0;
          width: 100%;
        }
      }
      .row-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px 0;
        .header-text {
          color: #707070;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
    }
    ::v-deep(.objection-list-table-wrapper) {
      position: relative;
      max-height: 500px;
      overflow: auto;
      display: block;
      .objection-list-table {
        thead {
          display: none;
        }
        th {
          background-color: #f9f9f9;
          font-weight: 14px;
          color: #707070;
        }
        tr {
          background-color: #F7F7F7;
        }
        td {
          border-top: none;
          padding-bottom: 1rem;
        }
        .objection-item {
          color: #707070;
          font-size: 12px;
          margin-bottom: 4px;
        }
      }
    }
    /* overwrite */
    ::v-deep(.card-body) {
      padding: 1.25rem 3px;
    }
    ::v-deep(.custom-pagenation) {
      margin-right: 16px;
      /* overwrite */
      .page-item {
        margin: 0;
      }
      /* overwrite */
      .page-link {
        color: #707070
      }
      .active {
        .page-link {
          font-weight: bold;
          color: #ffffff;
          background-color: #12B6D4;
          border: #12B6D4;
          height: 100%;
        }
      }
    }
  }
  /* overwrite */
  ::v-deep(.btn-outline-secondary) {
    font-size: 12px;
  }
}
</style>
