<template>
  <div :style="{ height: '100%', padding: '1rem 2rem' }">
    <!-- Saved -->
    <div
      v-if="showSaved"
      :style="{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }"
    >
      <div class="sub-title" :style="{ color: '#707070', fontSize: '20px', fontWeight: 'bold' }">
        イベントの登録が完了しました
      </div>
      <div class="action-button-wrapper">
        <EllipseButton
          :buttonText="'イベント情報にもどる'"
          :width="'212px'"
          :height="'36px'"
          @button-click="$_onClickGoBack"
        />
      </div>
    </div>

    <!-- Detail, Edit -->
    <div v-else :style="{ hegith: '100%' }">
      <!-- イベント情報 -->
      <div v-if="showEventDetial" class="pb-5">
        <!-- ヘッダー -->
        <div class="topic-title">
          イベント情報
          <EllipseButton
            v-if="!showEventEdit && !showConfirm"
            class="edit-button"
            :buttonText="'編集'"
            :width="'100px'"
            :height="'28px'"
            @button-click="$_onClickEventEdit"
          />          
        </div>
        <!-- 公開・非公開 -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">公開設定</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
          </div>
          <div v-if="showEventEdit" :style="{
            width: '360px',
            display: 'flex',
            alignItems: 'center',
            margin: '0 16px'
          }">
            <input type="radio" id="01-A" name="radio01" v-model="editPublicStatus" value="公開">
            <label for="01-A" class="radio01">公開</label>
            <input type="radio" id="01-B" name="radio01" v-model="editPublicStatus" value="非公開">
            <label for="01-B" class="radio01">非公開</label>
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ editPublicStatus }}</div>
          <div v-else-if="showSaved">{{ editPublicStatus }}</div>
          <div v-else class="event-detail-text">{{ publicStatus }}</div>
        </div>
        <!-- 投稿者(編集不可) -->
        <div
          v-if="!showEventEdit && !showConfirm && !showSaved"
          class="event-detail-sub-title-container"
        >
          <div class="sub-title">投稿者</div>
          <div class="event-detail-text">{{ addedBy }}</div>
        </div>
        <!-- イベント名 -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">イベント名</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
            <p class="limitation-label">50字まで</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <input
              type="text"
              maxlength="50"
              v-model="editEventName"
              :style="{
                width: '100%',
                border: 'none'
              }"
            />
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ editEventName }}</div>
          <div v-else-if="showSaved">{{ editEventName }}</div>
          <div v-else class="event-detail-text">{{ eventName }}</div>
        </div>
        <!-- サムネイル -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">サムネイル</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="optional-label">任意</p>
            <p class="limitation-label">未登録の場合<br />デフォルト画像<br />表示</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <UploadImageAdmin
                v-bind:src="eventImage"
                v-bind:imageName="editEventImageName"
                v-bind:underButtonText="'PNG形式（1枚まで）'"
                v-on:changed-image="$_onChangeImage"
                v-on:remove-image="$_onRemoveImage"
              />
            </div>
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">
            <div class="event-detail-images-container" :style="{ justifyContent: 'flex-start', columnGap: '16px' }">
              <viewer :images="[image]">
                <img
                  class="event-image"
                  :src="editEventImage.base64"
                  width="200px"
                  height="133px"
                />
              </viewer>
              <div class="event-detail-text" :style="{ margin: '0' }">{{ editEventImageName }}</div>
            </div>
          </div>
          <div v-else-if="showSaved" class="event-detail-text">
            <div class="event-detail-images-container" :style="{ columnGap: '16px' }">
              <viewer :images="[image]">
                <img
                  class="event-image"
                  :src="editEventImage.base64"
                  width="200px"
                  height="133px"
                />
              </viewer>
              <div class="event-detail-text" :style="{ margin: '0' }">{{ editEventImageName }}</div>
            </div>
          </div>
          <div v-else class="event-detail-text">
            <div
              class="event-detail-images-container"
              :style="{ justifyContent: 'flex-start', columnGap: '16px' }"
            >
              <viewer :images="[image]">
                <img
                  class="event-image"
                  :src="image"
                  width="200px"
                  height="133px"
                />
              </viewer>
              <div class="event-detail-text" :style="{ margin: '0' }">{{ eventImageName }}</div>
            </div>
          </div>
        </div>
        <!-- サムネイル引用元URL -->
        <div v-if="hasEventImage" class="event-detail-sub-title-container">
          <div class="sub-title">サムネイル引用元URL</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="optional-label">任意</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <input
              type="text"
              v-model="editCopyRightUrl"
              :style="{
                width: '100%',
                border: 'none'
              }"
            />
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ editCopyRightUrl }}</div>
          <div v-else-if="showSaved">{{ editCopyRightUrl }}</div>
          <div v-else class="event-detail-text">{{ copyRightUrl }}</div>
        </div>
        <!-- スペース名 -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">スペース名</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
            <p class="limitation-label">50字まで</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <input
              type="text"
              maxlength="50"
              v-model="editSpaceName"
              :style="{
                width: '100%',
                border: 'none'
              }"
            />
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ editSpaceName }}</div>
          <div v-else-if="showSaved">{{ editSpaceName }}</div>
          <div v-else class="event-detail-text">{{ spaceName }}</div>
        </div>
        <!-- 開催日 -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">開催日</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
          </div>
          <div v-if="showEventEdit" :style="{
            width: '340px',
            height: '100%',
            margin: '16px 0'
          }">
            <AppCalendar
              :initialMonth="calendarInitialMonth"
              :reservedDates="editHeldDates"
              @change-date-selection="$_onChangeDateSelection"
            />
          </div>
          <div v-else-if="showConfirm" class="event-detail-text" :style="{ marginTop: '8px', marginBottom: '8px' }">
            <p
              v-for="(date, index) in displayHeldDates"
              :key="`held-date-${index}`"
              :style="{ marginBottom: '0' }"
            >
              {{ date }}
            </p>
          </div>
          <div v-else-if="showSaved">
            <p
              v-for="(date, index) in displayHeldDates"
              :key="`held-date-${index}`"
              :style="{ marginBottom: '0' }"
            >
              {{ date }}
            </p>
          </div>
          <div v-else class="event-detail-text" :style="{ marginTop: '8px', marginBottom: '8px' }">
            <p
              v-for="(date, index) in heldDates"
              :key="`held-date-${index}`"
              :style="{ marginBottom: '0' }"
            >
              {{ date }}
            </p>
          </div>
        </div>
        <!-- 募集期間(開始) -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">募集期間(開始)</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <div class="news-text">
              <b-form-select
                v-model="termBeginSelectedYear"
                :options="yearOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >年</span>
              <b-form-select
                v-model="termBeginSelectedMonth"
                :options="monthOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >月</span>
              <b-form-select
                v-model="termBeginSelectedDate"
                :options="dateOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >日</span>
              <b-button
                :variant="this.disableButton1 ? 'secondary' : 'outline-secondary'"
                @click="$_onClickDisableButton1"
              >設定しない</b-button>
            </div>
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ displayEditTermBeginDate }}</div>
          <div v-else-if="showSaved">{{ displayEditTermBeginDate }}</div>
          <div v-else class="event-detail-text">{{ displayTermBeginDate }}</div>
        </div>
        <!-- 募集期間(終了) -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">募集期間(終了)</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <div class="news-text">
              <b-form-select
                v-model="termEndSelectedYear"
                :options="yearOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >年</span>
              <b-form-select
                v-model="termEndSelectedMonth"
                :options="monthOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >月</span>
              <b-form-select
                v-model="termEndSelectedDate"
                :options="dateOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >日</span>
              <b-button
                :variant="this.disableButton2 ? 'secondary' : 'outline-secondary'"
                @click="$_onClickDisableButton2"
              >設定しない</b-button>
            </div>
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ displayEditTermEndDate }}</div>
          <div v-else-if="showSaved">{{ displayEditTermEndDate }}</div>
          <div v-else class="event-detail-text">{{ displayTermEndDate }}</div>
        </div>
        <!-- 掲載終了日 -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">掲載終了日</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <div class="news-text">
              <b-form-select
                v-model="publicEndSelectedYear"
                :options="yearOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >年</span>
              <b-form-select
                v-model="publicEndSelectedMonth"
                :options="monthOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >月</span>
              <b-form-select
                v-model="publicEndSelectedDate"
                :options="dateOptions"
                :style="{ width: '80px' }"
              /><span :style="{ padding: '0 8px 0 4px' }" >日</span>
            </div>
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ displayEditEndOfPublicationDate }}</div>
          <div v-else-if="showSaved">{{ displayEditEndOfPublicationDate }}</div>
          <div v-else class="event-detail-text">{{ displayEndOfPublicationDate }}</div>
        </div>
        <!-- イベント情報掲載ページ -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">イベント情報掲載ページ</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="optional-label">任意</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <input
              type="text"
              v-model="editEventInfoUrl"
              :style="{
                width: '100%',
                border: 'none'
              }"
            />
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ editEventInfoUrl }}</div>
          <div v-else-if="showSaved">{{ editEventInfoUrl }}</div>
          <div v-else class="event-detail-text">{{ eventInfoUrl }}</div>
        </div>
        <!-- 備考 -->
        <!-- 以下の懸念から実装分離を保留 -->
        <!-- AppAdminDetailInputの肥大化を避けるため -->
        <!-- textareaの表示はこの形式のみとは限らないため -->
        <div class="event-detail-sub-title-container">
          <div class="sub-title">備考</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="optional-label">任意</p>
            <p class="limitation-label">400文字まで</p>
          </div>
          <div v-if="showEventEdit" class="textarea-wrapper">
            <CommonTextarea
              v-model="editRemarks"
              :prefix="'space-detail-edit'"
              :maxlength="String(maxRemerksLength)"
              :userStyle="{
                color: '#000',
                width: '100%',
                border: 'none'
              }"
            />
          </div>
          <CommonTextarea
            v-else-if="(showConfirm || showSaved)"
            v-model="editRemarks"
            :prefix="'event-detail-confirm'"
            :readonly="true"
            :userStyle="{
              flexGrow: '1',
              margin: '16px',
              fontSize: '14px',
              border: 'none',
            }"
          />
          <CommonTextarea
            v-else
            disabled
            :prefix="'event-detail'"
            :userStyle="{
              flexGrow: '1',
              margin: '16px',
              fontSize: '14px',
              border: 'none',
            }"
            :remarks="remarks"
          />
        </div>
        <!-- お気に入り（新規作成時は不要） -->
        <div v-if="placeId" class="event-detail-sub-title-container">
          <div class="sub-title">お気に入り数</div>
          <div v-if="showEventEdit" class="item-label">
            <p class="required-label">必須</p>
            <p class="limitation-label">半角数字</p>
          </div>
          <div v-if="showEventEdit" class="input-wrapper">
            <input
              type="number"
              maxlength="8"
              v-model="editLikes"
              :style="{
                width: '80px',
                border: 'none',
                marginRight: '32px'
              }"
            />
            <p :style="{ marginBottom: '0' }">変更前：{{ likes }}</p>
          </div>
          <div v-else-if="showConfirm" class="event-detail-text">{{ editLikes }}</div>
          <div v-else-if="showSaved">{{ editLikes }}</div>
          <div v-else class="event-detail-text">{{ likes }}</div>
        </div>

        <b-container fluid>
          <b-row>
            <b-col cols="12" md="7" class="pl-0">
              <!-- 郵便番号 -->
              <div class="event-detail-sub-title-container">
                <div class="sub-title">郵便番号</div>
                <div v-if="showEventEdit" class="item-label">
                  <p class="required-label">必須</p>
                  <p class="limitation-label">ハイフンを含めて<br />半角で入力</p>
                </div>
                <div v-if="showEventEdit" class="input-wrapper">
                  <input
                    type="text"
                    maxlength="8"
                    v-model="editZip"
                    :style="{
                      width: '100%',
                      border: 'none'
                    }"
                  />
                </div>
                <div v-else-if="showConfirm" class="event-detail-text">{{ editZip }}</div>
                <div v-else-if="showSaved">{{ editZip }}</div>
                <div v-else class="event-detail-text">{{ zip }}</div>
              </div>
              <!-- 都道府県 -->
              <div class="event-detail-sub-title-container">
                <div class="sub-title">都道府県</div>
                <div v-if="showEventEdit" class="item-label">
                  <p class="required-label">必須</p>
                </div>
                <div v-if="showEventEdit" :style="{ margin: '0 16px', display: 'flex', alignItems: 'center' }">
                  <b-form-select
                    v-model="editPrefecture"
                    :options="prefectureOptions"
                  />
                </div>
                <div v-else-if="showConfirm" class="event-detail-text">{{ editPrefecture }}</div>
                <div v-else-if="showSaved">{{ editPrefecture }}</div>
                <div v-else class="event-detail-text">{{ prefecture }}</div>
              </div>
              <!-- 市区郡 -->
              <div class="event-detail-sub-title-container">
                <div class="sub-title">市区郡</div>
                <div v-if="showEventEdit" class="item-label">
                  <p class="required-label">必須</p>
                </div>
                <div v-if="showEventEdit" class="input-wrapper">
                  <input
                    type="text"
                    v-model="editArea"
                    :style="{
                      width: '100%',
                      border: 'none'
                    }"
                  />
                </div>
                <div v-else-if="showConfirm" class="event-detail-text">{{ editArea }}</div>
                <div v-else-if="showSaved">{{ editArea }}</div>
                <div v-else class="event-detail-text">{{ area }}</div>
              </div>
              <!-- 住所（それ以降） -->
              <div class="event-detail-sub-title-container">
                <div class="sub-title">それ以降</div>
                <div v-if="showEventEdit" class="item-label">
                  <p class="required-label">必須</p>
                </div>
                <div v-if="showEventEdit" class="input-wrapper">
                  <input
                    type="text"
                    v-model="editAddress"
                    :style="{
                      width: '100%',
                      border: 'none'
                    }"
                  />
                </div>
                <div v-else-if="showConfirm" class="event-detail-text">{{ editAddress }}</div>
                <div v-else-if="showSaved">{{ editAddress }}</div>
                <div v-else class="event-detail-text">{{ address }}</div>
              </div>
            </b-col>
            <b-col cols="12" md="5" class="pr-0 position-map-container">
              <MapContents
                v-bind="mapConfig"
                v-bind:mapZoom="18"
                v-on:map-loadend="$_onMapLoadEnd"
              />
              <div v-if="showEventEdit" style="display: flex;justify-content: space-around;align-items: center;column-gap: 8px;">
                <b-button
                  style="flex-grow: 1;font-size: 12px"
                  @click="$_onClickApplyAddress"
                >
                  住所の位置に移動
                </b-button>
                <b-button
                  style="flex-grow: 1;font-size: 12px" 
                  @click="$_onClickApplyPosition"
                >
                  ピンの位置を更新
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <!-- 追加情報（編集不可）-->
        <div v-if="!showEventEdit && !showConfirm && !showSaved">
          <div class="topic-title">
            情報提供者連絡先
          </div>
          <div
            v-for="form in additionalForms"
            :key="`additional-form-${form.id}`"
          >
            <!-- 通常項目 -->
            <DetailInput
              :showEdit="false"
              :showConfirm="false"
              :showSaved="false"
              v-bind="form"
              @value-changed="form.onValueChanged"
            />
          </div>
        </div>

        <!-- Action buttons -->
        <!-- 入力 → 確認 -->
        <div
          v-if="showEventEdit"
          class="action-button-wrapper"
        >
          <EllipseButton
            :buttonText="'キャンセル'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onEditCancelClick"
          />
          <EllipseButton
            :buttonText="'入力内容を確認する'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onClickEventSaveEdit"
          />
        </div>
        <!-- 確認 → 保存 -->
        <div
          v-else-if="showConfirm"
          class="action-button-wrapper"
        >
          <EllipseButton
            :buttonText="'キャンセル'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onEventConfirmCancelClick"
          />
          <EllipseButton
            :buttonText="'この内容で作成する'"
            :width="'312px'"
            :height="'36px'"
            @button-click="$_onSaveEventClick"
          />
        </div>
        <!-- 削除 -->
        <div
          v-if="placeId && showEventEdit"
          :style="{
            textAlign: 'center',
            textDecoration: 'underline',
            lineHeight: '3',
          }"
        >
          <span
            :style="{ cursor: 'pointer' }"
            @click="$_onClickDelete" 
          >
            イベントを削除する
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import EllipseButton from '@/components/molecules/admin/AppAdminEllipseButton'
import DetailInput from '@/components/molecules/admin/AppAdminDetailInput.vue'
import MapContents from '@/components/molecules/user/AppUserMapContents'
import UploadImageAdmin from '@/components/organisms/admin/space/AppAdminUploadImage.vue'
import AppCalendar from '@/components/organisms/common/AppCommonCalendar'
import { getEvent, putEvent, postEvent, deleteEvent } from '@/helper/firestore/event'
import { getThumbnail } from '@/helper/place'
import { uploadThumbnailImages, deleteStorageImage } from '@/helper/admin'
import prefectures from '@/js/prefectures'
import { showBottomInfoToast, showInfoPopupAlert, getMaxLength } from '@/helper/common'
import moment from 'moment'
import { mapGetters } from 'vuex'

const MAX_REMARKS_LENGTH = 400

export default {
  name: 'AppAdminEventDataDetail',
  components: {
    CommonTextarea,
    AppCalendar,
    UploadImageAdmin,
    EllipseButton,
    DetailInput,
    MapContents
  },
  props: {
    placeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      geocooder: null,
      showConfirm: false,
      showEventDetial: true,
      showEventEdit: !this.placeId,
      showSaved: false,
      hasEventImage: false,
      event: {},
      eventOwner: {},
      editPublicStatus: '',
      editApplyPage: '',
      editZip: '',
      editPrefecture: '',
      editArea: '',
      editAddress: '',
      editLikes: '',
      editEventName: '',
      editSpaceName: '',
      editCopyRightUrl: '',
      editLikeCount: 0,
      editRemarks: '',
      editEventInfoUrl: '',
      editEventImage: { base64: require('@/assets/image/place_default.png') },
      editEventImageName: '',
      eventImage: null,
      yearOptions: [],
      monthOptions: [],
      dateOptions: [],
      editHeldDates: [],
      editTermBeginDate: null,
      termBeginSelectedYear: null,
      termBeginSelectedMonth: null,
      termBeginSelectedDate: null,
      editTermEndDate: null,
      termEndSelectedYear: null,
      termEndSelectedMonth: null,
      termEndSelectedDate: null,
      editEndOfPublicationDate: null,
      publicEndSelectedYear: null,
      publicEndSelectedMonth: null,
      publicEndSelectedDate: null,
      disableButton1: false,
      disableButton2: false,
      map: null,
      google: null,
      marker: null,
      fixedMarker: null,
      eventListener: null,
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [
        'mapConfig',
        'mapCenter',
        'mapZoom',
        'mapBounds',
        'currentLocation',
        'basePoint'
      ]
    ),
    ...mapGetters('place', [ 'chunks' ]),
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    prefectureOptions: function () {
      return [
        { value: '', text: '選択', disabled: true },
        ...prefectures
      ]
    },
    addedBy: function () {
      return this.event && this.event.addedBy
        ? this.event.addedBy === 'user'
          ? 'ユーザー'
          : '管理者'
        : '管理者'
    },
    eventName: function () {
      return this.event ? this.event.eventName : ''
    },
    spaceName: function () {
      return this.event ? this.event.spaceName : ''
    },
    copyRightUrl: function () {
      return this.event ? this.event.copyRightUrl : ''
    },
    heldDates: function () {
      moment.locale('ja')
      return this.event && this.event.heldDates
        ? this.event.heldDates
          .replace(/"/g, '').replace(/”/g, '')
          .split(",")
          .map(d => moment(d).format('YYYY年MM月DD日（ddd）'))
        : []
    },
    likes: function () {
      return this.event && this.event.likes ? this.event.likes : 0
    },
    publicStatus: function () {
      return this.event ? this.event.public : ''
    },
    image: function () {
     return this.event && this.event.image
      ? this.event.image
      : require('@/assets/image/place_default.png')
    },
    zip: function () {
      return this.event ? this.event.zip : ''
    },
    coordinates: function () {
      return this.event && this.event.coordinates
        ? this.event.coordinates
        : null
    },
    prefecture: function () {
      return this.event ? this.event.prefecture : ''
    },
    area: function () {
      return this.event ? this.event.area : ''
    },
    address: function () {
      return this.event ? this.event.address : ''
    },
    fullAddress: function () {
      return `
        ${this.editObject.prefecture}${this.editObject.area}${this.editObject.address}
      `
    },
    calendarInitialSelectedDate: function () {
      return this.event && this.event.heldDates
        ? this.event.heldDates
          .replace(/"/g, '').replace(/”/g, '')
          .split(",")
        : []
    },
    calendarInitialMonth: function () {
      const heldDates = this.event && this.event.heldDates
        ? this.event.heldDates
          .replace(/"/g, '').replace(/”/g, '')
          .split(",")
        : null
      return heldDates ? new Date(heldDates[0]) : new Date()
    },
    displayHeldDates: function () {
      moment.locale('ja')
      return this.editHeldDates.map(d => moment(d).format('YYYY年MM月DD日（ddd）'))
    },
    termBeginDate: function () {
      return this.event && this.event.termBeginDate
        ? this.event.termBeginDate.replace(/”/g, '').replace(/"/g, '')
        : ''
    },
    displayTermBeginDate: function () {
      moment.locale('ja')
      return this.termBeginDate
        ? moment(this.termBeginDate).format('YYYY年MM月DD日（ddd）')
        : ''
    },
    displayEditTermBeginDate: function () {
      if (
        this.disableButton1 ||
        !this.termBeginSelectedYear ||
        !this.termBeginSelectedMonth ||
        !this.termBeginSelectedDate
      ) return ''
      moment.locale('ja')
      return moment(`
        ${this.termBeginSelectedYear}/
        ${this.termBeginSelectedMonth}/
        ${this.termBeginSelectedDate}
      `).format('YYYY年MM月DD日（ddd）')
    },
    saveTermBeginDate: function () {
      if (
        this.disableButton1 ||
        !this.termBeginSelectedYear ||
        !this.termBeginSelectedMonth ||
        !this.termBeginSelectedDate
      ) return ''
      return `${this.termBeginSelectedYear}/${this.termBeginSelectedMonth}/${this.termBeginSelectedDate}`
    },
    termEndDate: function () {
      return this.event && this.event.termEndDate
        ? this.event.termEndDate.replace(/”/g, '').replace(/"/g, '')
        : ''
    },
    displayTermEndDate: function () {
      moment.locale('ja')
      return this.termEndDate
        ? moment(this.termEndDate).format('YYYY年MM月DD日（ddd）')
        : ''
    },
    displayEditTermEndDate: function () {
      if (
        this.disableButton2 ||
        !this.termEndSelectedYear ||
        !this.termEndSelectedMonth ||
        !this.termEndSelectedDate
      ) return ''
      moment.locale('ja')
      return moment(`
        ${this.termEndSelectedYear}/
        ${this.termEndSelectedMonth}/
        ${this.termEndSelectedDate}
      `).format('YYYY年MM月DD日（ddd）')
    },
    saveTermEndDate: function () {
      if (
        this.disableButton2 ||
        !this.termEndSelectedYear ||
        !this.termEndSelectedMonth ||
        !this.termEndSelectedDate
      ) return ''
      return `${this.termEndSelectedYear}/${this.termEndSelectedMonth}/${this.termEndSelectedDate}`
    },
    displayEditEndOfPublicationDate: function () {
      if (
        !this.publicEndSelectedYear ||
        !this.publicEndSelectedMonth ||
        !this.publicEndSelectedDate
      ) return ''
      moment.locale('ja')
      return moment(`
        ${this.publicEndSelectedYear}/
        ${this.publicEndSelectedMonth}/
        ${this.publicEndSelectedDate}
      `).format('YYYY年MM月DD日（ddd）')
    },
    saveEndOfPublicationDate: function () {
      if (
        !this.publicEndSelectedYear ||
        !this.publicEndSelectedMonth ||
        !this.publicEndSelectedDate
      ) return ''
      return `${this.publicEndSelectedYear}/${this.publicEndSelectedMonth}/${this.publicEndSelectedDate}`
    },
    endOfPublicationDate: function () {
      return this.event && this.event.endOfPublicationDate
        ? this.event.endOfPublicationDate.replace(/”/g, '').replace(/"/g, '')
        : ''
    },
    displayEndOfPublicationDate: function () {
      moment.locale('ja')
      return this.endOfPublicationDate
        ? moment(
            this.endOfPublicationDate
          ).format('YYYY年MM月DD日（ddd）')
        : ''
    },
    eventInfoUrl: function () {
      return this.event ? this.event.eventInfoUrl : ''
    },
    eventImageName: function () {
      return this.event ? this.event.eventImageName : ''
    },
    remarks: function () {
      return this.event && this.event.remarks
        ? this.event.remarks
        : ''
    },
    companyName: function () {
      return this.event ? this.event.companyName : ''
    },
    responsiblePersonName: function () {
      return this.event ? this.event.responsiblePersonName : ''
    },
    email: function () {
      return this.event ? this.event.email : ''
    },
    tell: function () {
      return this.event ? this.event.tell : ''
    },
    additionalForms: function () {
      return [
        {
          id: 1,
          formType: 'text',
          value: this.companyName,
          title: '会社名（団体名）',
          onValueChanged: () => {}
        },
        {
          id: 2,
          formType: 'text',
          value: this.responsiblePersonName,
          title: '担当者名',
          onValueChanged: () => {}
        },
        {
          id: 3,
          formType: 'text',
          value: this.email,
          title: 'メールアドレス',
          onValueChanged: () => {}
        },
        {
          id: 4,
          formType: 'text',
          value: this.tell,
          title: '電話番号',
          onValueChanged: () => {}
        },
      ]
    },
    maxRemerksLength() {
      return getMaxLength(
        MAX_REMARKS_LENGTH,
        this.editRemarks,
        false
      )
    },
    editObject: function () {
      return {
        address: this.editAddress,
        likes: this.editLikes,
        area: this.editArea,
        copyRightUrl: this.editCopyRightUrl,
        endOfPublicationDate: this.saveEndOfPublicationDate,
        eventInfoUrl: this.editEventInfoUrl,
        eventName: this.editEventName,
        heldDates: this.editHeldDates.map(d => `"${d}"`).join(","),
        prefecture: this.editPrefecture,
        public: this.editPublicStatus,
        spaceName: this.editSpaceName,
        termBeginDate: this.saveTermBeginDate,
        termEndDate: this.saveTermEndDate,
        zip: this.editZip,
        coordinates: this.event.coordinates,
        remarks: this.editRemarks
      }
    },
  },
  watch: {
    editEventImage: {
      handler: function (val, oldVal) {
        this.hasEventImage = val && !val.base64.includes('default')
      },
      deep: true
    }
  },
  mounted: async function() {
    this.$store.dispatch('loadingMask/showLoadingMask')

    // placeIdがあれば編集、なければ新規作成
    if (this.placeId) await this.$_setEventDetail(this.placeId)

    this.eventImage = this.image
    this.editEventImage.base64 = this.image

    const today = new Date()
    this.yearOptions = [
      today.getFullYear(),
      today.getFullYear() + 1,
      today.getFullYear() + 2,
      today.getFullYear() + 3,
      today.getFullYear() + 4,
    ]
    this.monthOptions = [...Array(12)].map((_, i) => i + 1)
    this.dateOptions = [...Array(31)].map((_, i) => i + 1)

    this.$nextTick(function(){
      this.$store.dispatch('loadingMask/hideLoadingMask')
    })
  },
  methods: {
    $_onMapLoadEnd: async function ({ map, google }) {
      this.map = map
      this.google = google

      try {
        // placeIdがあれば編集、なければ新規作成
        if (this.placeId) {
          await this.$_setEventDetail(this.placeId)

          this.map.panTo(this.coordinates)

          if (this.fixedMarker) this.fixedMarker.setMap(null)
          this.fixedMarker = new this.google.maps.Marker({
            position: this.map.getCenter(),
            map: this.map,
            icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            zIndex: 2
          })
        } else {
          this.$_setForms()
        }

        // マーカー初期化
        if (this.marker) this.marker.setMap(null)
        this.google.maps.event.removeListener(this.eventListener)

        // マーカー作成
        this.marker = new this.google.maps.Marker({
          position: this.map.getCenter(),
          map: this.map,
          icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
          zIndex: 1
        })
        this.eventListener = this.google.maps.event.addListener(
          this.map,
          'center_changed',
          () => {
            this.marker.setPosition(this.map.getCenter())
          })
      } catch(error) {
        console.log(error)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    async $_onClickApplyAddress() {
      if (!this.map) return

      if (
        // !this.placeId &&
        this.editPrefecture &&
        this.editArea &&
        this.editAddress
      ) {
        try {
          // 新規作成かつ住所の入力が揃ったらジオコーディング → マップに適用
          const coords = await this.$_getCoords(this.fullAddress)
          coords && this.map.panTo(coords)
        } catch(error) {
          console.log(error)
        }
      }
    },
    $_onClickApplyPosition() {
      const center = this.map.getCenter()
      this.editObject.coordinates = {
        lat: center.lat(),
        lng: center.lng(),
      }

      if (this.fixedMarker) {
        this.fixedMarker.setPosition(center)
      } else {
        this.fixedMarker = new this.google.maps.Marker({
          position: this.map.getCenter(),
          map: this.map,
          icon: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
          zIndex: 2
        })
      }
    },
    $_onChangeDateSelection: function (date) {
      this.editHeldDates = [...date]
    },
    $_onClickDisableButton1: function () {
      this.disableButton1 = !this.disableButton1
    },
    $_onClickDisableButton2: function () {
      this.disableButton2 = !this.disableButton2
    },
    $_onClickDelete: async function () {
      if (confirm('イベントを削除します。よろしいですか？')) {
        this.$store.dispatch('loadingMask/showLoadingMask')
        try {
          await this.$_deleteEvent()
          alert('削除しました')
        } catch (e) {
          // $_deleteEvent内でハンドリング済み
          console.log(e)
        } finally {
          this.$store.dispatch('loadingMask/hideLoadingMask')
        }
        this.$router.push('/admin/event_list')
      }
    },
    $_onEditCancelClick: function () {
      // 新規作成時はリストへ戻る
      if (!this.placeId) {
        this.$router.push('/admin/event_list')
      } else {
        this.showEventDetial = true
        this.showEventEdit = false
      }
    },
    $_onClickEventSaveEdit: function () {
      if (!this.$_validation()) return
      this.showEventEdit = false
      this.showConfirm = true
    },
    $_validation: function () {
      if (!this.editPublicStatus) {
        showBottomInfoToast('error', '「公開設定」を選択して下さい。', 4000)
        return false
      }
      if (!this.editEventName) {
        showBottomInfoToast('error', '「イベント名」を入力して下さい。', 4000)
        return false
      }
      // if (this.hasEventImage && !this.editCopyRightUrl) {
      //   showBottomInfoToast('error', '「サムネイル引用元URL」を入力して下さい。', 4000, 400)
      //   return false
      // }
      if (!this.editSpaceName) {
        showBottomInfoToast('error', '「スペース名」を入力して下さい。', 4000)
        return false
      }
      if (!this.editZip) {
        showBottomInfoToast('error', '「郵便番号」を入力して下さい。', 4000)
        return false
      }
      if (!/^[0-9]{3}-[0-9]{4}$/.test(this.editZip)) {
        showBottomInfoToast('error', '「郵便番号」は半角英数字でハイフンを含めて入力して下さい。', 4000, 550)
        return false
      }
      if (!this.editPrefecture) {
        showBottomInfoToast('error', '「都道府県」を選択して下さい。', 4000)
        return false
      }
      if (!this.editArea) {
        showBottomInfoToast('error', '「市区郡」を選択して下さい。', 4000)
        return false
      }
      if (!this.editAddress) {
        showBottomInfoToast('error', '「それ以降」を選択して下さい。', 4000)
        return false
      }
      if (!this.editHeldDates.length) {
        showBottomInfoToast('error', '「開催日」を選択して下さい。', 4000)
        return false
      }
      if (!this.saveTermBeginDate && !this.disableButton1) {
        showBottomInfoToast('error', '「募集期間(開始)」を選択して下さい。', 4000, 400)
        return false
      }
      if (!this.saveTermEndDate && !this.disableButton2) {
        showBottomInfoToast('error', '「募集期間(終了)」を選択して下さい。', 4000, 400)
        return false
      }
      if (!this.saveEndOfPublicationDate) {
        showBottomInfoToast('error', '「掲載終了日」を選択して下さい。', 4000)
        return false
      }
      // if (!this.editEventInfoUrl) {
      //   showBottomInfoToast('error', '「イベント情報掲載ページ」を選択して下さい。', 4000, 480)
      //   return false
      // }
      // 新規登録時は不要
      if (this.placeId && (!this.editLikes && this.editLikes !== 0)) {
        showBottomInfoToast('error', '「お気に入り数」を選択して下さい。', 4000)
        return false
      }
      return true
    },
    $_onEventConfirmCancelClick: function () {
      this.showEventEdit = true
      this.showEventEdit = true
      this.showConfirm = false
    },
    $_onSaveEventClick: async function () {
      try {
        this.$store.dispatch('loadingMask/showLoadingMask')

        this.placeId
          ? await this.$_updateEvent()
          : await this.$_addEvent()

        this.showSaved = true
      } catch (error) {
        showInfoPopupAlert(`更新に失敗しました。<br />${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_updateEvent: async function () {
      try {
        // サムネイル保存、ダウンロードURL取得
        let uploadResult = ''
        if (this.editEventImage && this.editEventImage.fileObject) {
          uploadResult = await uploadThumbnailImages(
            'event',
            this.placeId,
            'thumbnail_1.png',
            this.editEventImage.fileObject,
          )
        } else if (!this.editEventImage || !this.editEventImage.base64) {
          deleteStorageImage(
            'event',
            this.placeId,
            'thumbnail_1.png',
          )
        }

        // スペース詳細データにはChunkIDは含まれていないので、ここで取得
        // FIXME: propsでchunkIDもテーブル側からもらえるっぽい
        const targetChunk = this.chunks.find(c => c.placeId === this.placeId)
        if (!targetChunk) {
          throw new Error('ローカルデータが取得できませんでした。再度ログインしてください。')
        }
        await putEvent(
          {
            placeId: this.placeId,
            ...this.editObject,
            exteriorImage: uploadResult,
            eventImageName: this.editEventImageName || '',
            addedBy: this.event.addedBy || 'admin', // 無ければ管理者とする
            createAt: this.event.createAt, // firestoreへの書き込み時に値がundefinedだとエラーになるので、editObjectにはcreateAtは展開していない(新規作成時はhelper側で追加)
            type: 'event',
          },
          targetChunk.docId,
        )

        // reload
        await this.$_setEventDetail(this.placeId)
        // reload local chunk
        this.$store.dispatch('place/updateSpecificChunk', targetChunk.docId)
      } catch (e) {
        throw new Error(e)
      }
    },
    $_addEvent: async function () {
      try {
        // 指定の座標がなければ、ここでジオコーディング
        const coords = this.editObject.coordinates || await this.$_getCoords(this.fullAddress)
        // 無ければ管理者とする
        const addedBy = this.space.addedBy || 'admin'
        const eventObject = {
          ...this.editObject,
          coordinates: coords,
          addedBy: addedBy,
          type: 'event',
          likes: 0,
        }
        const result = await postEvent(eventObject)

        if (
          result.event.status === 'success' &&
          result.chunk.status === 'success'
        ) {
          const eventId = result.event.docId
          const chunkId = result.chunk.docId

          // 新規追加はplaceIdがないので、一度登録して取得してから画像登録
          if (this.editEventImage && this.editEventImage.fileObject) {
            const uploadResult = await uploadThumbnailImages(
              'event',
              eventId,
              'thumbnail_1.png',
              this.editEventImage.fileObject,
            )

            // 画像のダウンロードURLのみ更新
            await putEvent(
              {
                ...eventObject,
                placeId: eventId,
                exteriorImage: uploadResult,
                eventImageName: this.editEventImageName || '',
              },
              chunkId,
            )
          } else {
            deleteStorageImage(
              'event',
              eventId,
              'thumbnail_1.png',
            )
          }

          // reload
          await this.$_setEventDetail(eventId)
          // reload local chunk
          this.$store.dispatch('place/updateSpecificChunk', chunkId)
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    $_deleteEvent: async function () {
      try {
        const targetChunk = this.chunks.find(c => c.placeId === this.placeId)
        if (!targetChunk) {
          throw new Error('ローカルデータが取得できませんでした。再度ログインしてください。')
        }

        // delete event from events and chunks
        await deleteEvent(this.placeId, targetChunk.docId)

        // reload local chunk
        this.$store.dispatch('place/updateSpecificChunk', targetChunk.docId)
      } catch (e) {
        showInfoPopupAlert(`削除に失敗しました。<br />${e}`)
        throw new Error(e)
      }
    },
    $_getCoords: async function (address) {
      if(!this.geocooder) {
        this.geocooder = new this.google.maps.Geocoder()
      }

      return new Promise((resolve, reject) => {
        try {
          this.geocooder.geocode(
            {
              address: address,
            },
            (result, status) => {
              if (status === 'OK') {
                resolve({
                  lng: result[0].geometry.location.lng(),
                  lat: result[0].geometry.location.lat(),
                })
              } else {
                console.log('error: ', status)
                resolve()
              }
            }
          )
        } catch (err) {
          console.log(err)
          resolve()
        }
      })
    },
    $_onClickEventEdit: function () {
      this.showEventDetial = true

      this.showEventEdit = true

      this.editPublicStatus = this.publicStatus
      this.editEventName = this.eventName
      this.editSpaceName = this.spaceName
      this.editZip = this.zip
      this.editPrefecture = this.prefecture
      this.editArea = this.area
      this.editAddress = this.address
      this.editLikes = this.likes
      this.editCopyRightUrl = this.copyRightUrl
      this.editEventInfoUrl = this.eventInfoUrl
      this.editHeldDates = this.calendarInitialSelectedDate
      this.editTermBeginDate = this.termBeginDate
      this.editTermEndDate = this.termEndDate
      this.editEndOfPublicationDate = this.endOfPublicationDate
      this.editEventImageName = this.eventImageName
      this.editRemarks = this.remarks

      if (this.termBeginDate) {
        this.termBeginSelectedYear = moment(this.termBeginDate).format('YYYY')
        this.termBeginSelectedMonth = moment(this.termBeginDate).format('M')
        this.termBeginSelectedDate = moment(this.termBeginDate).format('D')
      }
      if (this.termEndDate) {
        this.termEndSelectedYear = moment(this.termEndDate).format('YYYY')
        this.termEndSelectedMonth = moment(this.termEndDate).format('M')
        this.termEndSelectedDate = moment(this.termEndDate).format('D')
      }
      if (this.endOfPublicationDate) {
        this.publicEndSelectedYear = moment(this.endOfPublicationDate).format('YYYY')
        this.publicEndSelectedMonth = moment(this.endOfPublicationDate).format('M')
        this.publicEndSelectedDate = moment(this.endOfPublicationDate).format('D')
      }

      this.disableButton1 = (
        !this.termBeginSelectedYear &&
        !this.termBeginSelectedMonth &&
        !this.termBeginSelectedDate
      ),
      this.disableButton2 = (
        !this.termEndSelectedYear &&
        !this.termEndSelectedMonth &&
        !this.termEndSelectedDate
      )
    },
    /**
     * イベント詳細情報を取得する
     */
    $_setEventDetail: async function (placeId) {
      try {
        const eventObject = await getEvent(placeId)
        // event画像を取得してthis.event.imageに埋め込む
        // 先にthis.eventにeventObjectを入れてしまうとタイミング的に
        // imageが取得できない
        eventObject.image = await getThumbnail('event', placeId)
        this.event = eventObject
      } catch (error) {
        showInfoPopupAlert(`イベント情報の取得に失敗しました。<br />${error}`)
      }
    },
    $_onClickGoBack: async function () {
      this.$store.dispatch('loadingMask/showLoadingMask')

      // reload
      if (this.placeId) await this.$_setEventDetail(this.placeId)

      this.showEventEdit = false
      this.showConfirm = false

      this.showEventDetial = true

      this.showSaved = false

      this.$store.dispatch('loadingMask/hideLoadingMask')
    },
    $_onChangeImage: function (e) {
      this.eventImage = e.base64
      this.editEventImage = e
      this.editEventImageName = e.fileObject.name
    },
    $_onRemoveImage: function (e) {
      this.eventImage = require('@/assets/image/place_default.png')
      this.editEventImage = ''
      this.editEventImageName = ''
      this.editCopyRightUrl = ''
    },
  },
}
</script>

<style lang="scss" scoped>
held-status {
  margin-bottom: 0;
}

.event-detail-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.back-arrow-button {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: white;
  height: 32px;
  width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
}

.event-detail-container {
  color: #707070;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;
}

.event-detail-title {
  color: #707070;
  font-size: 24px;
  font-weight: bold;
  margin: 0px !important;
  line-height: 32px;
}

.event-detail-sub-title {
  color: #707070;
  font-size: 16px;
  margin: 0px 0px 0px 24px !important;
  line-height: 32px;
}

.account-wrapper {
  flex-grow: 1;
  overflow: auto;
  min-width: 750px;
  padding: 40px;
}

.sns-icon-wrapper {
  flex-grow: 1;
}
.topic-title {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 12px 0;
  border-bottom: solid 2px #70707040;
  /* overwrite */
  ::v-deep(.btn-outline-secondary) {
    font-size: 12px;
  }
}
/* overwrite */
::v-deep(.btn-outline-primary) {
  background-color: #F4F5F7;
  border: solid 1px #E1E5EC;
  font-weight: bold;
  color: #767676;
  font-size: 14px;
  padding: 8px 16px;
  min-width: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* overwrite */
::v-deep(.btn-outline-primary).focus {
  box-shadow: none;
}
/* overwrite */
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled) {
  &:active {
    background-color: #12B6D4;
    border: solid 1px #12B6D4;
  }
}
::v-deep(.btn-outline-primary):not(:disabled):not(.disabled).active {
  background-color: #12B6D4;
  border: solid 1px #12B6D4;
  font-weight: bold;
}
.event-detail-sub-title-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 16px;
  border: solid 1px #D6D6D6;
  border-radius: 4px;
  .bold {
    font-weight: bold;
  }
  .item-label {
    width: 96px;
    font-size: 8px;
    flex-shrink: 0;
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: solid 1px #D6D6D6;
    text-align: center;
    .optional-label {
      color: #707070;
      font-weight: bold;
      margin-bottom: 0;
    }
    .required-label {
      color: red;
      font-weight: bold;
      margin-bottom: 0;
    }
    .limitation-label {
      color: #707070;
      margin-bottom: 0;
    }
  }
  .input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 16px;
  }
  .textarea-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px;
    color: #707070;
    font-size: 14px;
  }
  .sub-title {
    width: 13%;
    min-width: 260px;
    background-color: #D6D6D6;
    border-right: solid 1px #D6D6D6;
    color: #707070;
    font-size: 14px;
    font-weight: bold;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .news-text {
    flex-grow: 1;
    label {
      margin-bottom: 0;
    }
  }
  .event-detail-text {
    width: 87%;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto 1rem;
  }
  .event-detail-images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 8px 0;
      cursor: pointer;
    }
  }
}

.sns-icon {
  margin: 0 0 24px 0;
  display: flex;
  align-items: flex-start;
}
.sns-icon-image {
  margin-right: 16px;
  width: 18px;
  height: 18px;
}
.link-text {
  color: #767676;
  text-decoration: underline;
}
/* ラジオボタン01 */
input[type=radio] {
    display: none;
}
.radio01 {
    user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0 30px;
    position: relative;
    width: auto;
    margin-bottom: 0;
}
.radio01::before {
    background: #fff;
    border: 1px solid #231815;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
}
.radio01::after {
    background: #12B6D4;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 8px;
    margin-top: -4px;
    opacity: 0;
    position: absolute;
    top: 48%;
    width: 10px;
}
input[type=radio]:checked + .radio01::after {
    opacity: 1;
}
/* チェックボックス01 */
input[type=checkbox] {
    display: none;
}
.checkbox01 {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
}
.checkbox01::before {
    background: #fff;
    border: 1px solid #231815;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
}
.checkbox01::after {
    border-right: 3px solid #12B6D4;
    border-bottom: 3px solid #12B6D4;
    content: '';
    display: block;
    height: 9px;
    left: 10px;
    margin-top: -5px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 5px;
}
input[type=checkbox]:checked + .checkbox01::after {
    opacity: 1;
}
.action-button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 64px;
  /* overwrite */
  ::v-deep(.btn-outline-secondary) {
    margin: 24px 0 16px 0;
  }
}

.position-map-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
}
</style>
